import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const configEnv = process.env.REACT_APP_FIREBASE_CONFIG
const firebaseConfig = JSON.parse(configEnv || '')

let fbApp: firebase.app.App

function firebaseApp () {
  if (!fbApp) {
    fbApp = firebase.initializeApp(firebaseConfig)
  }

  return fbApp
}

const firebaseCommonErrors = [
  {
    code: 'auth/uid-already-exists',
    message: 'Ops! Já existe um usuário cadastrado.',
    isWarn: true
  },
  {
    code: 'auth/app-deleted',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/app-not-autorizado',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/invalid-email',
    message: 'O endereço de e-mail está formatado incorretamente.',
    isWarn: true
  },
  {
    code: 'auth/email-already-exists',
    message: 'O endereço de e-mail já está sendo usado por outra conta.',
    isWarn: true
  },
  {
    code: 'auth/invalid-password',
    message: 'Sua senha deve ter pelo menos 6 caracteres.',
    isWarn: true
  },
  {
    code: 'auth/invalid-api-key',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/invalid-user-token',
    message: 'Tempo de sessão expirado. Tente se logar novamente.',
    isWarn: true
  },
  {
    code: 'auth/invalid-tenant-id',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/network-request-failed',
    message: 'Tempo de sessão expirado, se conecte novamente.',
    isWarn: true
  },
  {
    code: 'auth/operation-not-allowed',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/requer-recent-login',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/too-many-requests',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/unauthorized-domain',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/user-disabled',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/user-not-found',
    message: 'Nenhum atleta foi encontrato com esse endereço de e-mail.',
    isWarn: true
  },
  {
    code: 'auth/user-token-expired',
    message: 'Ocorreu um erro de acesso. Tente se conectar novamente.',
    isWarn: false
  },
  {
    code: 'auth/web-storage-unsupported',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/account-existing-with-different-credential',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/auth-domain-config-required',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/cancelled-popup-request',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/operation-not-allowed',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/operation-not-supported-in-this-environment',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/popup bloqueado',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/popup-closed-by-user',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/unauthorized-domain',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/custom-token-mismatch',
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/invalid-custom-token',
    message: ' Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  },
  {
    code: 'auth/wrong-password',
    message: ' Ops! A senha está inválida.',
    isWarn: false
  },
  {
    code: 'auth/phone-number-already-exists',
    message: 'O número de telefone já existe',
    isWarn: true
  },
  {
    code: 'auth/phone-number-invalid',
    message: 'O número de telefone é inválido',
    isWarn: true
  }
]

export const getFirebaseErrorHandler = (code: string) => {
  const error = firebaseCommonErrors.find((f) => {
    return f.code === code
  })

  if (error) {
    return error
  }

  return {
    code,
    error,
    message: 'Ops! Aconteceu algo fora do esperado.',
    isWarn: false
  }
}

export default {
  firebaseConfig,
  firebaseApp
}
