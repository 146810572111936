const colors = {
  qipBlue: '#050830',
  qipBlueLight: '#050d3d',
  qipCoral: '#e05e4d',
  qipCoralLight: '#ff6e5c',
  qipCoralHome: '#FFB2A8',
  qipGreen: '#34c924',
  qipGreenLight: '#85fa45',
  qipSoftBlue: '#80a1f5',
  uiBlueSystem: '#0067c5',
  uiRedSystem: '#f55915',
  uiBlueLink: '#0d359c',
  uiRedError: '#fab4b4',
  uiErrorShadow: '#ff6e5c',
  uiYellowLight: '#faeeb4',
  uiYellow: '#f5d215',
  uiBlueFocus: '#afc4f9',
  uiBlack: '#292929',
  uiGreenLight: '#C8FDAB',
  uiGreyDark: '#424242',
  uiGrey: '#616161',
  uiGreyMedium: '#bdbdbd',
  uiGreyLight: '#e0e0e0',
  uiBackground: '#f5f5f5',
  uiBackgroundLight: '#fafafa',
  uiWhite: '#ffffff',
  uiFullBlack: '#000000'
}

export default {
  colors
}
