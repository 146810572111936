import { $http } from '../../httpService'

interface OrderParams {
  id: string
  summary?: string
  trackingNumber?: string
  status?: string
}

async function get ({ id }: OrderParams) {
  const response = await $http.get(`/v1/orders/${id}`)
  return response.data
}

async function put ({ id, trackingNumber, summary, status }: OrderParams) {
  const response = await $http.put(`/v1/orders/${id}`, {
    trackingNumber,
    summary,
    status
  })
  return response.data
}

export default {
  get,
  put
}
