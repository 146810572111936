import React from 'react'
import Skeleton from 'react-loading-skeleton'

interface EqipoLoadingSkeletonProps {
  disableHeader?: boolean
}

export const EqipoLoadingSkeleton: React.FC<EqipoLoadingSkeletonProps> = ({ disableHeader }) => (
  <div style={{ padding: '12px 20px' }}>
    {!disableHeader && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px'
        }}
      >
        <Skeleton width={49} height={25} borderRadius={5}/>
      </div>
    )}
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
        marginTop: '12px'
      }}
    >
      {Array.from({ length: 5 }, (_, i) => (
        <div key={i}>
          <Skeleton height={155} borderRadius={5}/>
          <Skeleton height={20} style={{ marginTop: '8px' }}/>
          <Skeleton height={12} style={{ marginTop: '6px' }}/>
        </div>
      ))}
    </div>
  </div>
)
