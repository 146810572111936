import remote from './remote'

interface MessageParams {
  roomId: string
  text: string
}
export interface RoomParams {
  listingId: string
  message: string
}

async function getRooms () {
  return await remote.get()
}

async function getRoomById (id: string) {
  return await remote.getById(id)
}

async function getMessagesByRoomId (id: string) {
  return await remote.getMessagesByRoomId(id)
}
async function getMessagePaginate (id: string, lastMessageId: string) {
  return await remote.getMessagePaginate(id, lastMessageId)
}

async function sendMessage ({ roomId, text }: MessageParams) {
  return await remote.send({ roomId, text })
}
async function createRoom ({ listingId, message }: RoomParams) {
  return await remote.createRoom({ listingId, message })
}
async function messagesUnread () {
  return await remote.messagesUnread()
}

async function readMessage (roomId: string, messageId: string) {
  return await remote.readMessage(roomId, messageId)
}

export default {
  getRooms,
  getRoomById,
  getMessagesByRoomId,
  getMessagePaginate,
  sendMessage,
  createRoom,
  readMessage,
  messagesUnread
}
