import React, { useEffect, useState } from 'react'
import ContainerButton from './ContainerButton'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import FormField from '../../components/FormComponents/FormField'
import RadioField from '../../components/FormComponents/RadioField'
import CheckboxField from '../../components/FormComponents/CheckboxField'
import { type Address } from '../../models/Checkout'
import './CheckoutShipping.scss'
import citiesByStateService from '../../services/citiesByStateService'
import { type UserProfile } from '../../models/User'
import { shippingValidationSchema } from './Checkout.validation'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'

export interface CheckoutShippingProps {
  listingId: string
  onSaveShipping: (shippingAddress: Address, service: Service) => void
  shippingAddress: Address
  setShippingAddress: (payload: any) => void
  seller?: UserProfile
}

export interface Service {
  serviceCode: number
  title?: string
  method: string
  price: string
  priceOwnHand?: string | number
  priceReceiptNotice?: string | number
  priceDeclaredValue?: string | number
  priceNoExtras?: string | number
  deliveryTime?: string
  error?: string
  message?: string
}

interface SelectOptionProps {
  label: string
  value: string
}

type StateAndCitiesProps = Record<string, string[]>

const CheckoutShipping = (props: CheckoutShippingProps) => {
  const [errors, setErrors] = useState<any>(null)
  const [services] = useState<Service[]>([{
    serviceCode: 0,
    title: `A combinar com:  ${props.seller?.displayName}`,
    method: 'IN_PERSON_DELIVERY',
    price: '0',
    deliveryTime: '2'
  }])
  const [, setStates] = useState<SelectOptionProps[]>([])
  const [, setAllCitiesAndStates] = useState<StateAndCitiesProps>({})
  const linkExternalTermsAndConditions =
    process.env.REACT_APP_LINK_EXTERNAL_TERMS_CONDITIONS

  useEffect(
    () => {
      const loadCitiesByState = async () => {
        const response = await citiesByStateService.get()

        setAllCitiesAndStates(response)

        const states = Object.keys(response)
          .sort((stateA, stateB) => (stateA > stateB) ? 1 : -1)
          .map(state => ({
            value: state,
            label: state
          }))

        setStates(states)
      }

      void loadCitiesByState()
    },
    []
  )

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const service = services.filter(item => item.serviceCode === parseInt(shippingAddress.serviceCode, 10))[0]
      await shippingValidationSchema.validate(shippingAddress, { abortEarly: false })
      props.onSaveShipping(props.shippingAddress, service)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const formattedErrors: any = {}

        error.inner.forEach(
          (errorMessage: any) =>
            (formattedErrors[errorMessage.path] = errorMessage.message)
        )

        setErrors(formattedErrors)
      } else {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage/>)
      }
    }
  }

  const handleChange = (name: string, value: string | boolean) => {
    props.setShippingAddress({ ...props.shippingAddress, [name]: value })
  }

  const handleChangeCheckbox = () => {
    props.setShippingAddress({
      ...props.shippingAddress, termOfAgreement: !(props.shippingAddress.termOfAgreement)
    })
  }

  const { shippingAddress } = props

  return (
    <div>
      <form onSubmit={async e => { await handleSave(e) } }>
        { services.length > 0
          ? (
          <FormField
            name="serviceCode"
            label="Qual a forma de entrega?"
            validation={errors?.serviceCode}
          >
            { services.map((service: Service) => (
              <div key={ service.serviceCode }>
                <RadioField
                  name="serviceCode"
                  onChange={ handleChange }
                  label={ `${service.title} ${parseInt(service.price.toString(), 10) === 0 ? '' : `R$ ${service.price}`}` }
                  value={ service.serviceCode }
                  checked={ parseInt(shippingAddress.serviceCode, 10) === service.serviceCode }
                />
                <p className="shipment__description">Combine a entrega direto com a pessoa vendedora.<br/> Chame a Qip se precisar.</p>
              </div>
            )) }
          </FormField>
            )
          : null }
        <FormField
          name="termOfAgreement"
          label=""
          validation={ errors?.termOfAgreement }
          required
        >
          <CheckboxField
            value={ !!(shippingAddress?.termOfAgreement) }
            checked={ !!(shippingAddress?.termOfAgreement) }
            onChange={ handleChangeCheckbox }
            labelLink="termos de entrega e recebimento"
            link={ linkExternalTermsAndConditions }
            label="LI E CONCORDO COM OS "
          />
        </FormField>
        <ContainerButton>
          <Button
            margin="0px"
            type="submit"
            variant="primary"
          >
            CONTINUAR PARA PAGAMENTO
          </Button>
        </ContainerButton>
      </form>
    </div>
  )
}

export default CheckoutShipping
