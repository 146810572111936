import React, { useContext } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import GlobalStyle from './global'
import SignUp from './pages/Auth/SignUp'
import SignUpStrava from './pages/Auth/SignUpStrava'
import AccountData from './pages/Auth/AccountData'
import SignIn from './pages/Auth/SignIn'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetPassword from './pages/Auth/ResetPassword'
import { ThemeProvider } from 'styled-components'
import Eqipo from './pages/Eqipo/Eqipo'
import OrderTrackingNumber from './pages/Eqipo/OrderTrackingNumber/OrderTrackingNumber'
import MyPurchases from './pages/MyPurchases/MyPurchases'
import ConfirmSendReview from './pages/Eqipo/ConfirmSendReview/ConfirmSendReview'
import 'react-loading-skeleton/dist/skeleton.css'

import Rooms from './pages/Rooms/Rooms'
import MessagesRoom from './pages/Rooms/MessagesRoom/MessagesRoom'
import MyNotification from './pages/MyNotifications/MyNotification'
import MyFavorites from './pages/MyFavorites/MyFavorites'
import AuthCode from './pages/Auth/AuthCode'
import NewEqipo from './pages/Eqipo/NewEqipo/NewEqipo'
import Checkout from './pages/Checkout/Checkout'
import MySales from './pages/MySales/MySales'
import MyBalance from './pages/MyBalance/MyBalance'
import Profile from './pages/Profile/Profile'
import ProfileUpdate from './pages/Profile/ProfileUpdate/ProfileUpdate'
import MyBalanceBankAccountUpdate from './pages/MyBalance/MyBalanceBankAccountUpdate'
import theme from './global/theme'
import ConfirmDeliver from './pages/ConfirmDeliver/ConfirmDeliver'
import ConfirmReceive from './pages/ConfirmReceive/ConfirmReceive'
import PurchaseComplete from './pages/Checkout/PurchaseComplete/PurchaseComplete'
import { isMobile } from 'react-device-detect'

import { ConfirmServiceProvider } from './components/ConfirmDialog/ConfirmServiceProvider'
import { GlobalContext, GlobalProvider } from './context/GlobalContext'
import { SignUpProvider } from './context/SignUpContext'

import 'react-toastify/dist/ReactToastify.css'
import Footer from './components/Footer/Footer'
import MyEqipoPage from './pages/MyEqipoPage/MyEqipoPage'
import HomePage from './pages/HomePage/HomePage'
import FullScreenLoader from './components/FullScreenLoader/FullScreenLoader'
import { Desktop } from './pages/HomePage/Desktop'
import ScrollTop from './components/ScrollTop/ScrollTop'
import Help from './pages/Help/Help'
import ZendeskWidget from './components/ZendeskWidget/ZendeskWidget'
import AboutUs from './pages/AboutUs/AboutUs'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'

const App: React.FC = () => {
  return <ConfirmServiceProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <GlobalProvider>
        <QipRouter/>
      </GlobalProvider>
    </ThemeProvider>
  </ConfirmServiceProvider>
}

const QipRouter: React.FC = () => {
  const { isLoading } = useContext(GlobalContext)

  if (!isMobile) {
    return <Desktop/>
  }

  return isLoading
    ? <FullScreenLoader/>
    : <Router>
      <ToastContainer position="top-center" closeOnClick transition={Slide} autoClose={8000}/>
      <ZendeskWidget/>
      <Switch>
        <Route path="/entrar/" component={SignIn}/>
        <Route exact path="/resetar-senha" component={ForgotPassword}/>
        <Route path="/resetar-senha/complete" component={ResetPassword} />

        {/* The outer <Route path="/cadastrar"> is used here not for rendering but as a
                mechanism to apply SignUpProvider to all nested signup routes. This approach
                allows us to utilize the context provided by SignUpProvider across all routes
                related to the signup process without wrapping a <Switch> directly, which
                doesn't work with context providers. (React Router v5) */}
        <Route path="/cadastrar">
          <SignUpProvider>
            <Switch>
              <Route exact path="/cadastrar" component={SignUp}/>
              <Route path="/cadastrar/strava" component={SignUpStrava}/>
              <Route path="/cadastrar/meus-dados" component={AccountData}/>
              <Route exact path="/cadastrar/codigo/:provider" component={AuthCode}/>
            </Switch>
          </SignUpProvider>
        </Route>

        <Route exact path="/atleta/:username" component={Profile}/>
        <ProtectedRoute path="/atleta/:id/editar/checkout" Component={ProfileUpdate}/>
        <ProtectedRoute path="/atleta/:id/editar" Component={ProfileUpdate}/>

        <Route path="/ajuda/" component={Help}/>
        <Route path="/quem-somos/" component={AboutUs}/>

        <ProtectedRoute path="/mensagens/" Component={Rooms}/>
        <ProtectedRoute path="/mensagem/:roomId/" Component={MessagesRoom}/>
        <ProtectedRoute path="/minhas-notificacoes/" Component={MyNotification}/>
        <ProtectedRoute path="/meus-equipos/" Component={MyEqipoPage}/>
        <ProtectedRoute path="/minhas-compras/" Component={MyPurchases}/>
        <ProtectedRoute path="/minhas-vendas/" Component={MySales}/>
        <ProtectedRoute path="/meu-extrato/" Component={MyBalance}/>
        <ProtectedRoute path="/dados-pagamentos/:id/editar" Component={MyBalanceBankAccountUpdate}/>
        <ProtectedRoute exact path="/favoritos/" Component={MyFavorites}/>

        <ProtectedRoute path="/eqipo/novo/" Component={NewEqipo}/>
        <ProtectedRoute path="/eqipo/:id/editar/" Component={NewEqipo}/>
        <ProtectedRoute path="/eqipo/:id/curadoria/" Component={ConfirmSendReview}/>
        <ProtectedRoute exact path="/eqipo/:id/postagem/" Component={OrderTrackingNumber}/>
        <Route path="/eqipo/:id/:slug" component={Eqipo}/>
        <ProtectedRoute exact path="/eqipo/:id" Component={Eqipo}/>

        <ProtectedRoute path="/confirmar-envio/:id/" Component={ConfirmDeliver}/>
        <ProtectedRoute path="/confirmar-recebimento/:id/" Component={ConfirmReceive}/>

        <ProtectedRoute exact path="/checkout/" Component={Checkout}/>
        <ProtectedRoute path="/checkout/compra-finalizada/:paymentMethod" Component={PurchaseComplete}/>

        <Route exact path="/" component={HomePage}/>
        <Route exact path="/eqipos" component={HomePage}/>
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <Footer/>
      <ScrollTop/>
    </Router>
}

export default App
