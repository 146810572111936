import remote from './remote'
import { type ListingStatus } from '../../models/Listings'

interface EqipoParams {
  id: string
  slug: string
}

interface UserEqipoParams {
  id: string
}

interface UserEqiposParams {
  itemStatus: ListingStatus[]
}

async function getPublicEqipo ({ id, slug }: EqipoParams) {
  return await remote.getByIdAndSlug({ id, slug })
}

async function getUserEqipo ({ id }: UserEqipoParams) {
  return await remote.getById({ id })
}

async function getMyEqipos (filter?: UserEqiposParams) {
  return await remote.getMyEqipos(filter)
}

async function getHighlights () {
  return await remote.getHighlights()
}

async function getMyPurchases () {
  return await remote.getMyPurchases()
}

async function getEqipoQuery (query: string) {
  return await remote.getEqipoQuery(query)
}

export default {
  getPublicEqipo,
  getUserEqipo,
  getMyEqipos,
  getHighlights,
  getMyPurchases,
  getEqipoQuery
}
