import React from 'react'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import './NotFoundPage.scss'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { useHistory } from 'react-router-dom'

const NotFoundPage: React.FC = () => {
  const history = useHistory()
  return (
    <div>
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <div className="notFoundPageContainer">
        <h1>CADÊ?</h1>
        <p><strong>O que você procura não está aqui</strong>
          <br/>
          Isso não está certo? Mande uma mensagem pra gente conferir!
        </p>
        <Button
          variant="blueSecondary"
          type="button"
          width="90px"
          margin="30px 0px 0px "
          onClick={() => { history.push('/') }}
        >
          VOLTAR
        </Button>
      </div>
    </div>
  )
}

export default NotFoundPage
