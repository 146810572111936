import React from 'react'
import { ButtonV2 as Button } from '../Button/ButtonV2'
import './ConfirmDialog.scss'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  title: string | null | JSX.Element
  description?: string | null | JSX.Element
  buttonConfirm?: string
  buttonCancel?: string
}

interface ConfirmDialogProps extends ConfirmationOptions {
  open: boolean
  onClose: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  if (!props.open) {
    return null
  }

  return (
    <div className="confirm-dialog">
      <div className="confirm-dialog__modal dialog-modal">
        <div className="dialog-modal__title">{ props.title }</div>
        <div className="dialog-modal__description">{ props.description }</div>
        <div className="dialog-modal__buttons">
          <Button margin="0" variant="blue" onClick={ props.onSubmit }>{ props.buttonConfirm || 'Sim' }</Button>
          <Button margin="8px 0 0 0" variant="softBlue" onClick={ props.onClose }>{ props.buttonCancel || 'Não' }</Button>
        </div>
      </div>
      <div className="confirm-dialog__overlay"></div>
    </div>
  )
}

export default ConfirmDialog
