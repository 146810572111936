import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import eqipoService from '../../services/eqipoService'
import Container from '../../components/Container/Container'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import '../MySales/MySales.scss'
import Cta from '../../components/Cta/Cta'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import EqipoCard from '../../components/EqipoCard/EqipoCard'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { Link } from 'react-router-dom'
import './MyPurchases.scss'
import { EqipoLoadingSkeleton } from '../../components/EqiposList/EqipoLoadingSkeleton'
import { type Order, OrderStatus } from '../../models/Orders'

function MyPurchases () {
  const [purchasesList, setPurchasesList] = useState<any | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const result = await eqipoService.getMyPurchases()
        setPurchasesList(result[0] !== null ? result : [])
        setLoading(false)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
    void fetchPurchases()
  }, [])

  const getStatus = (order: Order) => {
    if (order.orderStatus === OrderStatus.new) {
      return OrderStatus.new
    }

    if (order.orderStatus === OrderStatus.authorization) {
      return OrderStatus.authorization
    }

    if (order.orderStatus === OrderStatus.received) {
      return 'bought'
    }
  }

  return (
    <div>
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <ReturnHeader title="MINHAS COMPRAS" border/>
      {loading
        ? <EqipoLoadingSkeleton disableHeader/>
        : <Container>
          {(purchasesList && purchasesList.length === 0)
            ? <Cta
              title="Quem procura, acha"
              content="Seu próximo equipo pode estar te esperando aqui na Qip. Comece a procurar agora mesmo!"
              isButton
              buttonProps={{ title: 'Buscar Equipo', route: '/' }}
            />
            : <div className="containerMySales">
              {purchasesList.map((order: Order, index: number) => {
                return (
                  <div key={index}>
                    <EqipoCard
                      typeEqipo={'grid'}
                      key={index}
                      id={order.listing.id}
                      cover={order.listing.cover}
                      title={order.listing.title}
                      price={order.payment?.[0]?.amount / 100}
                      seller={order.seller}
                      photos={order.listing.photos}
                      status={getStatus(order)}
                    />
                    <div className="containerButtonEqipoItem">
                      <Link to={`/mensagem/${order.roomId || ''}`} style={{ width: '100%' }}>
                        <Button
                          type="button"
                          variant="blueSecondary"
                        >
                          VER MENSAGENS
                        </Button>
                      </Link>
                      {order?.orderStatus === 'shipped' &&
                        <Link to={`/confirmar-recebimento/${order.id}/`} style={{ width: '100%' }}>
                          <Button
                            type="button"
                            variant="primary"
                          >
                            RECEBI O EQUIPO
                          </Button>
                        </Link>
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </Container>
      }
    </div>
  )
}

export default MyPurchases
