import remote from './remote'

export interface SportItem {
  id: string
  title: string
  description: string
  parentId?: string
  canFilter?: boolean
  icon?: string
}

async function getSports (): Promise<SportItem[]> {
  const data = await remote.get()
  return data.sort((a: SportItem, b: SportItem) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  )
}

export default { getSports }
