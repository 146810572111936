import { type UserProfile } from './User'
import { type Payment } from './Payment'
import { type Listing } from './Listings'

export enum OrderStatus {
  new = 'new',
  authorization = 'authorization',
  paymentIssue = 'paymentIssue',
  awaitShipment = 'awaitShipment',
  shipped = 'shipped',
  delivered = 'delivered',
  received = 'received',
  disputed = 'disputed',
  returned = 'returned',
  returnReceived = 'returnReceived',
  canceled = 'canceled',
  revertedPayment = 'revertedPayment',
}

export interface OrderHistory {
  summary: string
  uid: string
  createdAt: string
  status: OrderStatus
}

export interface Order {
  id: string
  history: OrderHistory[]
  orderStatus: string
  payment: [Payment]
  seller: UserProfile
  buyer: UserProfile
  listing: Listing
  roomId: string
  createdAt: string
  originalAmount: number
  fees: number
  shipmentCost: number
  totalAmount: number
}
