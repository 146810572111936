import React from 'react'
import { useHistory } from 'react-router'
import { Container } from './styles'
import { ButtonV2 as Button, type Variant } from '../Button/ButtonV2'

interface CtaProps {
  title: string | React.ReactNode
  titleColor?: string
  content: string | React.ReactNode
  isButton?: boolean
  notSpace?: boolean
  color?: string
  borderColor?: string
  buttonProps?: {
    title: string
    isBack?: boolean
    route?: string
    variant?: Variant
    click?: () => any
  }
}

const Cta = ({
  title,
  content,
  color,
  titleColor,
  borderColor,
  notSpace,
  isButton = false,
  buttonProps = { route: '', isBack: false, title: '', variant: 'blue' }
}: CtaProps) => {
  const history = useHistory()
  return (
    <Container textColor={ color } titleColor={ titleColor } notSpace={ notSpace } borderColor={ borderColor }>
      <h2>{ title }</h2>
      <div>{ content }</div>
      { isButton && (
        <Button
          type="button"
          margin="0px"
          variant={ buttonProps.variant || 'blue' }
          onClick={ () => {
            buttonProps.isBack
              ? history.goBack()
              : buttonProps.click
                ? buttonProps.click()
                : history.push(buttonProps?.route || '/')
          }}
        >
          { buttonProps.title }
        </Button>
      ) }
    </Container>
  )
}

export default Cta
