import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ContainerButton from './ContainerButton'
import { faCheck, faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCcAmex, faCcDinersClub, faCcDiscover, faCcMastercard, faCcVisa, type IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { toast } from 'react-toastify'
import { type Options } from 'react-select'
import paymentsService from '../../services/paymentsService'
import { type BillingAddress, type CheckoutPaymentInfo, type PaymentMethod } from '../../models/Checkout'
import { type Option } from '../../models/Components'
import { availablePaymentMethods } from './checkoutEnums'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import FormField from '../../components/FormComponents/FormField'
import TextField from '../../components/FormComponents/TextField'
import RadioField from '../../components/FormComponents/RadioField'
import CleaveField from '../../components/FormComponents/CleaveField'
import SelectField from '../../components/FormComponents/SelectField'

import './CheckoutPayment.scss'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import shipmentService from '../../services/shipmentService'
import { SelectStateCity } from '../../components/SelectStateCity/SelectStateCity'
import { creditCardValidationSchema } from './Checkout.validation'
import * as Yup from 'yup'

export interface CheckoutPaymentProps {
  listingId: string
  onSavePayment: (paymentParams: any) => void
  paymentInfo: CheckoutPaymentInfo
  setPaymentInfo: (payload: any) => void
  billingAddress?: BillingAddress
  setBillingAddress: (payload: any) => void
}

export interface FilledCreditCard {
  id?: string
  number: string
  cvc: string
  expirationMonth: string
  expirationYear: string
}

export interface PaymentMethods {
  method: string
  title: string
  installmentCount?: number
  installmentsAmount?: string[]
  total?: string
  installmentValue?: string
}

interface SelectOptionProps {
  label: string
  value: string
}

const adaptCardtoMoip = async (paymentInfo: CheckoutPaymentInfo, billingAddress: BillingAddress): Promise<FilledCreditCard> => {
  if (
    !paymentInfo.ccHolder ||
    !paymentInfo.ccNumber ||
    !paymentInfo.ccCvc ||
    !paymentInfo.ccExp
  ) {
    throw Error('Empty fields!')
  }

  const [month, year] = paymentInfo.ccExp.split('/')
  const card = {
    number: paymentInfo.ccNumber,
    cvc: paymentInfo.ccCvc,
    expirationMonth: month,
    expirationYear: year
  }

  const moipCard = await paymentsService.postFundingInstruments(
    {
      method: 'CREDIT_CARD',
      creditCard: {
        expirationMonth: month,
        expirationYear: year,
        number: paymentInfo.ccNumber,
        cvc: paymentInfo.ccCvc,
        holderName: paymentInfo.ccHolder,
        holderDocument: paymentInfo.taxDocument!,
        billingAddress: {
          street: billingAddress.street,
          streetNumber: billingAddress.streetNumber,
          district: billingAddress.district,
          city: billingAddress.city,
          state: billingAddress.state,
          country: billingAddress.country,
          zipcode: billingAddress.zipCode
        }
      }
    })

  return { id: moipCard.id, ...card }
}

const adaptInstallments = (installments: string[]) => {
  if (!installments) return []
  return installments.map((amount, index) => ({
    value: `${index + 1}`,
    label: `${index + 1}x - ${Number(amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
  }))
}

const CheckoutPayment = (props: CheckoutPaymentProps) => {
  const [ccIcon, setCcIcon] = useState<IconDefinition>(faCreditCard)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods[]>([])
  const [installmentsOptions, setInstallmentsOptions] = useState<Option[]>([])
  const [selectedInstallments, setSelectedInstallments] = useState<Option>()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<any>(null)
  const { paymentInfo, setPaymentInfo, listingId } = props

  useEffect(
    () => {
      async function loadPaymentMethods () {
        setLoading(true)
        try {
          const paymentMethods = await paymentsService.getPaymentsMethods({ listingId })
          setPaymentMethods(paymentMethods)
          const installments = paymentMethods.find((method: PaymentMethods) => method.method === availablePaymentMethods.creditCard)
          if (installments) {
            const installmentsVar = adaptInstallments(installments.installmentsAmount)
            setInstallmentsOptions(installmentsVar)
            setSelectedInstallments(installmentsVar[0])
            setPaymentInfo({ ...paymentInfo, installments: installmentsVar[0] })
          }

          setLoading(false)
        } catch (error) {
          const { isWarn, message } = error as any
          isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage/>)
        } finally {
          setLoading(false)
        }
      }

      void loadPaymentMethods()
    },
    [listingId]
  )

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {
      let installments = 1
      let installmentsValue
      let total

      if (paymentInfo.paymentMethod === availablePaymentMethods.creditCard) {
        await creditCardValidationSchema.validate({ ...paymentInfo, ...billingAddress }, { abortEarly: false })
        const creditCard = paymentMethods.find((method: PaymentMethods) => method.method === availablePaymentMethods.creditCard)
        installments = parseInt(selectedInstallments!.value, 10)
        installmentsValue = creditCard!.installmentsAmount![parseInt(selectedInstallments!.value, 10) - 1]
        total = (installments * parseFloat(installmentsValue)).toFixed(2)
      }

      if (paymentInfo.paymentMethod === availablePaymentMethods.boleto) {
        const boleto = paymentMethods.find((method: PaymentMethods) => method.method === availablePaymentMethods.boleto)
        total = boleto!.total
      }

      if (paymentInfo.paymentMethod === availablePaymentMethods.pix) {
        const pix = paymentMethods.find((method: PaymentMethods) => method.method === availablePaymentMethods.pix)
        total = pix!.total
      }

      const payload: PaymentMethod = {
        installments,
        installmentsValue,
        method: paymentInfo.paymentMethod,
        total
      }

      if (paymentInfo.paymentMethod === availablePaymentMethods.creditCard) {
        const card = await adaptCardtoMoip(paymentInfo, billingAddress!)

        payload.creditCard = {
          moipId: card.id,
          // hash,
          billingAddress,
          fullname: paymentInfo.ccHolder,
          birthdate: moment(paymentInfo.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          ccLastDigits: paymentInfo.ccNumber!.slice(paymentInfo.ccNumber!.length - 4),
          taxDocument: {
            type: 'CPF',
            number: paymentInfo.taxDocument
          }
        }
      }

      props.onSavePayment(payload)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const formattedErrors: any = {}

        error.inner.forEach(
          (errorMessage: any) =>
            (formattedErrors[errorMessage.path] = errorMessage.message)
        )

        setErrors(formattedErrors)
        setLoading(false)
      } else {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage/>)
        setLoading(false)
      }
    }
  }

  const handleChangeInstallments = (installments: Option) => {
    setSelectedInstallments(installments)
    setPaymentInfo({ ...paymentInfo, installments: selectedInstallments })
  }

  const handleChange = (name: string, value: string | boolean) => {
    setPaymentInfo({ ...paymentInfo, [name]: value })
  }

  const handleChangeBilling = (name: string, value: string | boolean) => {
    const [, _subName] = name.split('.')
    if (_subName) {
      props.setBillingAddress({
        ...billingAddress,
        [_subName]: value
      })
    }
  }

  const handleCreditCardChange = (type: string) => {
    switch (type) {
      case 'amex':
        setCcIcon(faCcAmex)
        break
      case 'discovery':
        setCcIcon(faCcDiscover)
        break
      case 'diners':
        setCcIcon(faCcDinersClub)
        break
      case 'mastercard':
      case 'maestro':
        setCcIcon(faCcMastercard)
        break
      case 'visa':
        setCcIcon(faCcVisa)
        break
      default:
        setCcIcon(faCreditCard)
    }
  }

  async function getCEPDetail (name: string, zipcode: string) {
    const { billingAddress, setBillingAddress } = props
    setBillingAddress({ ...billingAddress, country: 'BR', [name]: zipcode })
    if (!zipcode || zipcode.length !== 9) {
      return false
    }
    try {
      setLoading(true)
      const { state, city, neighborhood, street } = await shipmentService.getCEP({
        cepTo: zipcode
      })

      const cities: Options<SelectOptionProps> = [{ value: city, label: city }]
      const states: Options<SelectOptionProps> = [{ value: state, label: state }]

      setBillingAddress({
        ...billingAddress,
        city,
        state,
        street,
        streetNumber: '',
        zipCode: zipcode,
        currentStateOption: states[0],
        currentCityOption: cities[0],
        country: 'BR',
        district: neighborhood,
        number: '',
        serviceCode: ''
      })
    } catch (err) {
      // TODO handle error properly
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const { billingAddress } = props

  const handleChangeState = (option: any) => {
    props.setBillingAddress({
      ...billingAddress,
      state: option.value,
      currentStateOption: option,
      currentCityOption: undefined
    })
  }

  const handleChangeCity = (option: any) => {
    props.setBillingAddress({
      ...billingAddress,
      city: option.value,
      currentCityOption: option
    })
  }

  return (
    <div>
      <FormField
        name="paymentMethod"
        label="Forma de Pagamento"
        className="paymentMethod"
      >
        {paymentMethods.map(method => (
          <RadioField
            name="paymentMethod"
            key={method.method}
            onChange={handleChange}
            label={method.title}
            value={method.method}
            checked={paymentInfo.paymentMethod === method.method}
          />
        ))}
      </FormField>

      {paymentInfo.paymentMethod === availablePaymentMethods.creditCard && (
        <form onSubmit={async e => {
          await handleSave(e)
        }}>
          <div className="field credit-card">
            <FormField
              name="ccHolder"
              label="Nome completo (impresso no cartão)"
              validation={errors?.ccHolder}
              required
            >
              <TextField
                autoComplete="cc-name"
                name="ccHolder"
                type="text"
                value={paymentInfo.ccHolder}
                placeholder="Digite o nome impresso no cartão"
                onChange={handleChange}
              />
            </FormField>
            <FormField
              name="ccNumber"
              label="Número do cartão"
              validation={errors?.ccNumber}
              required
            >
              <div className="control has-icons-right">
                <CleaveField
                  name="ccNumber"
                  inputMode="numeric"
                  autoComplete="cc-number"
                  maxLength={19}
                  placeholder="XXXX XXXX XXXX XXXX"
                  options={{
                    creditCard: true,
                    onCreditCardTypeChanged: handleCreditCardChange
                  }}
                  value={paymentInfo.ccNumber}
                  onChange={handleChange}
                />
                <FontAwesomeIcon icon={ccIcon} />
              </div>
            </FormField>
            <div className="credit-card-details">
              <FormField
                name="ccExp"
                label="Validade"
                validation={errors?.ccExp}
                required
              >
                <CleaveField
                  name="ccExp"
                  type="tel"
                  inputMode="numeric"
                  autoComplete="cc-exp"
                  maxLength={5}
                  placeholder="XX/XX"
                  options={{
                    date: true,
                    datePattern: ['m', 'y']
                  }}
                  value={paymentInfo.ccExp}
                  onChange={handleChange}
                />
              </FormField>
              <FormField
                name="ccCvc"
                label="CVV"
                validation={errors?.ccCvc}
                required
              >
                <TextField
                  name="ccCvc"
                  type="text"
                  placeholder="XXX"
                  value={paymentInfo.ccCvc}
                  onChange={handleChange}
                />
              </FormField>
            </div>
            <FormField
              name="installments"
              label="Número de parcelas"
              validation={errors?.installments}
            >
              <SelectField
                value={selectedInstallments}
                onChange={handleChangeInstallments}
                placeholder="Selecionar"
                options={installmentsOptions}
                isClearable={false}
              />
            </FormField>
            <FormField
              name="birthDate"
              label="Data de nascimento"
              optional
              validation={errors?.birthDate}
            >
              <CleaveField
                name="birthDate"
                inputMode="numeric"
                maxLength={10}
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y']
                }}
                placeholder="XX/XX/XXXX"
                value={paymentInfo.birthDate}
                onChange={handleChange}
              />
            </FormField>
            <FormField
              name="taxDocument"
              label="CPF"
              validation={errors?.taxDocument}
              required
            >
              <CleaveField
                name="taxDocument"
                maxLength={14}
                inputMode="numeric"
                options={{
                  blocks: [3, 3, 3, 2],
                  delimiters: ['.', '.', '-'],
                  numericOnly: true
                }}
                placeholder="###.###.###-##"
                value={paymentInfo.taxDocument}
                onChange={handleChange}
              />
            </FormField>
            <div className="subtitleCheckoutPayment">Endereço de Cobrança</div>
            <FormField
              name="zipcode"
              label="CEP"
              validation={errors?.zipcode}
              required
            >
              <div className="control has-icons-right">
                <CleaveField
                  name="zipcode"
                  inputMode="numeric"
                  maxLength={9}
                  options={{
                    numericOnly: true,
                    blocks: [5, 3],
                    delimiters: ['-']
                  }}
                  value={billingAddress?.zipCode}
                  onChange={getCEPDetail}
                  placeholder="#####-###"
                />
                <span className="icon is-right">
                {billingAddress?.zipCode
                  ? (
                    <FontAwesomeIcon className={loading ? 'fa-pulse' : ''} icon={loading ? faSpinner : faCheck}/>
                    )
                  : ''}
              </span>
              </div>
            </FormField>
            <FormField
              name="street"
              label="Rua/Avenida"
              validation={errors?.street}
              required
            >
              <TextField
                name="billingAddress.street"
                autoComplete="street-address"
                value={billingAddress?.street}
                onChange={handleChangeBilling}
                disabled={loading}
                placeholder="Digite o nome da rua/avenida"
              />
            </FormField>

            <FormField
              name="number"
              label="Número"
              validation={errors?.streetNumber}
              required
            >
              <TextField
                name="billingAddress.streetNumber"
                value={billingAddress?.streetNumber}
                onChange={handleChangeBilling}
                disabled={loading}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField
              name="district"
              label="Bairro"
              validation={errors?.district}
              required
            >
              <TextField
                name="billingAddress.district"
                value={billingAddress?.district}
                onChange={handleChangeBilling}
                disabled={loading}
                placeholder="Digite o bairro"
              />
            </FormField>
            <SelectStateCity
              currentStateOption={billingAddress?.currentStateOption}
              currentCityOption={billingAddress?.currentCityOption}
              handleStateChange={handleChangeState}
              handleCityChange={handleChangeCity}
              stateValidation={errors?.state}
              cityValidation={errors?.city}
              isDisabled={loading}
            />
            <ContainerButton>
              <Button
                margin="0px"
                type="submit"
                variant="primary"
                disabled={loading}
              >
                Confirmar Informações
              </Button>
            </ContainerButton>
          </div>
        </form>
      )}

      {paymentInfo.paymentMethod === availablePaymentMethods.boleto && (
        <form onSubmit={async e => {
          await handleSave(e)
        }}>
          <div>
            <div className="labelText">Prazo de compensação de 1 a 2 dias úteis</div>
            <ContainerButton>
              <Button
                margin="0px"
                type="submit"
                variant="primary"
              >
                Confirmar Informações
              </Button>
            </ContainerButton>
          </div>
        </form>
      )}

      {paymentInfo.paymentMethod === availablePaymentMethods.pix && (
        <form onSubmit={async e => {
          await handleSave(e)
        }}>
          <div>
            <div className="labelText"></div>
            <ContainerButton>
              <Button
                margin="0px"
                type="submit"
                variant="primary"
              >
                Confirmar Informações
              </Button>
            </ContainerButton>
          </div>
        </form>
      )}
    </div>
  )
}

export default CheckoutPayment
