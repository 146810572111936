import React from 'react'

import './Form.scss'
import { FormFieldContainer } from './styles'

interface FormFieldProps {
  name: string
  label: string
  required?: boolean
  optional?: boolean
  subtitle?: string
  textColor?: string
  children: React.ReactChild | React.ReactChild[]
  validation?: string | null
  className?: string

}

const FormField = (props: FormFieldProps) => (
  <FormFieldContainer className={ `field ${props.className ? props.className : ''}`} textColor={props.textColor} >
    <label className="label" htmlFor={props.name}>
      {props.label}
      {/* {props.required && <span className="required">Obrigatório</span>} */}
      {props.subtitle && <span className="required">{props.subtitle}</span>}
    </label>
    <div className={props.validation ? 'validation-error' : ''}>
      {props.children}
    </div>
    {props.validation && <div className="error-message">{props.validation}</div>}
  </FormFieldContainer>
)

export default FormField
