import { $http } from '../../httpService'

const getPaymentsMethods = async (listingId: string) => {
  const response = await $http.get(
      `/payments/methods/${listingId}`
  )
  return response.data
}

export default { getPaymentsMethods }
