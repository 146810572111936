import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { $http } from '../../services/httpService'
import { Container, PencilContainer, ProgressBar, ProgressText } from './styles'
import './UploadImage.scss'
import VectorCameraIcon from '../../assets/images/camera.svg'
import PlusIcon from '../../assets/images/plus.svg'
import PencilIcon from '../../assets/images/edit-blue.svg'
import DeleteIcon from '../../assets/images/trash.png'

export interface Image {
  id: number
  hasUploaded: boolean
  src?: string
  imagedata?: File
}

export type OnDelete = (id: number) => void

interface UploadImageProps {
  isProfile?: boolean
  disabled?: boolean
  shared?: boolean
  id: number
  hasUploaded: boolean
  className?: string
  onDelete?: OnDelete
  onFinish: (image: Image) => void
  single?: boolean
  isPlus?: boolean
  preset: string
  src?: string
}

const uploaderApiUrl = process.env.REACT_APP_IMAGE_UPLOADER_API_BASE_URL

const UploadImage = ({
  id = Math.random(),
  isPlus,
  hasUploaded,
  className,
  disabled = false,
  isProfile,
  shared,
  src,
  onFinish,
  preset,
  onDelete
}: UploadImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>()
  const [progress, setProgress] = useState<number>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setImageSrc(src)
  }, [src])

  const uploadService = async (image: Image) => {
    if (!image.imagedata) return
    const data = new FormData()
    data.append('files', image.imagedata)
    setLoading(true)
    const response = await $http.post(
      `${uploaderApiUrl}?preset=${preset}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (data) => {
          const progress = data.total ? Math.round((100 * data.loaded) / data.total) : 0
          setProgress(progress)
        }
      }
    )
    const imageUrl: string = response.data.response

    onFinish({ ...image, src: imageUrl })
    setImageSrc(imageUrl)
    setLoading(false)
  }

  const MIN_SIZE_KB = 20
  const MAX_SIZE_MB = 6

  const checkImageSize = (imageSizeInBytes: number) => {
    const sizeInKB = imageSizeInBytes / 1024
    const sizeInMB = sizeInKB / 1024

    if (sizeInKB < MIN_SIZE_KB) {
      toast.warn('Imagem com baixa resolução')
      return false
    }
    if (sizeInMB > MAX_SIZE_MB) {
      toast.warn('O tamanho do arquivo excede o limite de 6MB')
      return false
    }
    return true
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (event && event.target && event.target.files?.[0]) {
      const imagedata = event.target.files[0]
      if (checkImageSize(imagedata.size)) {
        const reader = new FileReader()
        reader.onload = async function (e) {
          const newSource =
            e.target && typeof e.target.result === 'string'
              ? e.target.result
              : null
          if (newSource) {
            const updatedImage = {
              id,
              imagedata,
              hasUploaded: true,
              src: newSource
            }
            await uploadService(updatedImage)
          }
        }
        reader.readAsDataURL(imagedata)
      }
    }
  }

  const imageUploadBackground = isPlus
    ? PlusIcon
    : VectorCameraIcon

  return (
    <Container
      className={`uploadImage ${className}`}
      hasUploaded={hasUploaded}
      isProfile={isProfile}
      htmlFor={`image${id}`}
    >
      {(!disabled && (!imageSrc || isProfile)) && (
        <input
          type="file"
          name={`image${id}`}
          id={`image${id}`}
          onChange={e => {
            onChange(e, id)
          }}
        />
      )}

      <img
        src={imageSrc || imageUploadBackground}
        alt={isProfile ? 'Foto de perfil' : 'Foto'}
      />

      {!disabled && !isProfile && onDelete && imageSrc && (
        <span className="deleteIcon" onClick={(e) => {
          e.stopPropagation()
          onDelete(id)
        }}>
        <img src={DeleteIcon} alt="Ícone de deleção"/>
      </span>
      )}

      {progress && loading && (
        <>
          <ProgressBar percentage={progress} isProfile={isProfile || false}/>
          <ProgressText>
            <strong>Carregando...</strong>
            <small>{progress}%</small>
          </ProgressText>
        </>
      )}

      {isProfile && !disabled && !shared &&
        <PencilContainer>
          <img src={PencilIcon} alt="pencil-icon"/>
        </PencilContainer>}
    </Container>
  )
}

export default UploadImage
