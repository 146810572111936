import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ZENDESK_SCRIPT_ID = 'ze-snippet'
const ZENDESK_SCRIPT_SRC = 'https://static.zdassets.com/ekr/snippet.js?key=f9f35cc7-0d20-4ed0-b5f1-d7ad76f85389'

const ZendeskWidget: React.FC = () => {
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    const loadZendeskWidget = () => {
      const script = document.createElement('script')
      script.id = ZENDESK_SCRIPT_ID
      script.async = true
      script.src = ZENDESK_SCRIPT_SRC
      // Inject additional inline JavaScript
      script.innerHTML = ''
      document.head.appendChild(script)
      initializeZendesk()
    }

    const initializeZendesk = () => {
      const checkZendeskAPI = setInterval(() => {
        if (typeof window.zE !== 'undefined') {
          clearInterval(checkZendeskAPI)
          if (pathname === '/ajuda/') {
            console.log('Zendesk is initialized:', window.zE)
            window.zE('messenger', 'open')
            hideZendeskElements()
          } else {
            window.zE('messenger', 'hide')
          }
        }
      }, 100)

      // Clear interval in case of unmount
      return () => {
        clearInterval(checkZendeskAPI)
      }
    }

    const hideZendeskElements = () => {
      // Hide the iframe for web_widget
      const webWidgetIframe: any = document.querySelector('iframe[data-product="web_widget"]')
      if (webWidgetIframe) {
        webWidgetIframe.style.display = 'none'
        console.log('web_widget iframe hidden')
      } else {
        console.log('web_widget iframe not found')
      }

      // Retry finding the Messaging window iframe
      retryHideMessagingWindowIframe()
    }

    const retryHideMessagingWindowIframe = () => {
      const MAX_RETRIES = 60 // Maximum attempts to find the iframe
      let attempts = 0

      const checkMessagingWindowIframe = setInterval(() => {
        const messagingWindowIframe = document.querySelector('iframe[title="Messaging window"]')
        if (messagingWindowIframe) {
          clearInterval(checkMessagingWindowIframe)
          console.log('Messaging window iframe found')
          hideCloseButtonInIframe(messagingWindowIframe)
        } else {
          attempts++
          console.log('Retrying to find Messaging window iframe...')
          if (attempts >= MAX_RETRIES) {
            clearInterval(checkMessagingWindowIframe)
            console.log('Messaging window iframe not found after max retries')
          }
        }
      }, 100) // Retry every 100ms

      // Clear interval in case of unmount
      return () => { clearInterval(checkMessagingWindowIframe) }
    }

    const hideCloseButtonInIframe = (iframe: any) => {
      if (iframe.contentWindow) {
        const iframeDoc = iframe.contentWindow.document
        const closeButton = iframeDoc.querySelector('button[aria-label="Close"]')
        if (closeButton) {
          closeButton.style.display = 'none'
          console.log('closeButton hidden')
        } else {
          console.log('closeButton not found')
        }
      } else {
        console.log('Content window not found for Messaging window iframe')
      }
    }
    const removeZendeskWidget = () => {
      // Remove the Zendesk script
      const script = document.getElementById(ZENDESK_SCRIPT_ID)
      if (script) {
        script.remove()
      }

      // Attempt to hide the messenger if the Zendesk API is loaded
      if (typeof window.zE !== 'undefined' && window.zE) {
        window.zE('messenger', 'hide')
        delete window.zE
        delete window.zEmbed
        delete window.zEACLoaded
      }

      // Thoroughly remove all potential injected elements by Zendesk
      document.querySelectorAll(`
        body > div[style*="visibility: visible;"],
        body > div[style*="position: fixed;"],
        body > iframe[data-product="web_widget"],
        body > iframe[title="No content"],
        body > div[role="presentation"]
      `).forEach((el) => {
        el.remove()
      })

      // Also remove any other potential remnants
      document.querySelectorAll(`
        body > iframe,
        body > div[style*="z-index: 999999;"],
        body > div[role="presentation"]
      `).forEach((el) => {
        el.remove()
      })
    }

    if (pathname === '/ajuda/') {
      loadZendeskWidget()
    } else {
      removeZendeskWidget()
    }

    // Cleanup on component unmount
    return () => {
      removeZendeskWidget()
    }
  }, [pathname])

  return null
}

export default ZendeskWidget
