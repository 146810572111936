import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'

import FormField from '../../../components/FormComponents/FormField'
import SelectField from '../../../components/FormComponents/SelectField'
import TextField from '../../../components/FormComponents/TextField'
import { ButtonV2 as Button } from '../../../components/Button/ButtonV2'
import SwitchField from '../../../components/FormComponents/SwitchField'
import { newEquipoFormValidationSchema } from './NewEqipoForm.validation'
import { type EqipoInfo } from './NewEqipo'

import './NewEqipoForm.scss'
import CleaveField from '../../../components/FormComponents/CleaveField'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'
import CheckboxField from '../../../components/FormComponents/CheckboxField'
import Cta from '../../../components/Cta/Cta'
import { SelectSports } from '../../../components/SelectSports/SelectSports'
import SelectBrands from '../../../components/SelectBrands/SelectBrands'
import SelectSizes from '../../../components/SelectSizes/SelectSizes'
import * as Yup from 'yup'
import { conditions } from '../Eqipo'

export interface FilledEqipoInfo {
  title: string
  story: string
  sports: string[]
  itemCondition: string
  price: number
  brand: string
  size: string
  hasInvoice: boolean
  hasWarranty: boolean
  itemStatus?: string
}

export const itemsCondition = Object.keys(conditions).map(key => ({
  value: key,
  label: conditions[key]
}))

interface NewEqipoFormProps {
  onSubmitForm: (eqipoInfo: FilledEqipoInfo) => void
  eqipoInfo: EqipoInfo
  setEqipoInfo: (payload: any) => void
}

const convertEqipoInfoToListingPayload = (eqipoInfo: EqipoInfo): FilledEqipoInfo => {
  if (
    !eqipoInfo.title ||
    !eqipoInfo.story ||
    !eqipoInfo.sports ||
    !eqipoInfo.itemCondition ||
    !eqipoInfo.price ||
    !eqipoInfo.brand ||
    !eqipoInfo.size
  ) {
    throw Error('Empty fields!')
  }

  return {
    title: eqipoInfo.title,
    story: eqipoInfo.story,
    price: +eqipoInfo.price,
    sports: eqipoInfo.sports.map(sport => sport.id),
    itemCondition: eqipoInfo.itemCondition.value,
    brand: eqipoInfo.brand.name.replace(/\b\w/g, c => c.toUpperCase()),
    hasWarranty: false, // TODO: removed due to the figma template
    hasInvoice: !!eqipoInfo.hasInvoice,
    size: eqipoInfo.size.label
  }
}

const NewEqipoForm = (props: NewEqipoFormProps) => {
  const [errors, setErrors] = useState<any>(null)
  const { eqipoInfo, setEqipoInfo } = props
  const formRef = useRef<any>(null)
  const linkExternalTermsAndConditions =
    process.env.REACT_APP_LINK_EXTERNAL_TERMS_CONDITIONS

  const handleChange = (name: string, value: string | boolean) => {
    setEqipoInfo({ ...eqipoInfo, [name]: value })
  }

  const handleCleaveChange = (name: string, value: string, rawValue: string) => {
    setEqipoInfo({ ...eqipoInfo, [name]: rawValue, [`${name}WithMask`]: value })
  }

  const handleChangeCheckbox = () => {
    setEqipoInfo({ ...eqipoInfo, termOfAgreement: !(eqipoInfo.termOfAgreement) })
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await newEquipoFormValidationSchema.validate(eqipoInfo, { abortEarly: false })
      const newListing = convertEqipoInfoToListingPayload(eqipoInfo)
      props.onSubmitForm(newListing)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const formattedErrors: any = {}

        error.inner.forEach(
          (errorMessage: any) =>
            (formattedErrors[errorMessage.path] = errorMessage.message)
        )

        setErrors(formattedErrors)
      } else {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage/>)
      }
    }
  }

  return (
    <form ref={formRef} className="new-eqipo-form" noValidate onSubmit={async e => {
      await handleSave(e)
    }}>
      <Cta
        notSpace
        title="INFORMAÇÕES DO EQUIPO"
        content={
          <p>Não se esqueça de nenhum detalhe. <br/>
            Conte por que seu equipo é incrível.</p>
        }
      />
      <FormField
        name="price"
        label="Qual o nome do seu equipo?"
        validation={errors?.title}
        required
      >
        <TextField
          name="title"
          type="text"
          placeholder="Ex. Garmin Fenix 5s"
          value={eqipoInfo.title}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        name="story"
        label="Conte a história do seu equipo"
        validation={errors?.story}
        required
      >
        <TextField
          name="story"
          value={eqipoInfo.story}
          placeholder="Ex. Esse Garmin “correu” uma ultramaratona de 100 km na Patagônia, e me trouxe saúde mental. Comprei em 2022 na caixa, tem tela de Safira, e a bateria está com 80% de vida útil"
          onChange={handleChange}
          textArea
        />
      </FormField>
      <FormField
        name="sports"
        label="Esse equipo serve para?"
        validation={errors?.sports}
        required
      >
        <SelectSports
          value={eqipoInfo.sports || []}
          onChange={(sports) => {
            setEqipoInfo({ ...eqipoInfo, sports })
          }}
          placeholder="Ex. Corrida - Trail, Natação - Piscina"
        />
      </FormField>
      <FormField
        name="itemCondition"
        label="Em que estado de uso está seu equipo?"
        validation={errors?.itemCondition}
        required
      >
        <SelectField
          value={eqipoInfo.itemCondition}
          onChange={(itemCondition) => {
            setEqipoInfo({ ...eqipoInfo, itemCondition })
          }}
          placeholder="Ex. Quase novo (pouco usado)"
          options={itemsCondition}
          id="itemCondition"
        />
      </FormField>
      <FormField
        name="size"
        label="Qual o tamanho do seu equipo?"
        validation={errors?.size}
        required
      >
        <SelectSizes
          value={eqipoInfo.size}
          onChange={(size: any) => {
            setEqipoInfo({ ...eqipoInfo, size })
          }}
          placeholder="Ex. 47 MM"
        />
      </FormField>
      <FormField
        name="brand"
        label="Qual a marca do seu equipo?"
        validation={errors?.brand}
        required
      >
        <SelectBrands
          value={eqipoInfo.brand}
          sports={eqipoInfo.sports?.map(s => s.id)}
          onChange={(brand: any) => {
            setEqipoInfo({ ...eqipoInfo, brand })
          }}
          placeholder="Ex. Garmin"
        />
      </FormField>
      <FormField name="invoice" label="Tem Nota Fiscal?">
        <SwitchField
          name="hasInvoice"
          checked={!!eqipoInfo.hasInvoice}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        name="price"
        label="Quanto vale seu equipo?"
        validation={errors?.price}
        required
      >
        <CleaveField
          name="price"
          value={eqipoInfo.priceWithMask}
          onChange={handleCleaveChange}
          type="tel"
          placeholder="Ex. R$ 2.000"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalScale: 2,
            numeralPositiveOnly: true,
            rawValueTrimPrefix: true,
            numeralDecimalMark: ',',
            delimiter: '.',
            prefix: 'R$ '
          }}
        />
      </FormField>
      <FormField
        name="termOfAgreement"
        label="Termos de entrega pessoal"
        validation={errors?.termOfAgreement}
        required
      >
        <CheckboxField
          value={!!(eqipoInfo?.termOfAgreement)}
          checked={!!(eqipoInfo?.termOfAgreement)}
          onChange={handleChangeCheckbox}
          label="LEIA E CONCORDE COM OS"
          labelLink="termos de entrega e recebimento"
          link={linkExternalTermsAndConditions}
        />
      </FormField>
      <div className="new-eqipo-form__button">
        <Button
          type="submit"
          variant="primary"
        >
          Revisar meu Anúncio
        </Button>
      </div>
    </form>
  )
}

export default NewEqipoForm
