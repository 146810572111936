import { $http } from '../../httpService'
import { type ListingStatus } from '../../../models/Listings'

interface EqipoParams {
  id: string
  slug: string
}

interface UserEqipoParams {
  id: string
}

interface UserEqiposParams {
  itemStatus: ListingStatus[]
}

async function getByIdAndSlug ({ id, slug }: EqipoParams) {
  const response = await $http.get(`/v1/listing/${id}/${slug}`)
  return response.data
}

async function getById ({ id }: UserEqipoParams) {
  const response = await $http.get(`/v1/listing/${id}`)
  return response.data
}

async function getMyEqipos (filter?: UserEqiposParams) {
  const url = '/v1/listings/me'
  const params = new URLSearchParams()

  if (filter?.itemStatus) {
    for (const status of filter.itemStatus) {
      params.append('itemStatus', status.trim())
    }
  }
  const response = await $http.get(url, { params })
  return response.data
}

async function getHighlights () {
  const url = '/v1/listings/highlights'
  const response = await $http.get(url)
  return response.data
}

async function getMyPurchases () {
  const response = await $http.get('/v1/orders')
  return response.data
}

async function getEqipoQuery (query: string) {
  const response = await $http.get(`/v1/search?${query}`)
  return response.data
}

export default {
  getById,
  getByIdAndSlug,
  getMyEqipos,
  getHighlights,
  getMyPurchases,
  getEqipoQuery
}
