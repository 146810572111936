import { $http } from '../../httpService'
import { type ListingStatus } from '../../../models/Listings'

export interface NewListing {
  title?: string
  story: string
  price: number
  itemCondition: string
  size: string
  brand: string
  yearOfManufacture?: string
  model?: string
  material?: string
  capacity?: string
  hasInvoice: boolean
  hasWarranty: boolean
  sports: string[]
  photos: string[]
  cover: string
  shipmentWeight?: number
  shipmentDepth?: number
  shipmentHeight?: number
  shipmentWidth?: number
  shipmentDiameter?: number
}

export interface EditListing extends Partial<NewListing> {
  itemStatus?: ListingStatus
}

export interface EditListingPrice {
  buyerId: string
  price: number
}

async function searchListings (query?: string) {
  const response = await $http.get(`/v1/search?${String(query)}`)
  return response.data.listings
}

async function getEnabledListings (userId: string) {
  const response = await $http.get(`/v1/listings/user/${userId}/enabled`)
  return response.data
}

async function postListing (payload: NewListing) {
  const response = await $http.post('/v1/listing', payload)

  return response.data
}

async function editListing (id: string, payload: EditListing) {
  const response = await $http.patch(`/v1/listing/${id}`, payload)

  return response.data
}

async function editListingPrice (id: string, payload: EditListing) {
  const response = await $http.put(`/v1/listing/${id}/negotiatedPrice`, payload)
  return response.data
}

async function searchListingBrand ({ search }: { search: string }) {
  let response
  if (search.length === 0) {
    response = await $http.get('/v1/listings/brands')
  } else {
    response = await $http.get(`/v1/listings/brands?search=${search}`)
  }
  return response.data
}

async function createListingBrand (body: { name: string, sports: string[] }) {
  const response = await $http.put('/v1/listings/brands', body)
  return response.data
}

async function searchListingSize ({ search }: { search: string }) {
  let response
  if (search.length === 0) {
    response = await $http.get('/v1/listings/sizes')
  } else {
    response = await $http.get(`/v1/listings/sizes?search=${search}`)
  }
  return response.data
}

async function createListingSize (body: { label: string }) {
  const response = await $http.put('/v1/listings/sizes', body)
  return response.data
}

export default { searchListings, getEnabledListings, postListing, editListing, editListingPrice, searchListingBrand, createListingBrand, searchListingSize, createListingSize }
