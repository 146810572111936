import React from 'react'
import { useHistory } from 'react-router-dom'
import './ReturnHeader.scss'
import returnIcon from '../../assets/images/return-icon.svg'
import Skeleton from 'react-loading-skeleton'

interface ReturnHeaderProps {
  title: string
  border: boolean
  noIcon?: boolean
  click?: any
  style?: any
}

const ReturnHeader: React.FC<ReturnHeaderProps> = ({ title, border, noIcon = false, style, click }) => {
  const history = useHistory()

  return (
    <div
      style={style}
      className={border ? 'return-header return-border' : 'return-header'}
      onClick={click || history.goBack}
    >
      {!noIcon && <img src={returnIcon} alt="Retorno"/>}
      {title ? (<p>{title}</p>) : (<Skeleton height={30} width={160} style={{ marginLeft: '10px' }}/>)}
    </div>
  )
}

export default ReturnHeader
