import React, { useContext, useState } from 'react'
import EqipoCard from '../EqipoCard/EqipoCard'
import iconFilter from '../../assets/images/filter-icon-2.svg'

import './EqiposList.scss'
import Filter from '../Filter/Filter'
import { type Listing } from '../../models/Listings'
import { EqipoLoadingSkeleton } from './EqipoLoadingSkeleton'
import GridBlueIcon from '../../assets/images/grid-blue.svg'
import GridIcon from '../../assets/images/grid.svg'
import ListBlueIcon from '../../assets/images/list-blue.svg'
import ListIcon from '../../assets/images/list.svg'
import { GlobalContext } from '../../context/GlobalContext'
import { Roles } from '../../models/User'
import FilterButtonList from '../Filter/FilterButtonList'
import { type ListingFilter } from '../ListingsProvider/ListingsProvider'

interface EqiposListProps {
  disableHeader?: boolean
  disableFilterModal?: boolean
  hidePrices?: boolean
  listings?: Listing[]
  isLoading?: boolean
  filter?: ListingFilter
  refreshFilter?: () => Promise<void>
  isFilterApplied?: boolean
}

const EqiposList = ({
  disableHeader = false,
  disableFilterModal = false,
  hidePrices = false,
  listings = [] as Listing[],
  isLoading = false,
  filter,
  refreshFilter,
  isFilterApplied = false
}: EqiposListProps) => {
  const [isFilterDialogOpen, setFilterDialogOpen] = useState(false)
  const [typeEqipo, setTypeEqipo] = useState<'grid' | 'list' | null>('grid')
  const { userProfile } = useContext(GlobalContext)
  const isAdmin =
    userProfile?.customClaims?.roles?.includes(Roles.superAdmin) || false

  const handleOpenFilterDialog = () => {
    setFilterDialogOpen(true)
  }

  if (isLoading) {
    return <EqipoLoadingSkeleton disableHeader={disableHeader}/>
  }

  return (
    <div className="eqipos">
      {!disableHeader && listings?.length !== 0 && (
        <div className="eqipos-header">
          <div className="eqipos-header-row">
            <div className="grid-system">
            <span onClick={() => {
              setTypeEqipo('grid')
            }}>
              <img src={typeEqipo === 'grid' ? GridBlueIcon : GridIcon} alt="grid-icon"/>
            </span>
              <span onClick={() => {
                setTypeEqipo('list')
              }}>
              <img src={typeEqipo === 'list' ? ListBlueIcon : ListIcon} alt="list-icon"/>
            </span>
            </div>
            {!disableFilterModal && (
              <div className="eqipos-header__filter-group"
                onClick={handleOpenFilterDialog}
              >
                <img src={iconFilter} alt="filter"/>
                <span className="eqipos-header__filter-label">Filtrar</span>
              </div>
            )}
          </div>
          <div className="eqipos-header-row">
          {isFilterApplied &&
            <div className="filter-list">
              <strong>FILTROS:</strong>
              <div>
                {filter?.sports?.map(sport => (
                  <FilterButtonList
                    type="sport"
                    label={sport.title}
                    refreshFilter={refreshFilter}
                    id={sport.id}
                    key={sport.id}
                  />
                ))}
                {filter?.conditions?.map(condition => (
                  <FilterButtonList
                    type="condition"
                    label={condition.label}
                    refreshFilter={refreshFilter}
                    id={condition.value}
                    key={condition.value}
                  />
                ))}
                {filter?.states?.map(state => (
                  <FilterButtonList
                    type="state"
                    label={state.value}
                    refreshFilter={refreshFilter}
                    id={state.value}
                    key={state.value}
                  />
                ))}
              </div>
            </div>
          }
          </div>
        </div>
      )}
      {isFilterDialogOpen && (
        <Filter isAbsolute closePanel={() => {
          setFilterDialogOpen(false)
        }}/>
      )}
      <div className={typeEqipo === 'grid' ? 'eqipos__grid' : 'eqipos__list'}>
        {
          listings?.map((listing: any) => {
            return (
              <EqipoCard
                typeEqipo={typeEqipo}
                key={listing.id}
                id={listing.id}
                cover={listing.cover}
                title={listing.title}
                status={listing.itemStatus}
                price={!hidePrices && (listing.negotiatedPrices?.[0]?.price || listing.price)}
                seller={listing.seller}
                photos={listing.photos}
                isAdmin={isAdmin}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default EqiposList
