import { $http } from '../../httpService'
import { type RoomParams } from '../index'

interface MessageParams {
  roomId: string
  text: string
}

async function get () {
  const response = await $http.get('/v1/rooms')
  return response.data
}

async function getById (id: string) {
  const response = await $http.get(`/v1/rooms/${id}`)
  return response.data
}

async function getMessagePaginate (roomId: string, lastMessageId: string) {
  const response = await $http.get(`/v1/rooms/${roomId}/messages?limit=20&reverse=true&offset=${lastMessageId}`)
  return response.data
}
async function messagesUnread () {
  const response = await $http.get('/v1/messages/unread')
  return response.data
}

async function getMessagesByRoomId (roomId: string) {
  const response = await $http.get(`/v1/rooms/${roomId}/messages?limit=20&reverse=true`)
  return response.data
}

async function send ({ roomId, text }: MessageParams) {
  const response = await $http.post('/v1/message', {
    roomId,
    text
  })

  return response.data
}

async function createRoom ({ listingId, message }: RoomParams) {
  const response = await $http.post('/v1/rooms', {
    listingId,
    message
  })

  return response.data
}
async function readMessage (roomId: string, messageId: string) {
  const response = await $http.put(`/v1/rooms/${roomId}/messages/${messageId}/read`)
  return response.data
}

export default {
  get,
  getById,
  send,
  getMessagesByRoomId,
  getMessagePaginate,
  createRoom,
  readMessage,
  messagesUnread
}
