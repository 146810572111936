import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import './ForgotPassword.scss'
import Cta from '../../components/Cta/Cta'
import authService from '../../services/authService'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

const ForgotPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation<{ email: string }>()
  const [email, setEmail] = useState<string>()
  const [isEmailSend, setIsEmailSend] = useState(false)

  useEffect(() => {
    const sendPasswordResetEmail = async () => {
      try {
        setIsLoading(true)
        const email_ = location.state?.email
        setEmail(email)
        await authService.sendPasswordResetEmail(email_)
        setIsEmailSend(true)
        setIsLoading(false)
      } catch (error: any) {
        console.log(error.error.response.status)
        const { error: { response: { status } } } = error
        if (status === 404) {
          setIsEmailSend(false)
          setIsLoading(false)
          toast.warn(<ToastMessage label={'E-mail não cadastrado'}/>)
        } else {
          setIsEmailSend(false)
          setIsLoading(false)
          toast.error(<ToastMessage/>)
        }
      }
    }

    void sendPasswordResetEmail()
  }, [])

  return (
    <>
      <Navbar/>
      <div className="containerResetPassword">
        {isLoading
          ? <div>
            <Skeleton height={50} style={{ marginBottom: '15px' }}/>
            <Skeleton height={100} style={{ marginBottom: '15px' }}/>
            <Skeleton height={50}/>
          </div>
          : isEmailSend
            ? (
              <Cta
                isButton
                borderColor="#E0E0E0"
                buttonProps={{
                  route: '/entrar',
                  isBack: false,
                  title: 'VOLTAR PARA LOGIN',
                  variant: 'blueNoBorder'
                }}
                content={
                  <>
                    Enviamos um e-mail com instruções
                    <br/> para recuperar sua senha!
                    <br/>{' '}
                    <span style={{ color: '#ff6e5c' }}>
                  Não se esqueça de conferir a pasta de SPAM
                </span>
                  </>
                }
                title="AGORA FALTA POUCO!"
              />
              )
            : null}
      </div>
    </>
  )
}

export default ForgotPassword
