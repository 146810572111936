import React from 'react'
import { type UserProfile } from '../../../models/User'

import { Container, Description, Profile } from './styles'
import ProfileAvatar from './ProfileAvatar'
import ProfileInformation from './ProfileInformation'
import ProfileSports from './ProfileSports'
import ProfileSocials from './ProfileSocials'
import EditProfileButton from './EditProfileButton'
import ProfileLoading from './ProfileLoading'

interface IProfileContainer {
  profile: UserProfile
  shared?: boolean
  isOwner?: boolean
  handleChangeAvatar?: any
  loading?: boolean
}

const ProfileContainer = (
  {
    profile,
    shared = false,
    isOwner = false,
    handleChangeAvatar,
    loading = false
  }: IProfileContainer) => {
  if (loading) {
    return <ProfileLoading />
  }

  return (
    <Container>
      <Profile>
        <ProfileAvatar
          shared={ shared }
          isOwner={ isOwner }
          profile={ profile }
          handleChangeAvatar={ handleChangeAvatar }
        />
        <ProfileInformation profile={ profile } />
      </Profile>
      <Description>
        <ProfileSports profile={ profile } />
        <ProfileSocials profile={ profile } isOwner={ isOwner } />
        { isOwner && <EditProfileButton profile={ profile } /> }
      </Description>
    </Container>
  )
}

export default ProfileContainer
