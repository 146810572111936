import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import queryString from 'query-string'
import messageService from '../../services/messageService'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import RoomResultCard from './RoomResultCard/RoomResultCard'
import './Rooms.scss'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import RoomFilters from './RoomFilters/RoomFilters'
import { GlobalContext } from '../../context/GlobalContext'
import Cta from '../../components/Cta/Cta'

interface RoomsProps {
  values: any
  errorMessage: string
  history?: any
}

function Rooms (props: RoomsProps) {
  const { userProfile } = useContext(GlobalContext)
  const [rooms, setRooms] = useState<any[]>([])
  const [seller, setSeller] = useState(true)
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [unreadSellingMessages, setUnreadSellingMessages] = useState(0) // New state variable
  const [unreadBuyingMessages, setUnreadBuyingMessages] = useState(0)

  useEffect(() => {
    async function loadRooms () {
      try {
        setLoading(true)
        const rooms = await messageService.getRooms()

        const unreadSelling = rooms.reduce(
          (total: number, room: any) =>
            total + (room.listing.userId === userProfile?.id ? room.countMessageNotRead : 0),
          0
        )
        const unreadBuying = rooms.reduce(
          (total: number, room: any) =>
            total + (room.listing.userId !== userProfile?.id ? room.countMessageNotRead : 0),
          0
        )

        setUnreadSellingMessages(unreadSelling)
        setUnreadBuyingMessages(unreadBuying)

        setSeller(unreadSelling >= unreadBuying)
        setRooms(rooms)
        setLoading(false)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        setLoading(false)
      }
    }

    void loadRooms()
  }, [])

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    const sellerQueryParam = queryParams.seller === 'true'
    setSeller(sellerQueryParam)
  }, [location.search])

  const handleRedirectMessage = (roomId: string) => {
    props.history.push(`/mensagem/${roomId}`)
  }

  const handleSellerChange = (value: boolean) => {
    setSeller(value)
    const queryParams = queryString.parse(location.search)
    queryParams.seller = value.toString()
    props.history.replace({ search: queryString.stringify(queryParams) })
  }

  const handleFilterChange = (value: string) => {
    setFilter(value)
  }

  const roomsSeller =
    rooms.filter((room: any) => (seller ? room.listing.userId === userProfile?.id : room.listing.userId !== userProfile?.id))

  const roomsFiltered = filter ? roomsSeller.filter((room: any) => room.listing.itemStatus === filter) : roomsSeller

  function renderLoading () {
    return (
      <div>
        <RoomFilters />
        <div style={{ margin: '40px 10px 0 10px' }}>
          <Skeleton height={80} count={4} style={{ marginBottom: '30px' }}/>
        </div>
      </div>
    )
  }

  function renderEmptyRooms (seller: boolean) {
    return (
      <div style={{ margin: '0 20px' }}>
        {seller
          ? <Cta
          title="Mais vazio que pneu furado"
          content={
            <>
              Você ainda não recebeu mensagem.
              <br /> <br />
              Anuncie e divulgue seus equipos para fazer sua primeira venda!
            </>
          }
          isButton
          buttonProps={{ title: 'Vender Equipo', route: '/eqipo/novo' }}
        />
          : <Cta
          title="Quem procura, acha"
          content="Seu próximo equipo pode estar te esperando aqui na Qip. Comece a procurar agora mesmo!"
          isButton
          buttonProps={{ title: 'Buscar Eqipo', route: '/' }}
        />}
      </div>
    )
  }

  return (
    <div>
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <ReturnHeader title="Mensagens" border={true}/>

      {loading ? renderLoading() : null}

      <RoomFilters
        seller={seller}
        setSeller={handleSellerChange}
        filter={filter}
        setFilter={handleFilterChange}
        unreadSellingMessages={unreadSellingMessages}
        unreadBuyingMessages={unreadBuyingMessages}
      />

      { !loading &&
        (roomsFiltered.length === 0
          ? renderEmptyRooms(seller)
          : <div className="Rooms">
              {roomsFiltered.map((room: any) => {
                const sellerInfo = seller
                  ? room.users.find((user: any) => user.id !== room.listing.userId)
                  : room.users.find((user: any) => user.id === room.listing.userId)
                return (
                  <div key={room.id} className="tile is-parent is-12">
                    <RoomResultCard
                      title={room.listing.title}
                      cover={room.listing.cover}
                      seller={sellerInfo}
                      itemStatus={room.listing.itemStatus}
                      orderStatus={room.order?.orderStatus}
                      userSeller={room.listing.userId === userProfile?.id}
                      countMessageNotRead={room.countMessageNotRead}
                      countMessageRead={room.countMessageRead}
                      handleRedirectMessage={() => {
                        handleRedirectMessage(room.id)
                      }}
                    />
                  </div>
                )
              })}
            </div>
        )}
    </div>
  )
}

export default Rooms
