import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Link, type match, type RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import EqipoGallery from '../../components/EqipoGallery/EqipoGallery'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { months } from '../Profile/ProfileContainer/ProfileInformation'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'

import eqipoService from '../../services/eqipoService'
import listingsService from '../../services/listingsService'
import messageService from '../../services/messageService'

import { type FullListing, ListingStatus } from '../../models/Listings'
import { Roles } from '../../models/User'

import { GlobalContext } from '../../context/GlobalContext'
import './Eqipo.scss'
import { EqipoLoadingSkeleton } from './EqipoLoadingSkeleton'
import emptyProfileAvatar from '../../assets/images/empty-profile.svg'

interface EqipoMatch extends match {
  params: {
    id: string
    slug: string
  }
}

interface EqipoProps extends RouteComponentProps {
  match: EqipoMatch
}

export const conditions: Record<string, string> = {
  AS_NEW: 'Novinho (nunca usado)',
  LIGHTLY_USED: 'Quase novo (pouco usado)',
  USED: 'Usado (em boas condições de uso)'
}

export const translateCondition = (condition: string): string => conditions[condition] || ''

const Eqipo: React.FC<EqipoProps> = ({ match, history }) => {
  const { userProfile } = useContext(GlobalContext)
  const [id, setId] = useState('')
  const [slug, setSlug] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [eqipo, setEqipo] = useState<FullListing | null>(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [formattedMonthCreateTimeUser, setFormattedMonthCreateTimeUser] = useState<string>()
  const [yearCreateTimeUser, setYearCreateTimeUser] = useState<number>()

  useEffect(() => {
    const loadData = async () => {
      try {
        let eqipo
        const { id, slug } = match.params

        setId(id)
        setSlug(slug)

        if (slug) {
          eqipo = await eqipoService.getPublicEqipo({ id, slug })
        } else {
          eqipo = await eqipoService.getUserEqipo({ id })
        }

        const createTimeUser = eqipo.user.createdAt
        const formattedCreateTimeUser = moment(createTimeUser)
        const monthCreateTimeUser = formattedCreateTimeUser.month()
        const formattedMonthCreateTimeUser = months[monthCreateTimeUser]
        const yearCreateTimeUser = formattedCreateTimeUser.year()
        const isAdmin =
          userProfile?.customClaims?.roles?.includes(Roles.superAdmin) || false

        setEqipo(eqipo)
        setFormattedMonthCreateTimeUser(formattedMonthCreateTimeUser)
        setYearCreateTimeUser(yearCreateTimeUser)
        setIsAdmin(isAdmin)
        setLoading(false)
      } catch (error) {
        history.push('/not-found')
      }
    }

    void loadData()
  }, [match.params, history])

  const buyEqipo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    localStorage.setItem('checkoutListing', id)
    localStorage.setItem('checkoutListingSlug', slug)
    history.push('/checkout', { listingId: id })
  }

  const sendMessage = async () => {
    setLoadingButton(true)
    try {
      const { id: roomId } = await messageService.createRoom(
        {
          listingId: id,
          message: ''
        })
      history.push(`/mensagem/${roomId}/`)
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    } finally {
      setLoadingButton(false)
    }
  }

  const sendToReview = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    try {
      await listingsService.sendToReview(eqipo!.id)
      history.push(`/eqipo/${id}/curadoria`)
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    }
  }

  const editListing = () => {
    history.push(`/eqipo/${id}/editar`)
  }

  const approveListing = async () => {
    try {
      await listingsService.editListing(eqipo!.id, { itemStatus: ListingStatus.ENABLED })
      history.push('/')
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    }
  }

  const hasUserProfile = !!userProfile
  const isPublic = eqipo?.itemStatus === ListingStatus.ENABLED
  const isDraft = eqipo?.itemStatus === ListingStatus.DRAFT
  const isWatingReview = eqipo?.itemStatus === ListingStatus.WAITING_REVIEW
  const isOwner = userProfile && userProfile.id === eqipo?.userId
  const canEdit = isOwner || isAdmin
  const photos = [...(eqipo?.photos || [])]
  // if (eqipo?.cover) {
  //   photos.push(eqipo?.cover);
  // }

  return (
    <ListingsProvider filter={initialFilter}>
      <div className="page page-eqipo">
        <Navbar/>
        {loading
          ? (
            <EqipoLoadingSkeleton/>
            )
          : <>
            <Helmet>
              <meta property="og:title" content={eqipo?.title} />
              <meta property="og:image" content={eqipo?.cover} />
              <meta property="og:url" content={`https://qip.com.br/eqipo/${eqipo?.id}/${slug}`} />
              <meta property="og:type" content="website" />
            </Helmet>
            <div className="containerEqipo">
              <div className="coverEqipo">
                <img
                  src={eqipo?.cover}
                  alt={`Imagem capa do equipo ${eqipo?.title}`}
                />
              </div>
              <h3 className="titleEqipo">{eqipo?.title}</h3>
              <div className="storyEqipo">
                <div className="profileEqipo">
                  <img
                    src={eqipo?.user.photoURL || emptyProfileAvatar}
                    alt={`perfil do ${eqipo?.user.displayName}`}
                  />
                  <div>
                    <h5>{eqipo?.user?.displayName}</h5>
                    <strong>
                      Estou em {eqipo?.user?.address?.currentCity}
                    </strong>
                    <small>
                      Sou Qiper desde{' '}
                      {`${formattedMonthCreateTimeUser?.slice(0, 3)}/${yearCreateTimeUser}`} -{' '}
                      <Link
                        to={
                          hasUserProfile
                            ? `/atleta/${eqipo?.user?.username}`
                            : '/entrar'
                        }
                      >
                        Veja meu perfil
                      </Link>
                    </small>
                  </div>
                </div>
                <p>{eqipo?.story}</p>
              </div>
              <div className="galleryContainerEqipo">
                <EqipoGallery photos={photos} title="Imagens do Equipo"/>
              </div>
              <div className="tagEqipo">
                <strong className="tagTitleEqipo">
                  Esse equipo serve para
                </strong>
                <div>
                  {eqipo?.sports.map(({ id, title }) => (
                    <span key={id}>{title}</span>
                  ))}
                </div>
              </div>
              <div className="specContainerEqipo">
                <strong className="titleEqipo">Especificações</strong>
                <div className="specEqipo" style={{ marginTop: '8px' }}>
                  <strong>Tamanho: </strong>
                  <small>{eqipo?.size?.label}</small>
                </div>
                <div className="specEqipo">
                  <strong>Fabricante: </strong>
                  <small>{eqipo?.brand?.name}</small>
                </div>
                <div className="specEqipo">
                  <strong>Nota Fiscal: </strong>
                  <small>{eqipo?.hasInvoice ? 'Sim' : 'Não'}</small>
                </div>
                <div className="specEqipo">
                  <strong>Conservação: </strong>
                  <small>{translateCondition(eqipo!.itemCondition)}</small>
                </div>
              </div>
              <div className="priceEqipo">
                {hasUserProfile
                  ? <strong>
                    {(eqipo!.negotiatedPrices?.[0]?.price || eqipo!.price).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </strong>
                  : <strong
                    className="eqipo-price-blur"
                    onClick={() => {
                      history.push('/entrar')
                    }}
                  >
                    R$ 00.00
                  </strong>
                }
                <small>no Pix ou em até 12x</small>
                <div/>
                <div className="containerButton">
                  {!hasUserProfile
                    ? (
                      <div className="columns is-mobile is-centered">
                        <Button
                          type="button"
                          onClick={() => {
                            history.push(
                              `/entrar?eqipo=${eqipo?.id}&slug=${slug}`
                            )
                          }
                          }
                          variant="primary"
                        >
                          VEM PARA A QIP
                        </Button>
                      </div>
                      )
                    : (
                      <>
                        {isPublic && !isOwner && (
                          <><Button
                            variant="primary"
                            type="button"
                            onClick={(e) => {
                              buyEqipo(e)
                            }
                            }
                          >
                            COMPRAR
                          </Button>

                            <Button
                              type="button"
                              variant="secondaryNoBorder"
                              isLoading={loadingButton}
                              onClick={sendMessage}
                            >
                  <span>
                    Falar com {eqipo?.user.displayName?.split(' ')[0]}
                  </span>
                            </Button>
                          </>)
                        }
                        {isPublic && isOwner && canEdit && (
                          <Button
                            type="button"
                            onClick={editListing}
                          >
                            Editar meu anúncio
                          </Button>
                        )}
                        {isOwner && isDraft && (
                          <Button
                            type="button"
                            variant="blue"
                            onClick={sendToReview}
                          >
                            <span>Enviar para curadoria</span>
                          </Button>
                        )}
                        {isAdmin && isWatingReview && (
                          <div className="adminZone">
                            <p className="adminZoneLabel">Zona Admin</p>
                            <Button onClick={approveListing}>
                              Aprovar Eqipo
                            </Button>
                            <Button variant="secondaryNoBorder" onClick={editListing}>
                              Editar Eqipo
                            </Button>
                            <Button variant="secondaryNoBorder" onClick={sendMessage}>
                              Falar com {eqipo?.user.displayName?.split(' ')[0]}
                            </Button>
                          </div>
                        )}
                      </>
                      )}
                </div>
              </div>
            </div>
          </> }
      </div>
    </ListingsProvider>
  )
}

export default Eqipo
