import React, { useContext } from 'react'

import './MyBalance.scss'
import Cta from '../../components/Cta/Cta'
import { GlobalContext } from '../../context/GlobalContext'

export const MyBalanceWithdrawSuccess: React.FC<any> = ({ setCurrentStep }) => {
  const { userProfile } = useContext(GlobalContext)

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }}
    >
      <Cta
        title="Saldo resgatado!"
        content="O valor foi transferido e deverá entrar na sua conta em até 24 horas."
        isButton
        buttonProps={{
          title: 'Voltar para meu perfil',
          route: `/atleta/${userProfile?.displayName}`
        }}
      />
    </div>
  )
}
