import React from 'react'
import Select from 'react-select'

export interface Option {
  value: string
  label: string
}

type Value<IsMulti extends boolean> = IsMulti extends true
  ? readonly Option[]
  : Option

type OnChangeType<IsClearable extends boolean, IsMulti extends boolean> = IsClearable extends true
  ? (value: Value<IsMulti> | null) => any
  : (value: Value<IsMulti>) => any

interface SelectFieldProps<IsClearable extends boolean, IsMulti extends boolean> {
  value?: Value<IsMulti>
  isMulti?: IsMulti
  options: Option[]
  onChange: OnChangeType<IsClearable, IsMulti>
  placeholder?: string
  description?: string
  id?: string
  isClearable?: IsClearable
}

const SelectField = function SelectField<IsClearable extends boolean = false, IsMulti extends boolean = false>
(props: SelectFieldProps<IsClearable, IsMulti>) {
  return (
    <>
      <Select
        inputId={props.id}
        isMulti={props.isMulti}
        value={props.value}
        onChange={(selectedOptions) => {
          if (!selectedOptions && !props.isClearable) {
            return
          }
          props.onChange(selectedOptions as any)
        }}
        placeholder={props.placeholder}
        options={props.options}
        isClearable={props.isClearable}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: '#C6C6C6',
            fontSize: '16px'
          })
        }}
      />
      {props.description && <span className="textarea__caption">{props.description}</span>}
    </>
  )
}

export default SelectField
