import styled, { css, keyframes } from 'styled-components'
import theme from '../../global/theme'
import React from 'react'

const spinAround = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const LoadingSpinner = styled.div<{ variant: Variant }>`
  animation: ${spinAround} 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;

  ${({ variant }) =>
    variant === 'secondaryNoBorder' &&
    css`
      border-color: ${theme.colors.qipCoralLight} ${theme.colors.qipCoralLight} transparent transparent;
    `}
`

export type Variant = 'primary' | 'secondary' | 'blue' | 'blueSecondary' | 'blueNoBorder' | 'secondaryNoBorder'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant
  background?: string
  color?: string
  borderColor?: string
  borderRadius?: string
  borderWidth?: string
  width?: string
  height?: string
  margin?: string
  padding?: string
  isLoading?: boolean
}

const StravaButton = styled.button<IButton>`
  border: 1px solid #7d829b;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.uiWhite};
  color: #fc4c02;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  > img {
    margin-left: 6px;
    width: 55.38px;
    height: 12px;
    margin-bottom: 2px;
  }
`

export const ButtonStrava: React.FC<IButton> = ({ isLoading, onClick, children, ...rest }) => (
  <StravaButton type="button" onClick={onClick} {...rest}>
    {isLoading ? <LoadingSpinner variant="primary" /> : children}
  </StravaButton>
)
