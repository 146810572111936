import React from 'react'
import Cta from '../../../components/Cta/Cta'
import { UserShare } from '../../../components/UserShare/UserShare'
import { useHistory, useLocation } from 'react-router-dom'
import shortUUID from 'short-uuid'

const ListingEmpty = () => {
  const location = useLocation()
  const history = useHistory()

  const handleClick = () => {
    const searchParams = new URLSearchParams(location.search)
    const shortUuid = shortUUID.generate()

    searchParams.set('showSearch', 'true')
    searchParams.set('salt', shortUuid)

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    })
  }

  return <div style={{ padding: '40px 20px 120px 20px' }}>
    <Cta
      title="Não foi dessa vez..."
      content={
        <>Puxa, por enquanto não tem nenhum equipo <br/>
          como o que você procura.<br/>
          <button style={{ background: 'transparent', textDecoration: 'underline', fontWeight: 'bold', marginTop: 20 }}
                  onClick={async () => {
                    await UserShare('Compre e Venda equipos na Qip!',
                      'Sabe aquele seu equipo esportivo que pode viver novas histórias? Traz para Qip!',
                      'https://qip.com.br')
                  }}
                  type="button">
            Convide mais pessoas para vender na Qip
          </button>
        </>
      }
      isButton
      buttonProps={{
        title: 'Alterar filtros',
        variant: 'blue',
        click: handleClick
      }}
    />
  </div>
}

export default ListingEmpty
