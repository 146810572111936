import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { type SportItem } from '../../services/sportService'
import Notification from '../../components/Notification/Notification'
import Navbar from '../../components/Navbar/Navbar'
import './Auth.scss'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import FormPagination from '../../components/FormPagination/FormPagination'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import FormField from '../../components/FormComponents/FormField'
import { type Option } from '../../components/FormComponents/SelectField'
import authService from '../../services/authService'
import { type Club } from '../../models/Clubs'
import { GlobalContext } from '../../context/GlobalContext'
import { useSignUp } from '../../context/SignUpContext'
import { SelectSports } from '../../components/SelectSports/SelectSports'
import SelectClubs from '../../components/SelectClubs/SelectClubs'
import { SelectStateCity } from '../../components/SelectStateCity/SelectStateCity'
import * as amplitude from '@amplitude/analytics-browser'
import { Identify } from '@amplitude/analytics-browser'

interface AccountDataState {
  values: any
  errors: any
  errorMessage: string
  formValid?: boolean
  stateList: Option[]
  selectedSports: SportItem[]
  selectedClubs: Club[]
  selectedState?: Option
  selectedCity?: Option
  isLoading: boolean
}

const schema = yup.object(
  {
    selectedSports: yup.array().min(1, 'Campo obrigatório'),
    selectedState: yup.object().required('Campo obrigatório'),
    selectedCity: yup.object().required('Campo obrigatório')
  })

const AccountData = () => {
  const history = useHistory()

  const { setUserProfile } = useContext(GlobalContext)
  const { signUp } = useSignUp()

  const [state, setState] = useState<AccountDataState>(
    {
      values: {
        sports: [],
        club: []
      },
      errors: {
        sports: null,
        club: null
      },
      errorMessage: '',
      selectedSports: [],
      selectedClubs: [],
      selectedState: undefined,
      selectedCity: undefined,
      stateList: [],
      isLoading: false
    })

  const handleStateChange = (selectedOption: Option) => {
    setState(prevState => ({
      ...prevState,
      selectedState: selectedOption,
      selectedCity: undefined,
      errors: { ...prevState.errors, selectedState: undefined }
    }))
  }

  const handleCityChange = (selectedOption: Option) => {
    setState(prevState => ({
      ...prevState,
      selectedCity: selectedOption,
      errors: { ...prevState.errors, selectedCity: undefined }
    }))
  }

  const handleCreateUser = async (
    displayName: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    password: string,
    sports: SportItem[],
    clubs: Club[],
    state_: string,
    city: string,
    stravaId: string | undefined,
    photoURL: string | undefined) => {
    if (stravaId) {
      const userProfile = await authService.registerUserStrava({
        displayName,
        firstName,
        lastName,
        email,
        phoneNumber,
        photoURL,
        sports,
        clubs,
        state: state_,
        city,
        stravaId
      })

      setUserProfile(userProfile)

      await authService.signInWithCustomToken(userProfile.token)
    } else {
      const userProfile = await authService.registerUser(
        {
          displayName,
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          sports,
          clubs,
          state: state_,
          city
        })

      setUserProfile(userProfile)

      await authService.signInEmailPassword(
        {
          email,
          password
        })
    }

    history.push('/')
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()

      setState(prevState => ({
        ...prevState,
        isLoading: true
      }))

      await schema.validate(state, { abortEarly: false })
      const { displayName, email, phoneNumber, password, stravaId, photoURL } = signUp

      const names = displayName.split(' ')
      const firstName = names[0]
      const lastName = names[names.length - 1]
      const phoneNumber_ = `+55${phoneNumber.replace(/\D/g, '')}`
      const sports = state.selectedSports
      const state_ = state.selectedState!.value
      const city = state.selectedCity!.value
      const clubs = state.selectedClubs

      const identify = new Identify()
      identify.set('email', email)
      amplitude.identify(identify)

      await handleCreateUser(displayName, firstName, lastName, email, phoneNumber_, password, sports, clubs, state_, city, stravaId, photoURL)
    } catch (error: any) {
      if (error.inner) {
        const errorMessage = error.inner.reduce((obj: any, item: any) => {
          obj[item.path] = item.message
          return obj
        }, {})

        setState(prevState => ({
          ...prevState,
          errors: { ...errorMessage },
          isLoading: false
        }))
      } else {
        const { isWarn, message } = error
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        setState(prevState => ({ ...prevState, isLoading: false }))
      }
    }
  }

  return (
    <div className="signup-form">
      <Navbar noButtons/>
      <form
        onSubmit={handleSubmit}
        className="signup-form__content"
        noValidate
      >
        <FormPagination
          steps={[{ id: 1, label: 'DADOS PESSOAIS' }, { id: 2, label: 'ESPORTE' }]}
          activateStep={2}
          setCurrentStep={() => {
            history.goBack()
          }}
        />
        <section style={{ marginTop: '53px', paddingBottom: '14px' }}>
          <Notification
            className="is-danger"
            notificationBody={state.errorMessage}
          />

          <FormField
            name="sports"
            label="QUAIS ESPORTES VOCÊ PRATICA?"
            validation={state.errors.selectedSports}
            required
          >
            <SelectSports
              value={state.selectedSports}
              onChange={(selectedOptions) => {
                setState(prevState => ({
                  ...prevState,
                  selectedSports: [...selectedOptions],
                  errors: { ...prevState.errors, selectedSports: undefined }
                }))
              }}
              placeholder="Selecionar"
              isDisabled={state.isLoading}
            />
          </FormField>
          <SelectStateCity
            currentCityOption={state.selectedCity}
            currentStateOption={state.selectedState}
            handleCityChange={handleCityChange}
            handleStateChange={handleStateChange}
            statesLabel="EM QUAL ESTADO VOCÊ MORA?"
            citiesLabel="QUAL É A SUA CIDADE?"
            stateValidation={state.errors.selectedState}
            cityValidation={state.errors.selectedCity}
            isDisabled={state.isLoading}
          />
          <FormField
            name="club"
            label="FAZ PARTE DE UMA ASSESSORIA OU CLUBE?"
          >
            <div className="control">
              <SelectClubs
                isLoading={state.isLoading}
                isDisabled={state.isLoading || !state.selectedCity}
                value={state.selectedClubs}
                state={state.selectedState?.value}
                city={state.selectedCity?.value}
                onChange={(selectedClubs: any) => {
                  setState(prevState => ({
                    ...prevState,
                    selectedClubs
                  }))
                }}
              />
            </div>
          </FormField>
        </section>
        <div className="signup-form__submit">
          <div className="control">
            <Button
              variant="primary"
              type="submit"
              isLoading={state.isLoading}
            >
              CONTINUAR
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AccountData
