import React from 'react'
import './Notification.scss'

export interface NotificationProps {
  className?: string
  notificationBody: string
}

const Notification: React.FC<NotificationProps> = (props: NotificationProps) => (
  <div className={props.notificationBody ? `notification ${props.className}` : ''}>{props.notificationBody}</div>
)

export default Notification
