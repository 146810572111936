import { AboutUsContainer, AboutUsContainerTop } from '../styles'
import React from 'react'

const ListingAboutUs = () => <>
  <AboutUsContainerTop />
  <AboutUsContainer>
    <h2>O que é a Qip?</h2>
    <h3>SEU LUGAR PARA COMPRAR E VENDER EQUIPOS PRÉ-AMADOS</h3>
    <p id="p1">
      A Qip é um site onde qualquer pessoa compra e vende equipamentos esportivos (<em>equipos</em>)
      pré-amados, com transparência e segurança.
      <br />
      <br /><strong>Não existe um estoque na Qip,</strong>
      todos os <em>equipos</em> são produtos de alguma pessoa que ama o esporte, e você tem a
      <strong> liberdade e confiança de conversar diretamente</strong> com ela para
      fazer perguntas, tomar decisão, negociar a compra, e combinar a entrega.<br />
    </p>
    <h3>O esporte é maior do que
      <br />qualquer equipamento</h3>
    <p id="p2">
      Aqui na <strong>Qip</strong> também somos pessoas que praticam e amam o esporte acima de qualquer <em>equipo</em>.
      Acreditamos que causamos impacto positivo na sociedade criando acesso simples à <em>equipos</em> em boas condições
      de uso e de compra.
      <br />
      <br />
      Acreditamos também que uma comunidade forte só se sustenta se <strong> feita por pessoas de confiança, </strong>
      por isso abrimos espaço para encontros via chat  da Qip entre quem compra e vende,
      e criamos funcionalidades de segurança para que as transações comecem e terminem dentro da Qip.
      <br />
    </p>
  </AboutUsContainer>
</>

export default ListingAboutUs
