import { $http } from '../../httpService'

interface HolderProps {
  taxDocument?: string
  fullname?: string
}

export interface BankAccount {
  bankNumber?: string
  agencyNumber?: string
  agencyCheckNumber?: string
  accountNumber?: string
  accountCheckNumber?: string
  type?: string
  holder?: HolderProps
}

export async function create (bankaccount: BankAccount) {
  const response = await $http.post('/v1/payments/bank-accounts', bankaccount)
  return response.data
}

export async function get () {
  const response = await $http.get('/v1/payments/bank-accounts')
  return response.data
}
