import React, { useContext, useEffect, useState } from 'react'
import Select, { type ActionMeta, components, type MultiValue } from 'react-select'
import { type SportItem } from '../../services/sportService' // Adjust the import path
import ArrowMenuOrange from '../../assets/images/arrow-menu-orange.svg'
import { GlobalContext } from '../../context/GlobalContext'

export interface GroupedOption {
  label: string
  options: SportItem[]
}

interface SelectSportsProps {
  value?: SportItem[]
  onChange: (newValue: MultiValue<SportItem>, actionMeta: ActionMeta<SportItem>) => void
  placeholder: string
  isDisabled?: boolean
}

export const SelectSports: React.FC<SelectSportsProps> = ({ value, onChange, placeholder, isDisabled = false }) => {
  const [options, setOptions] = useState<GroupedOption[]>([])
  const { sports } = useContext(GlobalContext)

  useEffect(() => {
    setOptions(sportsListByCategory(sports))
  }, [sports])

  const sportsListByCategory = (sportsList: SportItem[]): GroupedOption[] => {
    const categories: Record<string, GroupedOption> = {}

    sportsList.forEach(sport => {
      if (sport.parentId === null) {
        categories[sport.id] = { label: sport.title, options: [] }
      }
    })

    sportsList.forEach(sport => {
      if (sport.parentId !== null) {
        const parentCategory = categories[sport.parentId!]
        if (parentCategory) {
          parentCategory.options.push(sport)
        }
      }
    })

    return Object.values(categories)
  }

  return (
        <Select
            isMulti
            isClearable={false}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            getOptionLabel={(option) => `${option.title}`}
            getOptionValue={(option) => `${option.id}`}
            formatGroupLabel={(group) => group.label}
            isDisabled={isDisabled}
            components={{
              Option: (props: any) => (
                    <components.Option {...props}>
                        <img src={ArrowMenuOrange} alt="Seta laranja" style={{ marginRight: '8px' }}/>
                        {props.children}
                    </components.Option>
              )
            }}
            styles={{
              option: (base) => ({
                ...base,
                color: '#050d3d'
              }),
              groupHeading: (base) => ({
                ...base,
                color: '#050d3d',
                fontStyle: 'italic',
                fontWeight: 'bold'
              }),
              placeholder: (base) => ({
                ...base,
                color: '#C6C6C6',
                fontSize: '16px'
              })
            }}
        />
  )
}
