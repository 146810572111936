import { $http } from '../httpService'
import remote from './remote'

interface OrderParams {
  id: string
  summary?: string
  trackingNumber?: string
  status?: string
  escrowId?: string
}

async function getOrder (id: string) {
  return await remote.get({ id })
}

async function confirmTrackingNumber ({ id, trackingNumber, status }: OrderParams) {
  return await remote.put({ id, trackingNumber, summary: '', status })
}

async function updateOrder ({ id, trackingNumber, summary, status }: OrderParams) {
  return await remote.put({ id, trackingNumber, summary, status })
}

async function getEscrowId (id: string) {
  const response = await $http.get(`/v1/orders/${id}`)
  return response.data.escrowId
}

async function releaseEscrow (escrowId: string) {
  const response = await $http.post(`/v1/payments/escrow/${escrowId}`)
  return response.data
}

export default {
  getOrder,
  confirmTrackingNumber,
  updateOrder,
  getEscrowId,
  releaseEscrow
}
