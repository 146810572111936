import styled from 'styled-components'

export const PriceContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  > h5 {
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.qipBlueLight};
    height: 28px;
    border-bottom: 1px solid #80A1F5;
  }
  >small {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7D829B;
  }

`

export const DetailsContainer = styled.div`
> p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
> div {
  display: flex;
  align-items: center;
  >input {
    height: 40px;
    width: 113px;
    border: 1px solid ${({ theme }) => theme.colors.uiGreyLight};
    padding: 0 8px;
    color: #7D829B;
    ::placeholder {
      color: #7D829B;
    }
  }
}
`
