import React from 'react'
import Cta from '../../components/Cta/Cta'
import './MyBalance.scss'

const MyBalanceEmpty: React.FC = () => {
  return (
    <Cta
      notSpace
      title="Você ainda não vendeu nenhum equipo"
      content={
        <>
        Quando uma venda acontece, e assim que for confirmada a entrega do equipo,
        seu dinheiro aparecerá no extrato e você poderá consultar e resgatar seu
        saldo aqui{' '}
        </>
      }
      isButton
      buttonProps={{ title: 'Vender Equipo', route: '/eqipo/novo' }}
    />
  )
}

export default MyBalanceEmpty
