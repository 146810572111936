import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../assets/images/logo.svg'
import emptyLoginIcon from '../../assets/images/empty-login.svg'
import './Navbar.scss'
import NavBarUserMenu from './NavBarUserMenu'
import { GlobalContext } from '../../context/GlobalContext'
import Footer from '../Footer/Footer'
import { type SportItem } from '../../services/sportService'
import FilterBar from '../Filter/Filter'
import ArrowMenuOrange from '../../assets/images/arrow-menu-orange.svg'

interface SportParent {
  id: string
  title: string
  description: string
  parentId?: string
  parents: SportItem[]
}

interface SportsProps {
  sports?: SportItem[]
  onSportClick?: () => void
}

const SportsMenu = (props: SportsProps) => {
  const { sports } = props

  const categories = sports?.filter((filterCategory) => {
    return !filterCategory.parentId
  }).map((sportCategory) => {
    const subCategories = sports.filter((filterSubCategory) => {
      return filterSubCategory.parentId === sportCategory.id
    })
    return {
      ...sportCategory,
      parents: [...subCategories]
    }
  })

  const catItems = categories?.map((category: SportParent) => {
    return (
      <li key={category.id} onClick={props.onSportClick}>
        {category.parents.length === 0
          ? (
            <Link to={`/eqipos?sports=${category.id}`} className="navbar-item-without-parents">
              <img src={ArrowMenuOrange} alt="Seta laranja"/>
              {category.title}
            </Link>
            )
          : (
            <Link to={`/eqipos?sports=${category.id}`} className="navbar-item">
              {category.title}
            </Link>
            )}

        <ul>
          {category.parents.map((subCategory: SportItem) => (
            <li key={subCategory.id} onClick={props.onSportClick}>
              <Link to={`/eqipos?sports=${subCategory.id}`} className="navbar-subitem">
                <img src={ArrowMenuOrange} alt="Seta laranja"/>
                {subCategory.title}
              </Link>
            </li>
          ))}
        </ul>
      </li>
    )
  })

  return <ul className="sport-list">{catItems}</ul>
}

interface INavbar {
  noButtons?: boolean
}

const Navbar = ({ noButtons = false }: INavbar) => {
  const history = useHistory()
  const [showMenu, toggleMenu] = useState(false)
  const [showSearch, toggleSearch] = useState(false)
  const location = useLocation()

  const { sports, userProfile } = useContext(GlobalContext)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('showSearch') === 'true') {
      toggleMenu(true)
      toggleSearch(true)
    }
  }, [location.search])

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div
          className="menu"
          style={
            noButtons
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }
              : {}
          }
        >
          {!noButtons && (
            <div className="menu-btn">
              <button
                className={
                  showMenu ? 'navbar-burger is-active' : 'navbar-burger'
                }
                aria-label="menu"
                aria-expanded="false"
                data-target="sidebar"
                onClick={() => {
                  toggleMenu(!showMenu)
                  toggleSearch(false)
                }}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </button>
              <FontAwesomeIcon
                icon={faSearch}
                onClick={() => {
                  toggleMenu(!showMenu)
                  toggleSearch(true)
                }}
              />
            </div>
          )}
          <div className="menu__left logo">
            <button onClick={() => {
              history.push('/')
            }}>
              <img src={logo} alt="Logo"/>
            </button>
          </div>
          {!noButtons && (
            <div className="menu__right">
              {userProfile
                ? (
                  <Link to="/eqipo/novo" className="navbar-item">
                    VENDER
                  </Link>
                  )
                : (
                  <Link to="/entrar/" className="navbar-signin">
                    <img src={emptyLoginIcon} alt="Entrar"/>
                  </Link>
                  )}
              {userProfile && <NavBarUserMenu/>}
            </div>
          )}
        </div>
      </div>
      <div
        className={showMenu ? 'menu-overlay is-active' : 'menu-overlay'}
        onClick={() => {
          toggleMenu(!showMenu)
        }}
      ></div>
      <div
        id="sidebar"
        className={showMenu ? 'sidebar-menu is-active' : 'sidebar-menu'}
      >
        <div className="navbar-brand">
          <div className="menu">
            <div className="menu-btn"></div>
            <div className="menu__left logo">
              <Link to="/">
                <img src={logo} alt="Logo"/>
              </Link>
            </div>
            <div className="menu__right">
              <button
                className={
                  showMenu ? 'navbar-burger is-active' : 'navbar-burger'
                }
                aria-label="menu"
                aria-expanded="false"
                data-target="sidebar"
                onClick={() => {
                  toggleMenu(!showMenu)
                }}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
        <div className="navbar-end">
          {!showSearch
            ? <>
              <ul className="sidebar-items">
                <SportsMenu
                  sports={sports}
                  onSportClick={() => {
                    toggleMenu(!showMenu)
                  }}
                />
              </ul>
              <Footer/>
            </>
            : <FilterBar
              isOpened={showMenu}
              toggleMenu={() => {
                toggleMenu(!showMenu)
              }}/>}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
