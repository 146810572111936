import React from 'react'

import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage/ToastMessage'

export const UserShare = async (title: string, text: string, url: string) => {
  const navigator = window.navigator
  if (navigator.share) {
    try {
      await navigator.share({
        title,
        text,
        url
      })
    } catch (error) {
      toast.error(<ToastMessage/>)
    }
  } else {
    toast.error(<ToastMessage/>)
  }
}
