import { $http } from '../../httpService'
import { type Sport } from '../../../models/Sports'
import { type Club } from '../../../models/Clubs'

interface RegisterParam {
  displayName: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  sports: Sport[]
  clubs: Club[]
  state: string
  city: string
}

interface RegisterUserStravaParam {
  displayName: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  stravaId: string
  photoURL?: string
  sports: Sport[]
  clubs: Club[]
  state: string
  city: string
}

async function forgetPassword (email: string) {
  const response = await $http.post('/v1/forgot-password', { email })
  return response.data
}

async function register ({
  displayName,
  firstName,
  lastName,
  email,
  password,
  phoneNumber,
  sports,
  clubs,
  state,
  city
}: RegisterParam) {
  const response = await $http.post('/v1/user', {
    displayName,
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    sports,
    clubs,
    address: {
      currentCity: city,
      currentState: state
    }
  })

  return response.data
}

async function registerUserStrava ({
  displayName,
  firstName,
  lastName,
  email,
  phoneNumber,
  photoURL,
  sports,
  clubs,
  state,
  city,
  stravaId
}: RegisterUserStravaParam) {
  const response = await $http.post('/v1/user/strava', {
    displayName,
    firstName,
    lastName,
    photoURL,
    email,
    phoneNumber,
    sports,
    clubs,
    address: {
      currentCity: city,
      currentState: state
    },
    stravaId
  })

  return response.data
}

interface EditParams {
  displayName: string
  email: string
  uid: string
  phoneNumber: string
}

async function edit ({
  displayName,
  email,
  uid,
  phoneNumber
}: EditParams) {
  const response = await $http.patch('/v1/user', {
    displayName,
    email,
    uid,
    phoneNumber
  })

  return response.data
}

async function registerCustom (provider: string): Promise<{ redirectUrl: string }> {
  const response = await $http.get(
      `/v1/user/${provider}`
  )

  return response.data
}

async function getInfoStrava (code: string | null): Promise<any> {
  const response = await $http.get(
      `/v1/user/strava/${code}`
  )

  return response.data
}

async function validateCustomCode (provider: string, query: any): Promise<{ token: string }> {
  const response = await $http.post(
      `/v1/user/${provider}${query}`
  )

  return response.data
}

async function verifyEmail (email: string): Promise<{ alreadyExists: boolean }> {
  const response = await $http.get(
      `/v1/users/${email}/validate`
  )
  return response.data
}

export default {
  register,
  registerCustom,
  forgetPassword,
  validateCustomCode,
  edit,
  verifyEmail,
  getInfoStrava,
  registerUserStrava
}
