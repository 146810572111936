import React from 'react'
import './RoomResultCard.scss'
import emptyProfileAvatar from '../../../assets/images/empty-profile.svg'
import ArrowMenuOrange from '../../../assets/images/arrow-menu-orange.svg'

interface RoomUserCardProps {
  displayName: string
  photoURL: string
}

export interface RoomResultCardProps {
  title?: string
  cover?: string
  countMessageNotRead?: number
  countMessageRead?: number
  seller?: RoomUserCardProps
  handleRedirectMessage?: () => void
  userSeller?: boolean
  itemStatus?: string
  orderStatus?: any
}

const RoomResultCard: React.FC<RoomResultCardProps> = (
  props: RoomResultCardProps
) => (
  <div id="message" onClick={props.handleRedirectMessage}>
    <div className="main">
      <div className="product-image">
        <img src={props.cover} alt={props.title}/>
        <div className="profile-mini-image">
          <img
            src={props.seller?.photoURL || emptyProfileAvatar}
            alt=""
            onError={(e) => {
              (e.target as HTMLImageElement).onerror = null;
              (e.target as HTMLImageElement).src = emptyProfileAvatar
            }}
          />
        </div>
      </div>
      <div className="details">
        {(props.orderStatus === 'new' || props.orderStatus === 'authorization') &&
          (props.userSeller
            ? <span className="chat-tag tag-sold">VENDIDO</span>
            : <span className="chat-tag tag-bought">COMPREI</span>)}

        {props.orderStatus === 'shipped' &&
          (props.userSeller
            ? <span className="chat-tag">ENTREGUEI</span>
            : <span className="chat-tag tag-shopping">COMPREI</span>)}

        {props.orderStatus === 'received' &&
          (props.userSeller
            ? <span className="chat-tag tag-sold">VENDIDO</span>
            : <span className="chat-tag tag-bought">COMPREI</span>)}

        {(props.orderStatus === 'canceled' || props.orderStatus === 'returned' || props.orderStatus === 'returnReceived') &&
          <span className="chat-tag">CANCELADO</span>}

        {props.orderStatus === 'disputed' &&
          <span className="chat-tag">CONTESTADO</span>}

        {/* TODO: item indisponivel */}

        <p>
          {props.title?.length && props.title?.length > 22
            ? `${props.title?.substring(0, 22)}...`
            : props.title}
        </p>

        <div className="seller">
          <div className="info">
            <span>{props.seller?.displayName}</span>

            {(props.orderStatus === 'new') &&
              (props.userSeller
                ? <label className="new-info">PAGAMENTO PENDENTE</label>
                : <label className="new-info">PAGAMENTO PENDENTE</label>)}

            {(props.orderStatus === 'authorization') &&
              (props.userSeller
                ? <label className="new-info">MARCAR COMO ENTREGUE</label>
                : <label className="new-info">AGUARDANDO A ENTREGA</label>)}

            {props.orderStatus === 'shipped' &&
              (props.userSeller
                ? <label className="new-info">AGUARDANDO RECEBIMENTO</label>
                : <label className="new-info">MARCAR COMO RECEBIDO</label>)}

            {Boolean((!props.orderStatus || props.orderStatus === 'received') &&
                props.countMessageNotRead && props?.countMessageNotRead > 0) &&
              <label className="new-info">
                {props.countMessageNotRead && props.countMessageNotRead > 1
                  ? `${props.countMessageNotRead} MENSAGENS NOVAS`
                  : `${props.countMessageNotRead} MENSAGEM NOVA`}
              </label>}
          </div>
        </div>
      </div>
      <div className="arrow">
        <img src={ArrowMenuOrange} alt="Ir para mensagens"/>
      </div>
    </div>
  </div>
)

export default RoomResultCard
