import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import userService from '../../services/userService/index'
import Navbar from '../../components/Navbar/Navbar'
import FormField from '../../components/FormComponents/FormField'
import TextField from '../../components/FormComponents/TextField'
import CleaveField from '../../components/FormComponents/CleaveField'
import './Auth.scss'
import FormPagination from '../../components/FormPagination/FormPagination'
import * as yup from 'yup'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { ContainerSignUp } from './styles'
import { useSignUp } from '../../context/SignUpContext'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { toast } from 'react-toastify'
import { strictEmailRegex, strictPhoneNumberRegex } from '../../helpers/regex'
import { atLeastTwoWords } from '../../helpers/yupValidation'

const schema = yup.object({
  displayName: yup.string().required('Campo obrigatório').test('Nome inválido', 'Nome deve conter pelo menos duas palavras', atLeastTwoWords),
  email: yup.string().matches(strictEmailRegex, 'E-mail inválido').required('Campo obrigatório'),
  phoneNumber: yup.string().matches(strictPhoneNumberRegex, 'Número de telefone inválido').required('Campo obrigatório'),
  password: yup.string().min(6, 'A senha deve ter no mínimo 6 digitos').required('Campo obrigatório'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), undefined], 'As senhas não conferem').required('Campo obrigatório')
})

const SignUp = () => {
  const location = useLocation()
  const history = useHistory()

  const { signUp, updateSignUp } = useSignUp()
  const [errors, setErrors] = useState<any>(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!signUp.email) {
      const email = new URLSearchParams(location.search).get('email')
      if (email) updateSignUp({ email: decodeURIComponent(email.trim().replace(/-dot-/g, '.')) })
    }
  }, [])

  const handleChange = (name: string, value: string) => {
    updateSignUp({ [name]: value })
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      setErrors(null)
      await schema.validate(signUp, { abortEarly: false })
      const { alreadyExists } = await userService.validEmail(signUp.email.toLowerCase())
      if (!alreadyExists) {
        history.push('/cadastrar/meus-dados')
      } else {
        toast.warn(<ToastMessage label="E-mail ja cadastrado" />)
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessage = error.inner.reduce((obj: any, item: any) => {
          obj[item.path] = item.message
          return obj
        }, {})
        setErrors({ ...errorMessage })
      } else {
        const { isWarn, message } = error
        isWarn ? toast.warn(<ToastMessage label={ message } />) : toast.error(<ToastMessage/>)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="signup-form">
      <Navbar noButtons />
      <form className="signup-form__content" onSubmit={ handleSubmit } noValidate>
        <ContainerSignUp>
          <FormPagination
            steps={ [{ id: 1, label: 'DADOS PESSOAIS' }, { id: 2, label: 'ESPORTE' }] }
            activateStep={ currentStep }
            setCurrentStep={ ({ id }) => { setCurrentStep(id) } }
          />
          <section>
            <FormField
              name="displayName"
              label="Nome e Sobrenome"
              validation={ errors?.displayName }
              required
            >
              <TextField
                name="displayName"
                type="text"
                value={ signUp.displayName }
                onChange={ handleChange }
                disabled={isLoading}
                placeholder="Digite seu nome e sobrenome"
              />
            </FormField>
            <FormField
              name="email"
              label="E-mail"
              validation={ errors?.email }
              required
            >
              <TextField
                name="email"
                type="text"
                autoComplete="email"
                value={ signUp.email.trim() }
                disabled={isLoading}
                onChange={ async (name: string, value: string) => {
                  handleChange(name, value.trim())
                } }
              />
            </FormField>
            <FormField
              name="phoneNumber"
              label="Telefone"
              validation={ errors?.phoneNumber }
              required
            >
              <CleaveField
                name="phoneNumber"
                type="tel"
                inputMode="numeric"
                maxLength={ 14 }
                disabled={isLoading}
                options={ {
                  delimiters: ['(', ')', '-'],
                  blocks: [0, 2, 5, 4],
                  numericOnly: true
                } }
                value={ signUp.phoneNumber }
                placeholder="(XX)XXXX-XXXX"
                onChange={ handleChange }
              />
            </FormField>
            <>
              <FormField
                name="password"
                label="Senha"
                validation={ errors?.password }
                required
              >
                <TextField
                  name="password"
                  type="password"
                  value={ signUp.password }
                  onChange={ handleChange }
                  disabled={isLoading}
                  autoComplete="new-password"
                  placeholder="Digite uma senha"
                />
              </FormField>
              <FormField
                name="passwordConfirmation"
                label="Confirme sua senha"
                validation={ errors?.passwordConfirmation }
                required
              >
                <TextField
                  name="passwordConfirmation"
                  type="password"
                  value={ signUp.passwordConfirmation }
                  onChange={ handleChange }
                  disabled={isLoading}
                  autoComplete="new-password"
                  placeholder="Confirme sua senha"
                />
              </FormField>
            </>
          </section>
        </ContainerSignUp>
        <div className="control has-text-centered">
          <Button
            variant="primary"
            type="submit"
            isLoading={isLoading}>
            Continuar
          </Button>
        </div>
      </form>
    </div>
  )
}

export default SignUp
