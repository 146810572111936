import React from 'react'

import ContainerButton from './ContainerButton'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import RadioField from '../../components/FormComponents/RadioField'
import './CheckoutConfirm.scss'
import { availablePaymentMethods } from './checkoutEnums'
import { type Listing } from '../../models/Listings'
import { type Service } from './CheckoutShipping'
import { type Address, type PaymentMethod } from '../../models/Checkout'

export interface CheckoutConfirmProps {
  checkoutInfo?: {
    payment?: PaymentMethod
    shipment?: Address
    shipmentService?: Service
  }
  eqipo?: Listing
  onSubmitOrder: (orderPayload: any) => void
  isLoading: boolean
}

const CheckoutConfirm = (props: CheckoutConfirmProps) => {
  const { payment, shipment, shipmentService } = props.checkoutInfo!

  if (!payment || !shipment || !shipmentService || !props.eqipo) {
    return (<div>Nem todas as informações foram preenchidas corretamente</div>)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const payload = {
      payment,
      listingId: props.eqipo!.id,
      comment: 'Confirming order',
      shipment: {
        method: shipmentService.method,
        cost: shipmentService.price,
        zipcode: shipment.zipcode,
        address: shipment.address,
        address2: `${shipment.number} ${shipment.address2}`,
        district: shipment.district,
        country: shipment.country,
        city: shipment.city,
        state: shipment.state
      }
    }
    props.onSubmitOrder(payload)
  }

  const eqipoCost = (props.eqipo.negotiatedPrices?.[0]?.price || props.eqipo.price)
  const totalCost = Number(payment.total! + parseFloat(shipmentService.price))
  // const shipmentPrice = Number(shipmentService.price) || 0

  return (
    <form className="checkoutConfirm" onSubmit={e => {
      handleSubmit(e)
    }}>
      <div className="checkoutConfirmTitle">Forma de entrega</div>
      <div className="checkoutConfirmShipment">
        <RadioField
          name="shipmentMethod"
          key={shipment.serviceCode}
          label={`A combinar com: ${props.eqipo?.user?.displayName}`}
          checked={true}
        />
        <p className="checkoutConfirmShipmentDetail">Vou combinar a entrega  com a pessoa vendedora.</p>
      </div>
      <div className="checkoutConfirmTitle">Forma de pagamento</div>
      {payment?.method === availablePaymentMethods.creditCard &&
        <div>
          <RadioField
            name="paymentMethod"
            key={payment.method}
            label="Cartão de crédito"
            checked={true}
          />
          <div className="credit-card-preview">
            <p className="credit-card-preview__holder">{payment.creditCard!.fullname}</p>
            <p>****-****-****-{payment.creditCard!.ccLastDigits}</p>
          </div>
        </div>
      }
      {payment?.method === availablePaymentMethods.boleto &&
        <RadioField
          name="paymentMethod"
          key={payment?.method}
          label="Boleto bancário"
          checked={true}
        />
      }
      {payment?.method === availablePaymentMethods.pix &&
        <RadioField
          name="paymentMethod"
          key={payment?.method}
          label="Pix"
          checked={true}
        />
      }
      <div className="brCheckoutConfirm"/>
      <div className="cost">
        <p>Valor do equipo</p>
        <p>
          {eqipoCost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </p>
      </div>
      {payment?.creditCard
        ? (
          <div className="cost cost__installments">
            <p>{payment.installments!} parcela{payment.installments ? 's' : ''}</p>
            <p>
              {Number(payment.installmentsValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </p>
          </div>
          )
        : null}
      <div className="emptyCost">
      </div>
      <div className="cost cost--summary">
        <p>Valor Final</p>
        <p>
          {totalCost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </p>
      </div>
      <ContainerButton>
        <Button
          margin="0px"
          type="submit"
          variant="primary"
          isLoading={props.isLoading}
        >
          Finalizar Pagamento
        </Button>
      </ContainerButton>
    </form>
  )
}

export default CheckoutConfirm
