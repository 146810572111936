import styled, { css } from 'styled-components'

export const Container = styled.div<any>`
  background: ${({ theme }) => theme.colors.uiWhite};
  height: 100vh;
  width: 100vw;
  padding: 15px 20px;

  > form {
    padding: 0 0 20px;
  }

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    `}
`

interface IEqipoContainer {
  typeEqipo: 'grid' | 'list'
}

export const EqipoContainer = styled.div<IEqipoContainer>`
  display: grid;
  padding: 20px;
  ${({ typeEqipo }) => typeEqipo === 'list'
? css`
  `
: css`
    grid-template-columns: 40% 40%;
    grid-gap: 10%;
  `}
`

export const Header = styled.div`
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  height: 52px;
  background: ${({ theme }) => theme.colors.uiWhite};
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  box-shadow: 0px 1px 0px #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const FilterContainer = styled.div`
  padding: 24px 30px 0;
  display: flex;
  justify-content: space-between;

  .selected {
    box-shadow: inset -1px 0px 0px #616161, inset 0px -1px 0px #616161, inset 1px 0px 0px #616161, inset 0px 1px 0px #616161;
  }

  > button {
    width: 77px;
    height: 25px;
    background: ${({ theme }) => theme.colors.uiWhite};
    border: 1px solid #7D829B;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-style: normal;
      font-size: 11px;
      line-height: 140%;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.qipBlue};
      padding-left: 10px;
    }

  }
`
export const FilterList = styled.div`
  display: grid;
  grid-template-columns: 67px calc(100% - 67px);
  margin: 10px 20px;

  > div {
    display: flex;
    flex-wrap: wrap;
  }

  > strong {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
