import React from 'react'

import './MyBalanceBankAccountUpdate.scss'
import ListingsProvider from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import Skeleton from 'react-loading-skeleton'

const MyBalanceBankAccountUpdateLoading: React.FC = () => {
  return <div>
    <ListingsProvider>
      <Navbar/>
    </ListingsProvider>
    <ReturnHeader title="DADOS BANCÁRIOS" border={true}/>
    <div className="container">
      <Skeleton width="100%" height={50} />
      <Skeleton width="50%" height={50} style={{ marginTop: '20px' }} />
    </div>
  </div>
}

export default MyBalanceBankAccountUpdateLoading
