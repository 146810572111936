import styled from 'styled-components'

export const Container = styled.section<any>`
  border-top: ${({ theme, borderColor }) => `1px solid ${borderColor || theme.colors.uiBlueFocus}`};
  border-bottom: ${({ theme, borderColor }) => `1px solid ${borderColor || theme.colors.uiBlueFocus}`};
  margin: ${({ notSpace }) => notSpace ? '20px 0' : '40px 0 120px'};
  padding: 16px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  button {
    width: auto;
    padding: 0 10px;
  }
  
  > h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme, textColor, titleColor }) => titleColor || (textColor || theme.colors.qipBlueLight)};
  }

  > a {
    margin-top: 8px;
    text-decoration-line: underline;
  }

  > div {
    padding: 16px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: ${({ theme, textColor }) => textColor || theme.colors.qipBlueLight};
    
    .secondary {
      color: ${({ theme }) => theme.colors.uiGrey};
      font-size: 14px;
    }
  }
`
