import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, type RouteProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import ListingsProvider, { initialFilter } from '../../../components/ListingsProvider/ListingsProvider'

import TextField from '../../../components/FormComponents/TextField'
import Navbar from '../../../components/Navbar/Navbar'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'
import { GlobalContext } from '../../../context/GlobalContext'
import messageService from '../../../services/messageService'
import ReturnHeader from '../../../components/ReturnHeader/ReturnHeader'
import './MessagesRoom.scss'
import moment from 'moment'
import { type UserProfile } from '../../../models/User'
import ArrowCircle from '../../../assets/images/arrow-circle.svg'
import { MessagesRoomLoading } from './MessagesRoomLoading'
import { MessagesRoomHeader } from './MessagesRoomHeader'
import { type Room } from '../../../models/Rooms'
import { type Message } from '../../../models/Messages'
import emptyProfileAvatar from '../../../assets/images/empty-profile.svg'
import Check from '../../../assets/images/check.svg'
import Checknot from '../../../assets/images/checknot.svg'

interface MessageRoomProps extends RouteProps {
  match: {
    params: {
      roomId: string
    }
  }
}

const MessagesRoom = (props: MessageRoomProps) => {
  const { userProfile } = useContext(GlobalContext)
  const history = useHistory()
  const [room, setRoom] = useState<Room | null>()
  const [messagesImmutable, setMessagesImmutable] = useState<any[]>([])
  const [messages, setMessages] = useState<any[]>([])
  const [message, setMessage] = useState<string>('')
  const [recipient, setRecipient] = useState<UserProfile>()
  const [recipientMessage, setRecipientMessage] = useState<string>('')
  const [recipientPhoto, setRecipientPhoto] = useState<string>('')
  const [price, setPrice] = useState(0)
  const [priceWithMask, setPriceWithMask] = useState('')
  const [originalPrice, setOriginalPrice] = useState(0)

  const refMessage = useRef<HTMLDivElement>(null)

  function groupByMessage (messagesData: any) {
    if (messagesData.length > 0) {
      const group = messagesData.reduce((groups: any, item: any) => ({
        ...groups,
        [moment(item.createdAt).format('DD/MM/YYYY')]:
            [...(groups[moment(item.createdAt).format('DD/MM/YYYY')] || []), item]
      }),
      {}
      )
      setMessages(group)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log('fetchData')
      try {
        const { roomId } = props.match.params
        const room = await messageService.getRoomById(roomId)
        setRoom(room)

        const price =
          room.order?.buyerId === userProfile?.id
            ? room.order.payment?.[0]?.amount / 100
            : (room?.listing?.negotiatedPrices?.[0]?.price || room?.listing?.price || 0)

        setPrice(price)
        setOriginalPrice(price)
        setPriceWithMask(price.toString())

        const recipient = room?.users?.filter(
          (user: any) => user.id !== userProfile?.id
        )[0]

        if (recipient) {
          setRecipient(recipient)
          setRecipientMessage(recipient.displayName)
          setRecipientPhoto(recipient.photoURL)
        }
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }

    void fetchData()
  }, [props.match.params])

  useEffect(() => {
    const fetchData = async () => {
      console.log('fetchData 2')

      try {
        const { roomId } = props.match.params

        const { messages: messagesData } = await messageService.getMessagesByRoomId(roomId)
        groupByMessage([...messagesData.reverse(), ...messagesImmutable])
        setMessagesImmutable(messagesData.reverse())
        setTimeout(() => {
          refMessage.current?.scrollTo({ top: refMessage?.current?.scrollHeight, left: 0, behavior: 'smooth' })
        }, 50)
        for (const { id, readAt } of messagesData) {
          if (!readAt) {
            await messageService.readMessage(roomId, id)
          }
        }
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }

      try {
        const { roomId } = props.match.params
        const room = await messageService.getRoomById(roomId)
        setRoom(room)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }

    const interval = setInterval(() => {
      fetchData().catch(err => {
        console.error('Error fetching data:', err)
      })
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [props.match.params])

  const fetchDataRequest = async () => {
    console.log('fetchDataRequest')

    try {
      const { roomId } = props.match.params
      const { messages: messagesData } = await messageService.getMessagesByRoomId(roomId)
      groupByMessage(messagesData.reverse())
      setMessagesImmutable(messagesData.reverse())
      setTimeout(() => {
        refMessage.current?.scrollTo({ top: refMessage?.current?.scrollHeight, left: 0, behavior: 'smooth' })
      }, 50)
      for (const { id, readAt } of messagesData) {
        if (!readAt) {
          await messageService.readMessage(roomId, id)
        }
      }
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage/>)
    }
  }

  useEffect(() => {
    console.log('fetchDataRequest 2')

    void fetchDataRequest()
  }, [props.match.params])

  const handleOnChange = (name: string, value: string) => {
    setMessage(value)
  }

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault()
    const { roomId } = props.match.params

    if (!message) {
      toast.warn(<ToastMessage label="Informe uma mensagem."/>)
      return
    }

    await messageService.sendMessage({
      roomId,
      text: message
    })
    void fetchDataRequest()
    setMessage('')
  }

  // const fetchMoreMessages = () => {
  //   const fetchData = async () => {
  //     console.log('fetchMoreMessages')
  //     try {
  //       const { roomId } = props.match.params
  //       const { messages: messagesData } = await messageService.getMessagesByRoomId(roomId)
  //       groupByMessage(messagesData.reverse())
  //       setMessagesImmutable(messagesData.reverse())
  //     } catch (error) {
  //       const { isWarn, message } = error as any
  //       isWarn
  //         ? toast.warn(<ToastMessage label={message}/>)
  //         : toast.error(<ToastMessage />)
  //     }
  //   }
  //
  //   if (messagesImmutable.length > 0) {
  //     fetchData()
  //   }
  // }

  const MessageTransactional: React.FC<{ message: Message }> = ({ message }) => {
    return <li className="message-transactional">
      <div>
        {message.text}
        {' - '}
        {message?.createdAt && moment(message.createdAt).format('HH:mm')}
      </div>
    </li>
  }

  const MessageRight: React.FC<{ message: Message }> = ({ message }) => {
    return <li className="message-right">
      <div>
        <p>{message.text}</p>
        <span>
          {message?.createdAt && moment(message.createdAt).format('HH:mm')}
          <img className="imgCheckMesssage" src={message.readAt ? Check : Checknot} alt="check"/>
        </span>
      </div>
    </li>
  }

  const MessageLeft: React.FC<{ message: Message }> = ({ message }) => {
    return <li className="message-left">
      <Link to={`/atleta/${recipient?.username}`}>
        <img
          src={recipientPhoto || emptyProfileAvatar}
          alt=""
          onError={(e) => {
            (e.target as HTMLImageElement).onerror = null;
            (e.target as HTMLImageElement).src = emptyProfileAvatar
          }}
        />
      </Link>
      <div>
        <p>{message.text}</p>
        <span>
          {message?.createdAt && moment(message.createdAt).format('HH:mm')}
        </span>
      </div>
    </li>
  }

  return (
    <div className="profile-edit">
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <div id="messages" style={{ position: 'relative' }}>
        <div>
          <ReturnHeader title={recipientMessage} border/>
        </div>
        {!room
          ? <MessagesRoomLoading/>
          : <MessagesRoomHeader room={room}
                                recipient={recipient}
                                price={price}
                                priceWithMask={priceWithMask}
                                originalPrice={originalPrice}
                                setPrice={setPrice}
                                setPriceWithMask={setPriceWithMask}
                                history={history}/>
        }
        <div className="list-messages" ref={refMessage}>
          {Object.keys(messages).map((messageData: any, index: number) => (
            <div key={index}>
              <div className="date-messages">
                <div>{messageData}</div>
              </div>
              <ul>
                {messages[messageData].map((message: Message, index: number) =>
                  message.transactional
                    ? <MessageTransactional message={message} key={index}/>
                    : userProfile?.id === message.userId
                      ? <MessageRight message={message} key={index}/>
                      : <MessageLeft message={message} key={index}/>)}
              </ul>
            </div>
          ))}
        </div>
        {(room?.order?.orderStatus !== 'received') &&
          <form onSubmit={handleSendMessage}>
            <div className="send-message">
              <TextField
                name="displayName"
                type="text"
                value={message}
                onChange={handleOnChange}
              />
              <button className="circle" type="submit" disabled={!room}>
                <img src={ArrowCircle} alt="arrow"/>
              </button>
            </div>
          </form>}
      </div>
    </div>
  )
}

export default MessagesRoom
