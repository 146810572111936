import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import Skeleton from 'react-loading-skeleton'

import CheckoutShipping, { type Service } from './CheckoutShipping'
import CheckoutPayment from './CheckoutPayment'
import { type Listing } from '../../models/Listings'
import { type Address, type BillingAddress, type CheckoutPaymentInfo, type PaymentMethod } from '../../models/Checkout'
import CheckoutEqipo from './CheckoutEqipo'
import eqipoService from '../../services/eqipoService'
import CheckoutConfirm from './CheckoutConfirm'
import checkoutService from '../../services/checkoutService'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import FormPagination from '../../components/FormPagination/FormPagination'
import { GlobalContext } from '../../context/GlobalContext'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import styled from 'styled-components'
import orderService from '../../services/orderService'

const Container = styled.div`
  padding: 32px 20px 0;
`

const POOL_INTERVAL = 500
const POOL_TIMEOUT = 15000

export interface CheckoutPayload {
  listingId?: string
  coupon?: string
  comment?: string
  shipment?: Address
  payment?: PaymentMethod
  shipmentService?: Service
}

const initialAddress = {
  city: '',
  state: '',
  country: '',
  district: '',
  address: '',
  address2: '',
  zipcode: '',
  number: '',
  serviceCode: '',
  termOfAgreement: ''
}

const Checkout = () => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState(1)
  const [steps] = useState([{ id: 1, label: 'Entrega' }, { id: 2, label: 'Pagamento' }, { id: 3, label: 'Confirmação' }])
  const [eqipo, setEqipo] = useState<Listing>()
  const [checkoutInfo, setCheckoutInfo] = useState<CheckoutPayload>()
  const [shippingAddress, setShippingAddress] = useState<Address>(initialAddress)
  const [billingAddress, setBillingAddress] = useState<BillingAddress>()
  const [paymentInfo, setPaymentInfo] = useState<CheckoutPaymentInfo>({ paymentMethod: 'PIX' })
  const { userProfile } = useContext(GlobalContext)
  const [screenLoading, setScreenLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const listingId = localStorage.getItem('checkoutListing')!
  const slug = localStorage.getItem('checkoutListingSlug')!

  const handleSaveShipping = (destinationAddress: Address, service: Service) => {
    setCheckoutInfo({ ...checkoutInfo, shipment: destinationAddress, shipmentService: service })
    setCurrentStep(2)
  }

  const handleSavePayment = (paymentInfo: PaymentMethod) => {
    setCheckoutInfo({ ...checkoutInfo, payment: paymentInfo })
    setCurrentStep(3)
  }

  const pollOrderStatus = async (
    orderId: string,
    onStatusChange: (orderStatus: string) => void,
    onError: () => void,
    interval: number,
    timeout: number
  ) => {
    const fetchOrderStatus = async () => {
      try {
        const orderRes: any = await orderService.getOrder(orderId)
        const orderStatus = orderRes.orderStatus

        if (orderStatus !== 'new') {
          clearInterval(pollingInterval)
          clearTimeout(timeoutHandle)
          onStatusChange(orderStatus)
        }
      } catch {
        clearInterval(pollingInterval)
        clearTimeout(timeoutHandle)
        onError()
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const pollingInterval = setInterval(fetchOrderStatus, interval)
    const timeoutHandle = setTimeout(() => {
      clearInterval(pollingInterval)
      onStatusChange('new')
    }, timeout)
  }

  const handleSubmitOrder = async (orderPayload: any) => {
    try {
      setButtonLoading(true)
      const res: any = await checkoutService.postOrder(orderPayload)
      const { line: barecode, pdf: barecodePDF, url: pixUrl, qrCode: pixQrCode } = res.payment[0]
      const paymentMethod = paymentInfo.paymentMethod!.toLowerCase()
      const orderId = res.id

      const redirectToFinalPage = (orderStatus: string) => {
        history.replace({
          pathname: `/checkout/compra-finalizada/${paymentMethod}`,
          state: { pixUrl, pixQrCode, barecode, barecodePDF, sellerName: eqipo?.user?.displayName || '', listingId, orderStatus }
        })
      }

      if (paymentMethod === 'credit_card') {
        await pollOrderStatus(
          orderId,
          redirectToFinalPage,
          () => toast.error(<ToastMessage />),
          POOL_INTERVAL,
          POOL_TIMEOUT
        )
      } else {
        redirectToFinalPage('new')
      }
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message} />) : toast.error(<ToastMessage />)
    }
  }

  useEffect(() => {
    const verifyAccount = async () => {
      setScreenLoading(true)
      try {
        await checkoutService.account.getPaymentAccount()
      } catch (error) {
        try {
          await checkoutService.account.createPaymentAccount()
        } catch (error) {
          // toast.warn(<ToastMessage label="Complete seu cadastro para comprar um equipo"/>)
          history.replace(`/atleta/${userProfile?.username}/editar/checkout?nextScreen=checkout`)
        }
      }
      setScreenLoading(false)
    }

    void verifyAccount()
  },
  [userProfile]
  )

  useEffect(
    () => {
      async function loadEqipo () {
        try {
          const listing = await eqipoService.getPublicEqipo({ slug, id: listingId })
          setEqipo(listing)
        } catch (error) {
          const { isWarn, message } = error as any
          isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        }
      }

      void loadEqipo()
    },
    [setEqipo, listingId, slug]
  )

  return (
    <div className="new-eqipo">
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <ReturnHeader
        click={() => {
          currentStep === 1 ? history.goBack() : setCurrentStep(currentStep - 1)
        }}
        style={{ marginTop: '32px' }}
        title={steps.find(({ id }) => id === currentStep)?.label || 'Entrega'}
        border={true}
      />
      <Container>
        {screenLoading
          ? <Skeleton height={150}/>
          : <>
            <FormPagination
              steps={steps}
              activateStep={currentStep}
              setCurrentStep={({ id }) => {
                setCurrentStep(id)
              }}
            />

            {eqipo && <CheckoutEqipo
              eqipo={eqipo}
            />}
            {currentStep === 1 && (
              <CheckoutShipping
                seller={eqipo?.user}
                listingId={listingId}
                onSaveShipping={handleSaveShipping}
                shippingAddress={shippingAddress}
                setShippingAddress={setShippingAddress}
              />
            )}
            {currentStep === 2 && (
              <CheckoutPayment
                listingId={listingId}
                onSavePayment={handleSavePayment}
                paymentInfo={paymentInfo}
                setPaymentInfo={setPaymentInfo}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
              />
            )}
            {currentStep === 3 && (
              <CheckoutConfirm
                checkoutInfo={checkoutInfo}
                eqipo={eqipo}
                onSubmitOrder={handleSubmitOrder}
                isLoading={buttonLoading}
              />
            )}
          </>}
      </Container>
    </div>
  )
}

export default Checkout
