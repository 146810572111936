import React from 'react'
import './FormPagination.scss'

interface ISteps {
  id: number
  label: string
}

interface IFormPagination {
  steps: ISteps[]
  activateStep: number
  setCurrentStep: ({ id, label }: ISteps) => void
}

const FormPagination: React.FC<IFormPagination> = ({
  activateStep,
  steps,
  setCurrentStep
}) => {
  return (
    <div className="containerFormPagination">
      <div className="hrFormPagination" />
      {steps?.map(({ id, label }) => (
        <span key={id}
          onClick={() => {
            activateStep > id && setCurrentStep({ id, label })
          }}
          className="stepFormPagination"
        >
          <div
            className={`circleFormPagination ${
              activateStep === id && 'selectedFormPagination'
            }`}
          >
            {id}
          </div>
          <small className="textStepeFormPagination">{label}</small>
        </span>
      ))}
    </div>
  )
}

export default FormPagination
