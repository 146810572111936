import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ArrowMenuOrange from '../../assets/images/arrow-menu-orange.svg'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage/ToastMessage'
import { useConfirmationService } from '../ConfirmDialog/ConfirmServiceProvider'
import listingsService from '../../services/listingsService'
import { type ListingBrand } from '../../models/Listings'

interface SelectBrandsProps {
  isLoading?: boolean
  isDisabled?: boolean
  value?: ListingBrand
  onChange: (selectedBrand: any) => void
  sports?: string[]
  placeholder: string
}

const SelectBrands: React.FC<SelectBrandsProps> = ({
  isLoading = false,
  isDisabled = false,
  onChange,
  value,
  sports = [],
  placeholder
}) => {
  const minBrandCharacters = 3
  const modalConfirm = useConfirmationService()
  const [options, setOptions] = useState<ListingBrand[]>([])
  const [triggerFetchOptions, setTriggerFetchOptions] = useState<boolean>(false)

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const fetchedOptions = await listingsService.searchListingBrand({ search: '' })
        setOptions(fetchedOptions)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
    void fetchBrands()
  }, [triggerFetchOptions])

  const onCreateBrand = async (name: string, sports: string[]) => {
    const title = <>ADICIONAR MARCA</>
    const description = (
      <>Você está adicionando uma marca<br/>{' '}
        <span style={{ color: '#FF6E5C', fontStyle: 'italic' }}>{name}</span></>
    )

    const shouldCreate = await modalConfirm({ title, description, buttonCancel: 'Voltar', buttonConfirm: 'Criar' })

    if (shouldCreate && name) {
      try {
        const payload = { name, sports }
        const newBrand = await listingsService.createListingBrand(payload)
        setTriggerFetchOptions(!triggerFetchOptions)
        onChange(newBrand)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
  }

  return (
    <AsyncCreatableSelect
      isClearable
      defaultOptions={options}
      isValidNewOption={(inputValue) => inputValue.length >= minBrandCharacters}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder={placeholder}
      value={value}
      loadOptions={async (inputValue: string) => options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))}
      getOptionLabel={(option: any) => option.__isNew__ ? option.label : `${option.name}`}
      getOptionValue={(option: any) => option.__isNew__ ? option.label : `${option.name}`}
      onChange={onChange}
      onCreateOption={async (name) => { await onCreateBrand(name, sports) }}
      noOptionsMessage={() => 'Digite pelo menos 1 caractere'}
      components={{
        Option: (props: any) => {
          const isCreateOption = props.data.__isNew__
          return (
            <components.Option {...props}>
              {!isCreateOption && <img src={ArrowMenuOrange} alt="Seta laranja" style={{ marginRight: '8px' }}/>}
              {props.children}
            </components.Option>
          )
        }
      }}
      styles={{
        option: (base, state) => ({
          ...base,
          color: '#050d3d',
          backgroundColor: state.isSelected ? '#f5f5f5;' : 'white'
        }),
        placeholder: (base) => ({
          ...base,
          color: '#C6C6C6',
          fontSize: '16px'
        })
      }}
      formatCreateLabel={(value) =>
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
          Criar &quot;{value}&quot;
        </span>}
    />
  )
}

export default SelectBrands
