import React from 'react'
import styled from 'styled-components'
import closeIcon from '../../assets/images/close.svg'
import { type IRefreshFilter } from './Filter'

const Button = styled.button`
  margin: 0 4px 8px;
  height: 23px;
  background: ${({ theme }) => theme.colors.uiBackground};
  border: 1px solid ${({ theme }) => theme.colors.uiGreyLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.qipBlue};
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 6px;
  > span {
    display: flex;
    label {
      // padding-left: 5px;
      font-size: 10px;
    }
  }
  > img {
    height: 10px;
    width: 10px;
    margin-left: 5px;
  }
`

interface IButtonFilterList {
  type: 'condition' | 'sport' | 'state'
  id: string
  label: string
  refreshFilter?: (_: IRefreshFilter) => Promise<void>
}

// const translateType = {
//   condition: 'Condição',
//   sport: 'Esporte',
//   state: 'Estado'
// }

const FilterButtonList = ({
  type,
  label,
  id,
  refreshFilter
}: IButtonFilterList) => {
  return (
    <Button>
      <span>
        {/* <b>{translateType[type]}:</b> */}
        <label>{label}</label>
      </span>
      {refreshFilter && <img src={closeIcon} onClick={async () => {
        await refreshFilter({ id })
      }} alt="" />}
    </Button>
  )
}

export default FilterButtonList
