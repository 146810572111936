import React from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonV2 as Button } from '../../../../components/Button/ButtonV2'
import './ModalFinish.scss'

interface IModalFinish {
  idModal?: string
}

const ModalFinish: React.FC<IModalFinish> = ({ idModal }) => {
  const history = useHistory()

  return (
    <div className="modalFinishContainerNewEqipo">
      <div className="modalFinishNewEqipo">
        <strong>Foi dada a largada!</strong>
        <p>
          Recebemos seu anúncio e se estiver tudo certo logo logo estará pronto para venda!
        </p>
        <small>
          <b>Fique ligada(o) no seu e-mail ou WhatsApp que podemos entrar em contato com você.</b>
        </small>
        <div className="buttonsContainerNewEqipoReview">
          <Button
            type="button"
            variant="blue"
            onClick={ () => {
              window.location.replace(
                String(process.env.REACT_APP_LINK_CURADORIA)
              )
            }
            }
          >
            SAIBA MAIS SOBRE A CURADORIA
          </Button>
          <Button
            margin="0px"
            type="button"
            variant="softBlue"
            onClick={ () => { history.replace('/') } }
          >
            Fechar
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModalFinish
