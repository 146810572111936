import { $http } from '../../httpService'

export interface PaymentsMethodsParams {
  listingId: string
}

interface FundingInstrumentsProps {
  method: 'CREDIT_CARD'
  creditCard: {
    expirationMonth: string
    expirationYear: string
    number: string
    cvc: string
    holderName: string
    holderDocument: string
    billingAddress: {
      street?: string
      streetNumber?: string
      district?: string
      city?: string
      state?: string
      country?: string
      zipcode?: string
    }
  }
}

interface FundingInstrumentsResponse {
  id: string
  first_six_digits: string
  last_four_digits: string
  brand: string
  holder_name: string
  holder_document: string
  exp_month: number
  exp_year: number
  status: string
  label: string
}

interface PaymentTransferProps {
  amount: number
  bankAccount: {
    id: string
  }
}

export async function getPaymentsMethods ({ listingId }: PaymentsMethodsParams) {
  const response = await $http.get(`/v1/payments/methods/${listingId}`)
  return response.data
}

export async function postFundingInstruments (payload: FundingInstrumentsProps): Promise<FundingInstrumentsResponse> {
  const response = await $http.post('/v1/payments/customers/funding-instruments', payload)
  return response.data
}

export const requestTransfer = async (payload: PaymentTransferProps) => {
  const response = await $http.post('/v1/payments/transfers', payload)
  return response.data
}

export const getBalance = async () => {
  const response = await $http.get('/v1/payments/balance')
  return response.data
}
