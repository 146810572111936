import React from 'react'

const FullScreenLoader: React.FC = () => {
  return (
    <div className="hero is-white is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="has-text-centered has-text-primary">
            <span className="icon is-large">
              <i className="mdi mdi-loading mdi-spin mdi-36px"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FullScreenLoader
