import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { GlobalContext } from '../../../context/GlobalContext'
import authService from '../../../services/authService'
import messageService from '../../../services/messageService'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'
import ArrowMenuOrange from '../../../assets/images/arrow-menu-orange.svg'

import './ProfileMenu.scss'

const CountMessagesUnread = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.uiWhite};
  background: ${({ theme }) => theme.colors.qipCoralLight};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  top: -1px;
  margin: 0px 8px 0px 0px;
  white-space: nowrap;
`

const ProfileMenu = () => {
  const history = useHistory()
  const { userProfile, setUserProfile } = useContext(GlobalContext)
  const [messagesUnread, setCountMessagesUnread] = useState(0)

  useEffect(() => {
    async function fetchData () {
      try {
        const { count } = await messageService.messagesUnread()
        setCountMessagesUnread(count)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={ message } />) : toast.error(<ToastMessage />)
      }
    }

    void fetchData()
  }, [])

  const SignOut = async () => {
    try {
      setUserProfile(null)
      await authService.signOut()
      history.push('/')
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={ message } />) : toast.error(<ToastMessage />)
    }
  }

  if (!userProfile) {
    return null
  }

  return (
    <div className="profile-menu">
      <ul className="profile-menu__items">
        <li
          className="profile-menu__item"
          onClick={ () => { history.push('/mensagens/?seller=true') } }
        >
          <p>{ messagesUnread > 0 && <CountMessagesUnread>{ messagesUnread }</CountMessagesUnread> }Mensagens</p>
          <img src={ArrowMenuOrange} alt="Seta laranja" />
        </li>
        <li className="profile-menu__item" onClick={ () => { history.push('/meus-equipos/') } }>
          <p>Meus Equipos</p>
          <img src={ArrowMenuOrange} alt="Seta laranja" />
        </li>
        <li
          className="profile-menu__item"
          onClick={ () => { history.push('/minhas-compras') } }
        >
          <p>Minhas Compras</p>
          <img src={ArrowMenuOrange} alt="Seta laranja" />
        </li>
        <li
          className="profile-menu__item"
          onClick={ () => { history.push('/minhas-vendas') } }
        >
          <p>Minhas Vendas</p>
          <img src={ArrowMenuOrange} alt="Seta laranja" />
        </li>
        <li
          className="profile-menu__item"
          onClick={ () => { history.push('/meu-extrato/') } }
        >
          <p>Meu Extrato</p>
          <img src={ArrowMenuOrange} alt="Seta laranja" />
        </li>
        <li className="profile-menu__exit " onClick={ SignOut }>
          <p>Sair</p>
        </li>
      </ul>
    </div>
  )
}

export default ProfileMenu
