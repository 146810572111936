import styled, { css } from 'styled-components'

export const Container = styled.label<any>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > input {
    display: none;
  }
  > img {
    height: 100%;
    width: 100%;
    object-fit: ${({ isProfile = false }) => isProfile ? 'cover' : 'contain'};
    border-radius: ${({ isProfile = false }) => isProfile ? '100%' : '0px'};
  }
  ${({ hasUploaded = false, theme }) => !hasUploaded && css`
    > img {
      object-fit: none;
    }

    background: ${theme.colors.uiBackground};
    border: 1px solid ${theme.colors.uiGreyLight};
  `}
`

interface ProgressBarProps {
  percentage: number
  isProfile: boolean
}

export const ProgressBar = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};

  transition: 2ms;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  ${({ isProfile }) => isProfile && css`border-radius: 100%;`}
`

export const ProgressText = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  flex-direction: column;
  color: #fff;

  > strong {
    color: #fff;
  }
`

export const PencilContainer = styled.span`
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.uiWhite};
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 15px !important;
  }
`
