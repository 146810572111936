import remote from './remote'

interface ClubParams {
  search: string
}

export interface ClubPayload {
  clubName: string
  state: string
  city: string
}

async function searchClub ({ search }: ClubParams) {
  return await remote.search({ search })
}

async function createClub (clubPayload: ClubPayload) {
  let result
  if (clubPayload.clubName.length > 3) {
    result = await remote.create(clubPayload)
  }
  return result
}

export default { searchClub, createClub }
