import React, { useEffect, useState } from 'react'
import Select, { type ActionMeta } from 'react-select'
import citiesByStateService from '../../services/citiesByStateService'
import FormField from '../FormComponents/FormField'
import { type Option } from '../FormComponents/SelectField'

type StateAndCitiesProps = Record<string, string[]>

interface SelectStateCityProps {
  handleStateChange: (option: any, action: ActionMeta<Option>) => void
  handleCityChange?: (option: any, action: ActionMeta<Option>) => void
  currentCityOption?: Option | Option[]
  currentStateOption?: Option | Option[]
  statesLabel?: string
  citiesLabel?: string
  isDisabled?: boolean
  stateValidation?: string | null
  cityValidation?: string | null
  isMulti?: boolean
}

export const SelectStateCity: React.FC<SelectStateCityProps> = ({
  currentCityOption,
  currentStateOption,
  handleCityChange,
  handleStateChange,
  statesLabel = 'Estado',
  citiesLabel = 'Cidade',
  isDisabled = false,
  stateValidation,
  cityValidation,
  isMulti = false
}) => {
  const [cities, setCities] = useState<Option[]>([])
  const [allCitiesAndStates, setAllCitiesAndStates] = useState<StateAndCitiesProps>({})
  const [states, setStates] = useState<Option[]>([])

  const handleChangeCityByState = (allCitiesByState: any, state: any) => {
    if (!allCitiesByState[state]) {
      return
    }
    const cities: Option[] = allCitiesByState[state].map((city: string) => ({
      value: city,
      label: city
    }))
    setCities(cities)
  }

  useEffect(() => {
    const loadCitiesByState = async () => {
      const response = await citiesByStateService.get()
      setAllCitiesAndStates(response)
      const states = Object.keys(response)
        .sort((stateA, stateB) => (stateA > stateB) ? 1 : -1)
        .map(state => ({
          value: state,
          label: state
        }))
      setStates(states)
    }
    void loadCitiesByState()
  }, [])

  useEffect(() => {
    if (!Array.isArray(currentStateOption)) {
      handleChangeCityByState(allCitiesAndStates, currentStateOption?.value)
    }
  }, [currentStateOption, allCitiesAndStates])

  return (
    <>
      <FormField
        name="currentState"
        label={statesLabel}
        validation={stateValidation}
        required
      >
        <Select
          value={currentStateOption}
          onChange={handleStateChange}
          placeholder="Selecionar"
          options={states}
          isDisabled={isDisabled}
          isMulti={isMulti}
          styles={{
            placeholder: (base) => ({
              ...base,
              color: '#C6C6C6',
              fontSize: '16px'
            })
          }}
        />
      </FormField>
      {handleCityChange &&
        <FormField
          name="currentCity"
          label={citiesLabel}
          validation={cityValidation}
          required
        >
          <Select
            value={currentCityOption}
            onChange={handleCityChange}
            placeholder="Selecionar"
            options={cities}
            isDisabled={isDisabled || !currentStateOption}
            isMulti={isMulti}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: '#C6C6C6',
                fontSize: '16px'
              })
            }}
          />
        </FormField>
      }
    </>
  )
}
