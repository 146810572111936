import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { type Route, useLocation } from 'react-router-dom'
import ListingsProvider from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import { ItemCondition } from '../../models/Listings'
import { type SportItem } from '../../services/sportService'
import Landing from './Landing'
import Listings from './Listings/Listings'

interface SearchProps {
  location: Route
}

interface ListingFilter {
  itemCondition: ItemCondition | null
  keyword: string | null
  sports: SportItem[] | []
}

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const HomePage = (props: SearchProps) => {
  const query = useQuery()
  const parentIdQuery = query.get('parentId')
  const sportIdQuery = query.get('sports')
  const keywordId = query.get('keyword')
  const itemConditionQuery = query.get('itemCondition') as ItemCondition

  const { sports, userProfile } = useContext(GlobalContext)

  const [initialFilter, setInitialFilter] = useState<ListingFilter>(
    {
      itemCondition: null,
      keyword: null,
      sports: []
    })

  useEffect(
    () => {
      const loadSportFilterByParentId = async () => {
        let sportsFiltered = sports || []

        if (parentIdQuery) {
          sportsFiltered = sportsFiltered?.filter(sport => sport.parentId === parentIdQuery)
        }

        if (sportIdQuery) {
          sportsFiltered = sportsFiltered?.filter(sport => sport.id === sportIdQuery)
        }

        if (!sportIdQuery) {
          sportsFiltered = []
        }

        const itemCondition = Object.values(ItemCondition)
          .some(condition => condition === itemConditionQuery)
          ? itemConditionQuery
          : null

        setInitialFilter({
          itemCondition,
          sports: sportsFiltered,
          keyword: keywordId || null
        })
      }

      void loadSportFilterByParentId()
    },
    [keywordId, parentIdQuery, sportIdQuery, itemConditionQuery]
  )

  return (
    <ListingsProvider>
      <Navbar />
      { !userProfile && initialFilter.sports.length === 0
        ? <Landing />
        : <Listings />
      }
    </ListingsProvider>
  )
}

export default HomePage
