import React from 'react'
import { type UserProfile } from '../../../models/User'
import { SocialContainer } from './styles'
import { social } from '../SocialDictionary'

interface UserSocialsProps {
  profile: UserProfile
  isOwner: boolean
}

const UserSocials: React.FC<UserSocialsProps> = ({ profile }) => {
  if (!profile.socialsNetwork?.length) return null

  return <SocialContainer>
    <h5>
      Me Encontre
    </h5>
    <div>
      {
        profile.socialsNetwork.map(({ title, url }, i) => (
          <a key={i} href={ `${social[title].prefix || ''}${url}` } rel="noopener noreferrer" target="_blank">
            { social[title]
              ? (
                <img alt={ social[title].alt } src={ social[title].src } />
                )
              : (
                `link para ${title}`
                ) }
          </a>
        ))
      }
    </div>
  </SocialContainer>
}

export default UserSocials
