import styled from 'styled-components'

const Container = styled.div`
  height: 72px;
  width: calc(100% + 40px);
  margin-left: -20px;
  border-top: 1px solid rgba(0,0,0,0.25);
  padding: 16px 32px;
  margin-top: 32px;
`

export default Container
