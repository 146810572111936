import React from 'react'
import './RoomFilters.scss'

interface RoomFiltersProps {
  seller?: boolean
  setSeller?: (value: boolean) => void
  filter?: string
  setFilter?: (value: string) => void
  unreadSellingMessages?: number
  unreadBuyingMessages?: number
}

const RoomFilters: React.FC<RoomFiltersProps> = ({
  seller = true,
  setSeller,
  unreadSellingMessages,
  unreadBuyingMessages
}) => {
  return (
    <div className="filter-header">
      <ul>
        <li
          className={seller ? 'filter-seller active' : 'filter-seller'}
          onClick={() => {
            setSeller && setSeller(true)
          }}
        >
          Vendendo {Boolean(unreadSellingMessages) && <span className="badge">{unreadSellingMessages}</span>}
        </li>
        <li
          className={seller ? 'filter-seller' : 'filter-seller active'}
          onClick={() => {
            setSeller && setSeller(false)
          }}
        >
          Comprando {Boolean(unreadBuyingMessages) && <span className="badge">{unreadBuyingMessages}</span>}
        </li>
      </ul>
    </div>
  )
}

export default RoomFilters
