import Axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'

import authService from './authService/index'
import { getFirebaseErrorHandler } from './firebaseService'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const axiosConfig = {
  baseURL: apiBaseUrl,
  timeout: 10000,
  headers: { Accept: 'application/json' }
}
const translateMessageError = (message: any) => {
  switch (message) {
    case 'Invalid Username':
      return 'Ops! Usuário já utilizado!'
    default:
      return 'Ops! Verifique se preencheu todos os campos corretamente.'
  }
}

const remote = Axios.create(axiosConfig)

remote.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    return await authService
      .getCurrentUser()
      .then((currentUser) => {
        config.headers.Authorization = `Bearer ${currentUser.token}`
        return config
      })
      .catch(() => {
        return config
      })
  },
  // eslint-disable-next-line n/handle-callback-err
  async (err) => {
    return await Promise.reject({
      isWarn: false,
      message: 'Ops! Aconteceu algo fora do esperado.'
    })
  }
)
remote.interceptors.response.use(
  async (config: AxiosResponse) => {
    // Do something before request is sent
    return config
  },
  async (err) => {
    const status = err.response ? err.response.status : null
    const messageError = err.response ? err.response.data.message : null

    if (status === 401) {
      try {
        const currentUser = await authService.getCurrentUser()
        // try to reload user
        await currentUser.user.reload()
        const currentUserRefreshed = await authService.getCurrentUser()
        err.config.headers.Authorization = `Bearer ${currentUserRefreshed.token}`
        return await Axios.request(err.config)
      } catch (reloadErr) {
        return await Promise.reject(reloadErr)
      }
    }

    if (status === 422 || status === 502) {
      const { message, isWarn } = getFirebaseErrorHandler(err.response.data.info.code)

      return await Promise.reject({
        isWarn,
        message,
        error: err
      })
    }

    if (status === 400) {
      return await Promise.reject({
        isWarn: true,
        message: translateMessageError(messageError),
        error: err
      })
    }

    return await Promise.reject({
      isWarn: false,
      message: 'Ops! Aconteceu algo fora do esperado.',
      error: err
    })
  }
)

const publicRemote = Axios.create(axiosConfig)

export const $http = remote
export const $publicHttp = publicRemote
