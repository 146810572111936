import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react'

import { type FullListing, type Listing } from '../../models/Listings'
import listingsService from '../../services/listingsService'

import { type SportItem } from '../../services/sportService'
import { useHistory, useLocation } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'
import { translateCondition } from '../../pages/Eqipo/Eqipo'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage/ToastMessage'

interface SelectOptionProps {
  label: string
  value: string
}

export interface ListingFilter {
  sports?: SportItem[]
  conditions?: SelectOptionProps[]
  states?: SelectOptionProps[]
}

interface ListingsContextI {
  isLoading: boolean
  listings?: Listing[] | FullListing[]
  filter: ListingFilter
  refreshFilter: any
}

interface ProviderProps {
  filter?: ListingFilter
  children: ReactNode
}

export const initialFilter = {
  sports: [],
  conditions: [],
  states: []
}

const state: ListingsContextI = {
  isLoading: false,
  listings: [],
  filter: initialFilter,
  refreshFilter: () => {}
}

const queryToState = (query: string, setFilter: any, sports: SportItem[]) => {
  const params = new URLSearchParams(query)

  const newState: any = {}

  params.forEach((value, key) => {
    if (key === 'sports') {
      const sport = sports.filter(sport => sport.id === value).pop()
      newState.sports = [...(newState.sports || []), sport]
    } else if (key === 'itemConditions') {
      newState.conditions = [...(newState.conditions || []), { value, label: translateCondition(value) }]
    } else if (key === 'states') {
      newState.states = [...(newState.states || []), { value }]
    }
  })

  setFilter((prevState: any) => ({ ...newState }))
}

export const stateToQuery = (filter: ListingFilter) => {
  const params = {
    sports: filter.sports?.map(sport => `sports=${sport.id}`).join('&'),
    itemConditions: filter.conditions?.map(condition => `itemConditions=${condition.value}`).join('&'),
    states: filter.states?.map(state => `states=${state.value}`).join('&')
  }

  const queryParts = []
  if (params.sports) queryParts.push(params.sports)
  if (params.itemConditions) queryParts.push(params.itemConditions)
  if (params.states) queryParts.push(params.states)

  return queryParts.join('&')
}

export const ListingsContext = createContext<ListingsContextI>(state)

const ListingsProvider: React.FC<ProviderProps> = (props) => {
  const [filter, setFilter] = useState<ListingFilter>(props.filter || initialFilter)
  const [listings, setListings] = useState<Listing[]>([])
  const [loading, setLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const { sports } = useContext(GlobalContext)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    queryToState(location.search, setFilter, sports)
  }, [location.search])

  useEffect(() => {
    const fetchListings = async () => {
      try {
        setLoading(true)
        const query = stateToQuery(filter)
        const result = await listingsService.searchListings(query)
        setListings(result)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      } finally {
        setLoading(false)
      }
    }
    if (!isFirstLoad) {
      void fetchListings()
    }
    setIsFirstLoad(false)
  }, [filter])

  const refreshFilter = async ({ id }: any) => {
    const { sports, conditions, states } = filter
    const newFilter = {
      ...state,
      sports: sports?.filter(sport => sport.id !== id),
      conditions: conditions?.filter(condition => condition.value !== id),
      states: states?.filter(filter => filter.value !== id)
    }
    const query = stateToQuery(newFilter)
    history.push(`/eqipos?${query}`)
  }

  return (
    <ListingsContext.Provider
      value={{
        listings,
        isLoading: loading,
        filter,
        refreshFilter
      }}>
      {props.children}
    </ListingsContext.Provider>
  )
}

export default ListingsProvider
