import React from 'react'
import Cleave from 'cleave.js/react'
import { type CleaveOptions } from 'cleave.js/options'
import { type ChangeEvent } from 'cleave.js/react/props'

type OnChange = (name: string, value: string, rawValue: string) => void

type inputModeField =
  | 'text'
  | 'none'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search'
  | undefined

interface CleaveFieldProps {
  name: string
  inputMode?: inputModeField
  type?: string
  value?: string
  autoComplete?: string
  options: CleaveOptions
  maxLength?: number
  onChange: OnChange
  placeholder?: string
  disabled?: boolean
}

const CleaveField = (props: CleaveFieldProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.name, e.target.value, e.target.rawValue)
  }

  return (
    <div className="control">
      <Cleave
        name={ props.name }
        id={ props.name }
        type={ props.type }
        inputMode={ props.inputMode }
        autoComplete={ props.autoComplete }
        maxLength={ props.maxLength }
        options={ props.options }
        value={ props.value }
        onChange={ handleChange }
        className="input"
        placeholder={ props.placeholder }
        disabled={ props.disabled }
      />
    </div>
  )
}

export default CleaveField
