import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ArrowMenuOrange from '../../assets/images/arrow-menu-orange.svg'
import { type Club } from '../../models/Clubs'
import clubService from '../../services/clubService'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage/ToastMessage'
import { useConfirmationService } from '../ConfirmDialog/ConfirmServiceProvider'

interface SelectClubsProps {
  isLoading?: boolean
  isDisabled?: boolean
  value: Club[]
  onChange: (selectedClubs: any) => void
  state?: string
  city?: string
}

const SelectClubs: React.FC<SelectClubsProps> = ({
  isLoading = false,
  isDisabled = false,
  onChange,
  value,
  state,
  city
}) => {
  const minClubCharacters = 3
  const modalConfirm = useConfirmationService()
  const [options, setOptions] = useState<Club[]>([])

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const fetchedOptions = await clubService.searchClub({ search: '' })
        setOptions(fetchedOptions)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
    void fetchClubs()
  }, [])

  const onCreateClub = async (clubName: string) => {
    const title = <>ADICIONAR ASSESSORIA <br/>OU CLUBE</>
    const description = (
      <>Você está adicionando a assessoria/clube<br/>{' '}
        <span style={{ color: '#FF6E5C', fontStyle: 'italic', fontWeight: 'bold' }}>
          {clubName}
        </span></>
    )

    const shouldCreate = await modalConfirm({ title, description, buttonCancel: 'Voltar', buttonConfirm: 'Adicionar' })

    if (shouldCreate && state && city) {
      try {
        const payload = { clubName, state, city }
        const newClub = await clubService.createClub(payload)
        // When deleting new created option, value is not on the option list.
        // I tried to force update using setOptions, but it didn't work
        // setOptions(prevOptions => [...prevOptions, newClub])
        onChange([...value, newClub])
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
  }

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions
      isMulti
      isClearable={false}
      isValidNewOption={(inputValue) => inputValue.length >= minClubCharacters}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder="Escolher ou adicionar"
      value={value}
      loadOptions={async (inputValue: string) => options.filter(option => option.clubName.toLowerCase().includes(inputValue.toLowerCase()))}
      getOptionLabel={(option: any) => option.__isNew__ ? option.label : `${option.clubName}`}
      getOptionValue={(option: any) => option.__isNew__ ? option.label : `${option.id}`}
      onChange={onChange}
      onCreateOption={async (clubName) => { await onCreateClub(clubName) }}
      noOptionsMessage={() => `Digite pelo menos ${minClubCharacters} caracteres`}
      components={{
        Option: (props: any) => {
          const isCreateOption = props.data.__isNew__
          return (
            <components.Option {...props}>
              {!isCreateOption && <img src={ArrowMenuOrange} alt="Seta laranja" style={{ marginRight: '8px' }}/>}
              {props.children}
            </components.Option>
          )
        }
      }}
      styles={{
        option: (base) => ({
          ...base,
          color: '#050d3d'
        }),
        placeholder: (base) => ({
          ...base,
          color: '#C6C6C6',
          fontSize: '16px'
        })
      }}
      formatCreateLabel={(value) =>
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
          Adicionar &quot;{value}&quot;
        </span>}
    />
  )
}

export default SelectClubs
