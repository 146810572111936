import React, { useEffect, useState } from 'react'

import sportService, { type SportItem } from '../services/sportService'
import userService from '../services/userService'
import { type UserProfile } from '../models/User'
import { toast } from 'react-toastify'
import ToastMessage from '../components/ToastMessage/ToastMessage'

interface GlobalProviderProps {
  children: React.ReactElement | React.ReactElement[]
}

interface GlobalContextState {
  sports: SportItem[]
  userProfile: UserProfile | null
  setUserProfile: (payload: any) => void
  isLoading: boolean
}

const state: GlobalContextState = {
  sports: [],
  userProfile: null,
  setUserProfile: () => {
  },
  isLoading: true
}

export const GlobalContext = React.createContext(state)

export const GlobalProvider = (props: GlobalProviderProps) => {
  const [globalState, setGlobalState] = useState<GlobalContextState>(state)

  const setUserProfile = (userProfile: UserProfile | null) => {
    setGlobalState(prevState => ({
      ...prevState,
      userProfile
    }))
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await userService.getUser()
        setGlobalState(prevState => ({
          ...prevState,
          userProfile,
          isLoading: false
        }))
      } catch (error) {
        setGlobalState(prevState => ({
          ...prevState,
          isLoading: false
        }))
      }
    }

    void fetchUserProfile()
  }, [])

  useEffect(() => {
    const fetchSports = async () => {
      try {
        const sports = await sportService.getSports()
        setGlobalState(prevState => ({
          ...prevState,
          sports
        }))
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        setGlobalState(prevState => ({
          ...prevState
        }))
      }
    }

    void fetchSports()
  }, [])

  return (
    <GlobalContext.Provider value={ { ...globalState, setUserProfile } }>
      { props.children }
    </GlobalContext.Provider>
  )
}
