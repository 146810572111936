import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import FormField from '../../components/FormComponents/FormField'
import TextField from '../../components/FormComponents/TextField'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import './ResetPassword.scss'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import * as yup from 'yup'
import authService from '../../services/authService'

const ResetPassword: React.FC = () => {
  const schema = yup.object({
    newPassword: yup.string().min(6, 'A senha deve ter no mínimo 6 digitos').required('Campo obrigatório'),
    newPasswordConfirmation: yup.string().oneOf([yup.ref('newPassword'), undefined], 'As senhas não conferem').required('Campo obrigatório'),
    oobCode: yup.string().required()
  })

  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const [errors, setErrors] = useState<any>(null)
  const [oobCode, setOobCode] = useState<string | null>(null)
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const mode = query.get('mode')
    const code = query.get('oobCode')
    const apiKey = query.get('apiKey')

    if (mode === 'resetPassword' && code && apiKey) {
      setOobCode(code)
    } else {
      toast.error(<ToastMessage/>)
    }
  }, [query])

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      setErrors(null)
      await schema.validate({ newPassword, newPasswordConfirmation, oobCode }, { abortEarly: false })

      if (oobCode) {
        await authService.confirmPasswordReset(oobCode, newPassword)
        history.push('/entrar')
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessage = error.inner.reduce((obj: any, item: any) => {
          obj[item.path] = item.message
          return obj
        }, {})
        setErrors({ ...errorMessage })
      } else {
        toast.error(<ToastMessage/>)
      }
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Navbar/>
      <div className="container">
        {oobCode && (
          <form onSubmit={handleResetPassword}>
            <>
              <FormField
                name="newPassword"
                label="Nova Senha"
                validation={errors?.newPassword}
                required
              >
                <TextField
                  name="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(_, value) => {
                    setNewPassword(value)
                  }}
                  disabled={isLoading}
                  autoComplete="new-password"
                  placeholder="Digite uma senha"
                />
              </FormField>
              <FormField
                name="newPasswordConfirmation"
                label="Confirme sua nova senha"
                validation={errors?.newPasswordConfirmation}
                required
              >
                <TextField
                  name="newPasswordConfirmation"
                  type="password"
                  value={newPasswordConfirmation}
                  onChange={(_, value) => {
                    setNewPasswordConfirmation(value)
                  }}
                  disabled={isLoading}
                  autoComplete="new-password"
                  placeholder="Confirme sua senha"
                />
              </FormField>
            </>
            <Button
              variant="primary"
              type="button"
              margin="16px 0px 0px "
              onClick={handleResetPassword}
              isLoading={isLoading}
            >
              CONFIRMAR NOVA SENHA
            </Button>
          </form>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
