import remote from './remote'

interface NotificationParams {
  id: string
}

async function listNotifications () {
  return await remote.list()
}

async function readNotification ({ id }: NotificationParams) {
  return await remote.read({ id })
}

export default { listNotifications, readNotification }
