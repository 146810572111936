import remote, { type EditListingPrice, type NewListing } from './remote'
import { ListingStatus } from '../../models/Listings'

async function searchListings (query?: string) {
  return await remote.searchListings(query)
}

async function getEnabledListings (userId: string) {
  return await remote.getEnabledListings(userId)
}

async function postListing (listing: NewListing) {
  return await remote.postListing(listing)
}

async function editListing (listingId: string, listing: NewListing | any) {
  return await remote.editListing(listingId, listing)
}

async function editListingPrice (listingId: string, payload: EditListingPrice) {
  return await remote.editListingPrice(listingId, payload)
}

async function sendToReview (listingId: string) {
  return await remote.editListing(listingId, { itemStatus: ListingStatus.WAITING_REVIEW })
}

async function searchListingBrand ({ search }: { search: string }) {
  return await remote.searchListingBrand({ search })
}

async function createListingBrand (brandPayload: { name: string, sports: string[] }) {
  let result
  if (brandPayload.name.length > 3) {
    result = await remote.createListingBrand(brandPayload)
  }
  return result
}

async function searchListingSize ({ search }: { search: string }) {
  return await remote.searchListingSize({ search })
}

async function createListingSize (sizePayload: { label: string }) {
  return await remote.createListingSize(sizePayload)
}

export default { searchListings, getEnabledListings, postListing, sendToReview, editListing, editListingPrice, searchListingBrand, createListingBrand, searchListingSize, createListingSize }
