import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import messageService from '../../services/messageService'
import { Link } from 'react-router-dom'
import emptyProfileAvatar from '../../assets/images/empty-profile.svg'
import './NavbarUserMenu.scss'
import { Roles } from '../../models/User'

const NavBarUserMenu = () => {
  const { userProfile } = useContext(GlobalContext)
  const [showProfileMenu, toggleShowProfileMenu] = useState(false)
  const [unreadMessagesCount, setCountMessagesUnread] = useState(0)
  const isAdmin =
    userProfile?.customClaims?.roles?.includes(Roles.superAdmin) || false

  useEffect(() => {
    async function fetchData () {
      try {
        const { count } = await messageService.messagesUnread()
        setCountMessagesUnread(count)
      } catch (error) {
        console.log('ocorreu um erro ao pegar a quantidade de mensagens não lida')
      }
    }

    void fetchData()
  }, [])

  return (
    <div className="profile-container">
      <ul className="user-menu">
        <li className="user-menu__avatar">
          <Link to={ `/atleta/${userProfile!.username}` }>
            <button
              className="user-menu__btn"
              onClick={ () => { toggleShowProfileMenu(!showProfileMenu) } }
              style={{ position: 'relative' }}
            >
              <figure className="userAvatar image is-24x24">
                <img
                  className="is-rounded"
                  style={ { height: '24px', width: '24px' } }
                  alt="User avatar"
                  src={
                    userProfile?.photoURL
                      ? userProfile.photoURL
                      : emptyProfileAvatar
                  }
                />
                { isAdmin && <div className="adminLabel">Admin</div> }
                { unreadMessagesCount > 0 &&
                  <span className="unreadMessageBadge">
                         {unreadMessagesCount > 99 ? '99+' : unreadMessagesCount}
                  </span>
                }
              </figure>
            </button>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default NavBarUserMenu
