import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollTop = () => {
  const [showScroll, setShowScroll] = useState(false)
  const location = useLocation()

  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' })
  // }

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 65) {
      setShowScroll(true)
    } else if (showScroll && window.scrollY <= 65) {
      setShowScroll(false)
    }
  }

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  window.addEventListener('scroll', checkScrollTop)

  if (location.pathname.startsWith('/mensagem/')) {
    return null
  }

  return (
    <>
      {/* { showScroll && ( */}
      {/*  <Container type="button" onClick={ () => { scrollTop() } }> */}
      {/*    <img src={ArrowTop} alt="arrow-top" /> */}
      {/*  </Container> */}
      {/* ) } */}
    </>
  )
}

export default ScrollTop
