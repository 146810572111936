import React, { useState } from 'react'
import * as yup from 'yup'
import { useHistory } from 'react-router'

import CleaveField from '../../components/FormComponents/CleaveField'
import FormField from '../../components/FormComponents/FormField'
import TextField from '../../components/FormComponents/TextField'
import FormPagination from '../../components/FormPagination/FormPagination'
import Navbar from '../../components/Navbar/Navbar'
import './Auth.scss'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { useSignUp } from '../../context/SignUpContext'
import { atLeastTwoWords } from '../../helpers/yupValidation'
import { strictEmailRegex, strictPhoneNumberRegex } from '../../helpers/regex'

const schema = yup.object(
  {
    displayName: yup.string().required('Campo obrigatório').test('Nome inválido', 'Nome deve conter pelo menos duas palavras', atLeastTwoWords),
    email: yup.string().matches(strictEmailRegex, 'E-mail inválido').required('Campo obrigatório'),
    phoneNumber: yup.string().matches(strictPhoneNumberRegex, 'Número de telefone inválido').required('Campo obrigatório'),
    stravaId: yup.number().integer().required()
  })

const SignUpStrava = () => {
  const history = useHistory()
  const { signUp, updateSignUp } = useSignUp()
  const [errors, setErrors] = useState({ displayName: '', email: '', phoneNumber: '' })
  const [currentStep, setCurrentStep] = useState(1)

  const handleSubmit = async (e: React.FormEvent) => {
    setErrors({ displayName: '', email: '', phoneNumber: '' })

    try {
      e.preventDefault()
      await schema.validate(signUp, { stripUnknown: true, abortEarly: false })

      history.push('/cadastrar/meus-dados')
    } catch (error: any) {
      let fieldsWithError = {
        displayName: '',
        email: '',
        phoneNumber: ''
      }

      if (error instanceof yup.ValidationError) {
        error.inner.forEach((error) => {
          fieldsWithError = { ...fieldsWithError, [String(error.path)]: error.message }
          setErrors({ ...errors, [String(error.path)]: error.message })
        })

        setErrors(fieldsWithError)
      }
    }
  }

  const handleChange = (name: string, value: string) => {
    updateSignUp({ [name]: value })
  }

  return (
    <div className="signup-form">
      <Navbar noButtons/>
      <form className="signup-form__content" onSubmit={handleSubmit} noValidate>
        <FormPagination
          steps={[{ id: 1, label: 'DADOS PESSOAIS' }, { id: 2, label: 'ESPORTE' }]}
          activateStep={currentStep}
          setCurrentStep={({ id }) => { setCurrentStep(id) }}
        />
        <div>
          <FormField
            name="displayName"
            label="Nome"
            validation={errors.displayName}
            required
          >
            <TextField
              name="displayName"
              type="text"
              value={signUp.displayName}
              onChange={handleChange}
            />
          </FormField>
          <FormField
            name="email"
            label="E-mail"
            validation={errors.email}
            required
          >
            <TextField
              name="email"
              type="text"
              autoComplete="email"
              value={signUp.email}
              onChange={handleChange}
            />
          </FormField>
          <FormField
            name="phoneNumber"
            label="Telefone"
            validation={errors.phoneNumber}
            required
          >
            <CleaveField
              name="phoneNumber"
              type="tel"
              inputMode="numeric"
              maxLength={14}
              options={{
                delimiters: ['(', ')', '-'],
                blocks: [0, 2, 5, 4],
                numericOnly: true
              }}
              value={signUp.phoneNumber}
              onChange={handleChange}
            />
          </FormField>
        </div>
        <div className="signup-form__submit">
          <div className="control has-text-centered">
            <Button variant="primary" type="submit">
              Continuar
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SignUpStrava
