import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ArrowMenuOrange from '../../assets/images/arrow-menu-orange.svg'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage/ToastMessage'
import { useConfirmationService } from '../ConfirmDialog/ConfirmServiceProvider'
import listingsService from '../../services/listingsService'
import { type ListingSize } from '../../models/Listings'

interface SelectSizesProps {
  isLoading?: boolean
  isDisabled?: boolean
  value?: ListingSize
  onChange: (selectedSize: any) => void
  placeholder: string
}

const SelectSizes: React.FC<SelectSizesProps> = ({
  isLoading = false,
  isDisabled = false,
  onChange,
  value,
  placeholder
}) => {
  const minSizeCharacters = 1
  const modalConfirm = useConfirmationService()
  const [options, setOptions] = useState<ListingSize[]>([])
  const [triggerFetchOptions, setTriggerFetchOptions] = useState<boolean>(false)
  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const fetchedOptions = await listingsService.searchListingSize({ search: '' })
        setOptions(fetchedOptions)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
    void fetchSizes()
  }, [triggerFetchOptions])

  const onCreateSize = async (label: string) => {
    const title = <>ADICIONAR TAMANHO</>
    const description = (
      <>Você está adicionando um tamanho<br/>{' '}
        <span style={{ color: '#FF6E5C', fontStyle: 'italic' }}>{label}</span></>
    )

    const shouldCreate = await modalConfirm({ title, description, buttonCancel: 'Voltar', buttonConfirm: 'Criar' })

    if (shouldCreate && label) {
      try {
        const payload = { label }
        const newSize = await listingsService.createListingSize(payload)
        setTriggerFetchOptions(!triggerFetchOptions)
        onChange(newSize)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
  }

  return (
    <AsyncCreatableSelect
      isClearable
      defaultOptions={options}
      isValidNewOption={(inputValue) => inputValue.length >= minSizeCharacters}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder={placeholder}
      value={value}
      loadOptions={async (inputValue: string) => options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()))}
      getOptionLabel={(option: any) => option.__isNew__ ? option.label : `${option.label}`}
      getOptionValue={(option: any) => option.__isNew__ ? option.label : `${option.label}`}
      onChange={onChange}
      onCreateOption={async (label) => { await onCreateSize(label) }}
      noOptionsMessage={() => 'Digite pelo menos 1 caractere'}
      components={{
        Option: (props: any) => {
          const isCreateOption = props.data.__isNew__
          return (
            <components.Option {...props}>
              {!isCreateOption && <img src={ArrowMenuOrange} alt="Seta laranja" style={{ marginRight: '8px' }}/>}
              {props.children}
            </components.Option>
          )
        }
      }}
      styles={{
        option: (base, state) => ({
          ...base,
          color: '#050d3d',
          backgroundColor: state.isSelected ? '#f5f5f5;' : 'white'
        }),
        placeholder: (base) => ({
          ...base,
          color: '#C6C6C6',
          fontSize: '16px'
        })
      }}
      formatCreateLabel={(value) =>
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
          Criar &quot;{value}&quot;
        </span>}
    />
  )
}

export default SelectSizes
