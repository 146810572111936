import React from 'react'
import './Container.scss'

export interface ContainerProps {
  className?: string
  children?: any
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => (
  <section className={`container ${props.className}`}>{props.children}</section>
)

export default Container
