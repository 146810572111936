import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import eqipoService from '../../services/eqipoService/index'
import Container from '../../components/Container/Container'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'

import Navbar from '../../components/Navbar/Navbar'

import './MySales.scss'
import { type Listing, ListingStatus } from '../../models/Listings'
import Cta from '../../components/Cta/Cta'
import EqipoCard from '../../components/EqipoCard/EqipoCard'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import { EqipoLoadingSkeleton } from '../../components/EqiposList/EqipoLoadingSkeleton'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { OrderStatus } from '../../models/Orders'

const MySales = () => {
  const history = useHistory()
  const [salesList, setSalesList] = useState<any | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const result = await eqipoService.getMyEqipos({ itemStatus: [ListingStatus.SOLD] })
        setSalesList(result[0] !== null ? result : [])
        setLoading(false)
      } catch (error) {
        const { isWarn, message } = error as any
        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    }
    void fetchPurchases()
  }, [])

  const getStatus = (listing: Listing) => {
    if (listing.order?.orderStatus === OrderStatus.new) {
      return OrderStatus.new
    }

    if (listing.order?.orderStatus === OrderStatus.shipped) {
      return OrderStatus.shipped
    }

    if (listing.order?.orderStatus === OrderStatus.received) {
      return 'sold'
    }
  }

  return (
    <div>
      <div className="Header">
        <ListingsProvider filter={initialFilter}>
          <Navbar/>
        </ListingsProvider>
      </div>

      <ReturnHeader title="MINHAS VENDAS" border/>
      {loading
        ? <EqipoLoadingSkeleton disableHeader/>
        : <Container className="my-sales">
          {
            (!salesList || salesList.length === 0)
              ? <Cta
                title="E aquele seu equipo?"
                content="Aquele seu equipo em boas condições de uso pode ser o início ou o próximo passo da jornada de alguém no esporte!"
                isButton
                buttonProps={{ title: 'Vender Equipo', route: '/eqipo/novo' }}
              />
              : <div className="containerMySales">
                {salesList.map((listing: Listing, index: any) => {
                  return (
                      <div key={index}>
                        <EqipoCard
                          typeEqipo={'grid'}
                          key={listing.id}
                          id={listing.id}
                          cover={listing.cover}
                          title={listing.title}
                          price={listing.order!.listing.negotiatedPrices?.[0]?.price || listing.order!.listing.price}
                          seller={listing.order!.buyer}
                          photos={listing.photos}
                          status={getStatus(listing)}
                        />
                        {listing?.order && (
                          <div className="containerButtonEqipoItem">
                            <Button
                              type="button"
                              variant="blueSecondary"
                              onClick={() => {
                                history.push(`/mensagem/${listing.room!.id || ''}`)
                              }}
                            >
                              VER MENSAGENS
                            </Button>
                            {listing?.order?.orderStatus === 'authorization' &&
                              <Button
                                type="button"
                                className="buttonEqipoItem"
                                style={{ fontSize: 13 }}
                                onClick={() => {
                                  history.push(`/confirmar-envio/${listing.order!.id}/`)
                                }}
                              >
                                ENTREGUEI O EQUIPO
                              </Button>
                            }
                          </div>
                        )}
                      </div>
                  )
                }
                )}
              </div>
          }
        </Container>
      }
    </div>
  )
}

export default MySales
