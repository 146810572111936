import { $http } from '../../httpService'

import authService from '../../authService/index'
import { type Sport } from '../../../models/Sports'
import { type Club } from '../../../models/Clubs'
import { type UserProfile } from '../../../models/User'
import { isNull, omitBy } from 'lodash'

interface UserParams {
  email?: string
  password?: string
  passwordConfirmation?: string
  firstName?: string
  lastName?: string
  displayName?: string
  username?: string
  phoneNumber?: string
  photoURL?: string
  taxNumber?: string
  idNumber?: string
  idIssuer?: string
  idIssuerDate?: string
  birthDate?: string
  address?: string
  address2?: string
  addressNumber?: string
  district?: string
  zipcode?: string
  city?: string
  state?: string
  country?: string
  clubs?: Club[]
  sports?: Sport[]
  fcmToken?: string
}

async function get () {
  const response = await $http.get('/v1/user')
  return response.data
}

const getUserByUsername = async (username: string) => {
  const response = await $http.get(`/v1/users/${username}`)

  return response.data
}

async function patchUserData ({ email, sports, clubs, city, state }: UserParams) {
  const { user: currentUser } = await authService.getCurrentUser()
  const response = await $http.patch('/v1/user/', {
    sports,
    clubs,
    email,
    address: {
      currentCity: city,
      currentState: state
    }
  })

  if ((currentUser.email && !currentUser.emailVerified) || currentUser.email !== email) {
    await currentUser.sendEmailVerification()
  }

  return response.data
}

async function sendEmailVerification () {
  const { user: currentUser } = await authService.getCurrentUser()

  await currentUser.sendEmailVerification()
}

const updatePhotoUserProfile = async (photoURL: string) => {
  const payload = { photoURL }
  const response = await $http.patch('/v1/user/photoURL', payload)
  return response.data
  // await authService.updatePhotoUserProfile(photoURL);
}

const updateUserProfile = async (user: UserProfile | null) => {
  const payload = omitBy(user, isNull)
  const response = await $http.patch('/v1/user/', payload)
  return response.data
}
const validEmail = async (email: string) => {
  const response = await $http.get(`/v1/users/${email}/validate`)
  return response.data
}

export default {
  get,
  getUserByUsername,
  patchUserData,
  sendEmailVerification,
  updatePhotoUserProfile,
  updateUserProfile,
  validEmail
}
