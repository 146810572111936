import React from 'react'
import { ButtonsProfile, Container, Description, Profile, ProfileDetails, SocialContainer, SportList } from './styles'
import Skeleton from 'react-loading-skeleton'

const ProfileLoading: React.FC = () => {
  return <Container>
    <Profile>
      <Skeleton circle height={ 110 } width={ 110 } />
      <ProfileDetails>
        <h3><Skeleton /></h3>
        <small>
          <Skeleton />
        </small>
        <span>
              <Skeleton />
            </span>
      </ProfileDetails>
    </Profile>
    <Description>
      <h5>
        <Skeleton height={ 30 } width={ 150 } />
      </h5>
      <SportList className="my-sports-list" />
      <SocialContainer>
        <h5>
          <Skeleton height={ 30 } width={ 120 } />
        </h5>
        <div>
          <Skeleton height={ 30 } width={ 30 } />
          <Skeleton height={ 30 } width={ 30 } />
          <Skeleton height={ 30 } width={ 30 } />
          <Skeleton height={ 30 } width={ 30 } />
          <Skeleton height={ 30 } width={ 30 } />
        </div>
      </SocialContainer>
      <ButtonsProfile>
        <Skeleton height={ 40 } />
      </ButtonsProfile>
    </Description>
  </Container>
}

export default ProfileLoading
