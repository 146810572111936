import React, { useEffect, useState } from 'react'
import ListingsProvider from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'

import './MyBalance.scss'
import Container from '../../components/Container/Container'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import { useHistory } from 'react-router-dom'
import { MyBalanceSummary } from './MyBalanceSummary'
import { MyBalanceWithdraw } from './MyBalanceWithdraw'
import { MyBalanceWithdrawSuccess } from './MyBalanceWithdrawSuccess'
import paymentService from '../../services/paymentsService'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import MyBalanceLoading from './MyBalanceLoading'
import bankAccountService from '../../services/bankAccountService'

interface BalanceProps {
  unavailable: number
  future: number
  current: number
}

interface HolderProps {
  fullname: string
  taxDocument: {
    number: string
    type: string
  }
}

interface BankAccountProps {
  id: string
  agencyNumber: number
  agencyCheckNumber: number
  accountNumber: number
  accountCheckNumber: number
  bankNumber: number
  bankName: string
  holder: HolderProps
}

const MyBalance = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const [balance, setBalance] = useState<BalanceProps>({
    unavailable: 0,
    future: 0,
    current: 0
  })
  const [bankAccount, setBankAccount] = useState<BankAccountProps>()

  useEffect(
    () => {
      const fetchData = async () => {
        setIsLoading(true)
        try {
          const response = await paymentService.getBalance()

          setBalance({
            unavailable: Number(response.unavailable / 100),
            future: Number(response.future / 100),
            current: Number(response.current / 100)
          })

          const responseBank = await bankAccountService.get()
          setBankAccount(responseBank)
        } catch (e: any) {
          const { isWarn, message, error } = e

          if (error.response.status === 404) {
            return
          }
          isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        } finally {
          setIsLoading(false)
        }
      }

      void fetchData()
    },
    []
  )

  return <div>
    <div className="Header">
      <ListingsProvider >
        <Navbar />
      </ListingsProvider>
      <ReturnHeader
        click={() => {
          currentStep === 1 ? history.goBack() : setCurrentStep(currentStep - 1)
        }}
        title={currentStep === 1 ? 'MEU EXTRATO' : 'RESGATAR' }
        border />
    </div>
    <Container className="my-balance">
      {isLoading && <MyBalanceLoading />}

      {!isLoading && currentStep === 1 &&
        <MyBalanceSummary
          setCurrentStep={setCurrentStep}
          balance={balance}
          bankAccount={bankAccount}
        />
      }
      {!isLoading && currentStep === 2 &&
        <MyBalanceWithdraw
          setCurrentStep={setCurrentStep}
          balance={balance}
          setBalance={setBalance}
          bankAccount={bankAccount}
        />
      }
      {!isLoading && currentStep === 3 &&
        <MyBalanceWithdrawSuccess
          setCurrentStep={setCurrentStep}
        />
      }
    </Container>
  </div>
}

export default MyBalance
