import React, { useContext } from 'react'
import './MyBalance.scss'
import { GlobalContext } from '../../context/GlobalContext'
import { Link } from 'react-router-dom'

export const MyBalanceDataBank: React.FC<any> = ({
  bankAccount
}) => {
  const { userProfile } = useContext(GlobalContext)

  if (bankAccount?.id && userProfile?.taxNumber) {
    return <div className="bankInfoMyBalance">
      <strong>Dados Bancários</strong>
      <div><b>Banco: </b>{bankAccount?.bankName} ({bankAccount?.bank})</div>
      <div>
        <b>Agência: </b>{bankAccount?.branchNumber}
        {bankAccount?.branchCheckDigit && ` (${bankAccount?.branchCheckDigit})`}
        {' / '}
        <b>Conta:</b>{bankAccount?.accountNumber}
        {bankAccount?.accountCheckDigit && ` (${bankAccount?.accountCheckDigit})`}
      </div>
      <div><b>Cpf: </b>{userProfile?.taxNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')} </div>
      <Link to={`/dados-pagamentos/${userProfile?.id}/editar`}>Editar dados bancários</Link>
    </div>
  }
  return <div className="bankMyBalance">
    <strong>Dados Bancários</strong>
    <div>
      <b>Ainda não temos seus dados bancários!</b><br/>
      Precisamos dos seus dados para você resgatar o seu saldo
    </div>
    <Link to={`/dados-pagamentos/${userProfile?.id}/editar`}>Editar dados bancários</Link>
  </div>
}
