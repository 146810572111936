import React from 'react'
import { Link } from 'react-router-dom'

import './ToastMessage.scss'

interface ToastMessageProps {
  label?: string
}

const ToastMessage: React.FC<ToastMessageProps> = ({ label }) => {
  const to = process.env.REACT_APP_LINK_EXTERNAL_CONTACT_US
  return (
    <div id="toast-message">
      <p>{label || 'Ops! Aconteceu algo fora do esperado.'}</p>
      Se precisar de ajuda&nbsp;
      <Link className="toast-link" to={{ pathname: to }} target="_blank">
        fale conosco.
      </Link>
    </div>
  )
}

export default ToastMessage
