import React, { Component } from 'react'
import { type match, type RouteProps } from 'react-router'
import { toast } from 'react-toastify'

import eqipoService from '../../../services/eqipoService'
import orderService from '../../../services/orderService'
import Container from '../../../components/Container/Container'
import Notification from '../../../components/Notification/Notification'
import ListingsProvider from '../../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../../components/Navbar/Navbar'
import '../Eqipo.scss'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'

interface OrderTrackingNumberMatch extends match {
  params: {
    id: string
    slug: string
  }
}

interface OrderTrackingNumberProps extends RouteProps {
  match: OrderTrackingNumberMatch
  values: any
  errors: any
  errorMessage: string
}

interface OrderTrackingNumberState {
  id: string
  slug: string
  values: any
  errors: any
  errorMessage: string
  loading: boolean
  formValid?: boolean
  eqipo?: any
}

class OrderTrackingNumber extends Component<OrderTrackingNumberProps, OrderTrackingNumberState> {
  constructor (props: OrderTrackingNumberProps) {
    super(props)

    this.state = {
      id: '',
      slug: '',
      // showErrors: false,
      values: {
        trackingNumber: ''
      },
      errors: {
        trackingNumber: null
      },
      errorMessage: '',
      loading: true,
      eqipo: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    const { id, slug } = this.props.match.params

    if (id && slug) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.setState({ id, slug }, this.loadData)
    }
  }

  async loadData () {
    try {
      const { id, slug } = this.state
      const eqipo = await eqipoService.getPublicEqipo({ id, slug })
      this.setState({ eqipo, loading: false })
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={ message } />) : toast.error(<ToastMessage />)
      this.setState({ loading: false })
    }
  }

  handleChange (event: React.FormEvent<HTMLInputElement>) {
    const name = event.currentTarget.id
    const { value } = event.currentTarget

    // eslint-disable-next-line react/no-access-state-in-setstate
    const newValues = this.state.values
    newValues[name] = value

    this.setState({ values: newValues }, () => {
      this.validateField(name, value)
    })
  }

  validateForm () {
    let noError = true

    Object.keys(this.state.errors).forEach((item) => {
      noError = noError && this.state.errors[item] === false
    })

    this.setState({ formValid: noError })
  }

  validateField (fieldName: string, value: string) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const fieldValidationErrors = this.state.errors
    const isFilled = value.length > 0

    switch (fieldName) {
      default:
        fieldValidationErrors[fieldName] = isFilled
          ? false
          : 'campo obrigatório'
        break
    }

    this.setState({ errors: fieldValidationErrors }, this.validateForm)
  }

  async handleSubmit (event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    try {
      await orderService.confirmTrackingNumber({
        id: this.state.eqipo.id,
        trackingNumber: this.state.values.trackingNumber,
        status: 'Pedido Enviado'
      })

      this.successRedirect()
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={ message } />) : toast.error(<ToastMessage />)
    }
  }

  successRedirect () {
    // TODO: success redirect
  }

  render () {
    let eqipo

    if (this.state.eqipo) {
      eqipo = <div>
        <form onSubmit={ this.handleSubmit } noValidate>
          <div className="field">
            <label className="label" htmlFor="trackingNumber">
              Número da postagem
            </label>
            <div className="control">
              <input
                id="trackingNumber"
                className={
                  this.state.errors.trackingNumber ? 'is-danger input' : 'input'
                }
                type="text"
                placeholder="Seu e-mail"
                value={ this.state.values.trackingNumber }
                onChange={ this.handleChange }
              />
              <small className="caption has-text-danger">{ this.state.errors.trackingNumber }</small>
            </div>
          </div>
          <div className="field">
            <div className="control has-text-centered">
              <input
                className="button is-primary is-rounded is-medium is-fullwidth"
                type="submit"
                value="Confirmar postagem"
                disabled={ !this.state.formValid }
              />
            </div>
          </div>
        </form>
      </div>
    } else {
      eqipo = <div className="tile is-parent is-12">Ops, esse equipo não existe.</div>
    }

    const loading = (
      <div className="columns is-mobile is-centered">
        <div className="column has-text-centered eqipo-price">
          <p>Carregando...</p>
        </div>
      </div>
    )

    return (
      <div>
        <ListingsProvider >
          <Navbar />
        </ListingsProvider>

        <div className="OrderTrackingNumber">
          <Container>
            <Notification className="is-danger" notificationBody={ this.state.errorMessage } />

            <div className="tile is-ancestor tracking-number-details">
              { this.state.loading ? loading : eqipo }
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default OrderTrackingNumber
