import React, { useContext } from 'react'
import { Redirect, Route, type RouteComponentProps, type RouteProps } from 'react-router-dom'

import { GlobalContext } from '../../context/GlobalContext'

interface ProtectedRouteProps extends RouteProps {
  Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { userProfile } = useContext(GlobalContext)
  const { Component, ...rest } = props

  return (
    <Route { ...rest } render={ (props) =>
      userProfile?.email
        ? <Component { ...props } />
        : <Redirect
          to={ {
            pathname: '/entrar',
            state: {}
          } }
        /> } />
  )
}

export default ProtectedRoute
