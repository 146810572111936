import React from 'react'

interface SwitchFieldProps {
  name: string
  checked: boolean
  labelLeft?: string
  labelRight?: string
  onChange: (name: string, checked: boolean) => void
}

const SwitchField = (props: SwitchFieldProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.name, e.target.checked)
  }
  return (
    <div className="control">
      <span className="switch__span_left">Não</span>
      {props.labelLeft && <span className="switch__label">{props.labelLeft}</span>}
      <label className="switch">
        <input type="checkbox" name={props.name} onChange={handleChange} checked={props.checked} />
        <span className="slider"></span>
      </label>
      {props.labelRight && <span className="switch__label">{props.labelRight}</span>}
      <span className="switch__span_right">Sim</span>
    </div>
  )
}

export default SwitchField
