import * as Yup from 'yup'

export const shippingValidationSchema = Yup.object().shape({
  serviceCode: Yup.string().required('Campo obrigatório'),
  termOfAgreement: Yup.string().required('Campo obrigatório')
})

export const boletoValidationSchema = Yup.object().shape({}) // No validation rules

export const pixValidationSchema = Yup.object().shape({}) // No validation rules

export const creditCardValidationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required('Campo obrigatório'),
  ccHolder: Yup.string().required('Campo obrigatório'),
  ccNumber: Yup.string().required('Campo obrigatório'),
  ccCvc: Yup.string().required('Campo obrigatório'),
  ccExp: Yup.string().required('Campo obrigatório'),
  installments: Yup.object().required('Campo obrigatório'),
  // taxDocument: Yup.string().required('Campo obrigatório').test('cpf-validation', 'CPF inválido', cpfValidation),
  birthDate: Yup.string().required('Campo obrigatório'),
  zipcode: Yup.string().required('Campo obrigatório'),
  street: Yup.string().required('Campo obrigatório'),
  streetNumber: Yup.string().required('Campo obrigatório'),
  district: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório')
})
