import { $publicHttp } from '../../httpService'

interface NotificationParams {
  id: string
}

async function list () {
  const response = await $publicHttp.get('/v1/notifications')
  return response.data
}

async function read ({ id }: NotificationParams) {
  const response = await $publicHttp.put(`/v1/notifications/${id}`)
  return response.data
}

export default {
  list,
  read
}
