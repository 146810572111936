import React, { Component } from 'react'
import { toast } from 'react-toastify'

import notificationService from '../../services/notificationService'
import Container from '../../components/Container/Container'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import './MyNotification.scss'
import ToastMessage from '../../components/ToastMessage/ToastMessage'

interface NotificationProps {
  values: any
  errorMessage: string
}

interface NotificationState {
  values: any
  errorMessage: string
  notifications?: any
}

class MyNotification extends Component<NotificationProps, NotificationState> {
  constructor (props: NotificationProps) {
    super(props)

    this.state = {
      values: null,
      errorMessage: '',
      notifications: null
    }

    void this.listNotifications()
  }

  async listNotifications () {
    try {
      const result = await notificationService.listNotifications()

      this.setState({ notifications: result })
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    }
  }

  async handleRead (id: string) {
    try {
      const result = await notificationService.readNotification({ id })

      this.setState({ notifications: result })
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    }
  }

  render () {
    let notifications

    if (this.state.notifications) {
      notifications = this.state.notifications.map((notification: any, i: any) => {
        return (<div key={i} className="columns is-mobile is-centered">
                    <div className="column has-text-centered notification-element">
                        <a
                            href={notification.href}
                            onClick={async () => {
                              await this.handleRead(notification.id)
                            }}
                            className={notification.readAt ? 'read' : 'unread'}
                        >
                            <span className="notification-subject">{notification.subject}</span><br/>
                            <span className="notification-body">{notification.body}</span>
                        </a>
                    </div>
                </div>)
      })
    }

    return (
            <div>
                <ListingsProvider filter={initialFilter}>
                    <Navbar/>
                </ListingsProvider>

                <div className="Notification">
                    <Container>
                        <div className="tile is-ancestor notification-details">
                            {notifications}
                        </div>
                    </Container>
                </div>
            </div>
    )
  }
}

export default MyNotification
