import React from 'react'
import { ButtonV2 as Button } from '../../../components/Button/ButtonV2'
import { useHistory } from 'react-router-dom'
import { type UserProfile } from '../../../models/User'
import { ButtonsProfile } from './styles'
import { UserShare } from '../../../components/UserShare/UserShare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'

interface EditProfileButtonProps {
  profile: UserProfile
}

const EditProfileButton: React.FC<EditProfileButtonProps> = ({ profile }) => {
  const history = useHistory()

  return <ButtonsProfile>
    <Button
      variant="secondary"
      borderWidth="1px"
      borderColor="#7D829B"
      style={{ fontSize: 12 }}
      onClick={ () => { history.push(`/atleta/${profile?.username}/editar`) } }>
      Editar Perfil
    </Button>
    <Button
      variant="secondary"
      borderWidth="1px"
      borderColor="#7D829B"
      style={{ fontSize: 12 }}
      onClick={ async () => {
        await UserShare('Qip, equipos incríveis para amar o esporte',
          'Vem ver os equipos esportivos que estou vendendo na Qip',
          `https://qip.com.br/atleta/${profile?.username}/`)
      } }>
        <FontAwesomeIcon icon={ faShareNodes } />
    </Button>
  </ButtonsProfile>
}

export default EditProfileButton
