import paymentMethods from './remote/paymentMethods'
import paymentAccounts from './remote/paymentAccount'
import remote from './remote'

async function postOrder (order: any) {
  return await remote.post(order)
}

export default {
  postOrder,
  payments: { ...paymentMethods },
  account: { ...paymentAccounts }
}
