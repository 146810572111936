import React from 'react'
import './CheckoutEqipo.scss'
import { type Listing } from '../../models/Listings'

export interface CheckoutEqipoPros {
  eqipo: Listing
}

const CheckoutEqipo: React.FC<CheckoutEqipoPros> = ({ eqipo }) => {
  return (
  <div className="checkout-eqipo">
    <div className="checkout-eqipo__description">
      <div className="checkout-eqipo__content">
        <h2 className="checkout-eqipo__title">
          {eqipo.title}
        </h2>
        <h4 className="checkout-eqipo__subtitle">{eqipo?.user?.displayName}</h4>
      </div>
      <div className="checkout-eqipo__prices">
        <h2>
          {(eqipo.negotiatedPrices?.[0]?.price || eqipo.price).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })}
        </h2>
        <small>No Pix ou em até 12x</small>
      </div>
    </div>
    <div className="checkout-eqipo__image">
      <img src={eqipo.cover} alt={eqipo.title} />
    </div>
  </div>
  )
}

export default CheckoutEqipo
