import React from 'react'
import './MyBalance.scss'
import Skeleton from 'react-loading-skeleton'

const MyBalanceLoading: React.FC = () => {
  return (
    <div>
      <div className="balanceAvailableHeader">
        <Skeleton width={100} height={25} borderRadius={5}/>
      </div>
      <div className="balanceAvailable">
        <Skeleton width={100} height={50} borderRadius={5}/>
        <div className="balanceAvailableWithdraw">
          <Skeleton width={100} height={50} borderRadius={5}/>
        </div>
      </div>
      <div className="balanceFuture">
        <div className="balanceFutureHeader">
          <Skeleton width={100} height={25} borderRadius={5}/>
        </div>
        <div className="balanceFutureBalance">
          <Skeleton width={100} height={50} borderRadius={5}/>
        </div>
      </div>
      <div>
        <Skeleton width="100%" height={150} borderRadius={5} style={{ marginTop: '30px' }} />
      </div>
    </div>
  )
}

export default MyBalanceLoading
