import React, { useState } from 'react'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import './Help.scss'
import Skeleton from 'react-loading-skeleton'

const Help: React.FC = () => {
  const [loading] = useState(true)

  return (
    <div>
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <div id="chat-container" className={loading ? 'loading' : ''}>
        {loading
          ? <div className="container">
            <Skeleton height={150}/>
          </div>
          : <div id="zendesk-chat-wrapper"></div>
        }
      </div>
    </div>
  )
}

export default Help
