import { $http } from '../../httpService'

export interface FavoritesResponse {
  listings: any[] // TODO: fix the listing declaration
  count: number
  next?: string
}

/**
 * Get my favorites list
 *
 * @returns
 */
async function getFavorites (): Promise<FavoritesResponse> {
  const response = await $http.get('/v1/favorites')
  return response.data
}

/**
 * Add a listing to favorites
 *
 * @param {string} id Listing id
 */
async function addToFavorites (id: string) {
  const response = await $http.post(`/v1/favorites/${id}`)
  return response.data
}

/**
 * Remove a listing from favorites
 *
 * @param {string} id Listing id
 * @returns
 */
async function removeFromFavorites (id: string) {
  const response = await $http.delete(`/v1/favorites/${id}`)
  return response.data
}

export default {
  getFavorites,
  addToFavorites,
  removeFromFavorites
}
