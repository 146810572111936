import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './Eqipo.scss'

export const EqipoLoadingSkeleton = () => (
  <div className="containerEqipo">
    <div className="coverEqipo">
      <Skeleton height={222}/>
    </div>

    <div className="titleEqipo" style={{ display: 'inline' }}>
      <Skeleton height={40} width="100%" style={{ margin: '20px 0' }}/>
    </div>

    <div className="profileEqipo">
      <Skeleton circle={true} height={64} width={64}/>
      <div style={{ marginLeft: '9px' }}>
        <Skeleton height={20} width={100} style={{ marginBottom: '8px' }}/>
        <Skeleton height={15} width={150} style={{ marginBottom: '8px' }}/>
        <Skeleton height={15} width={100}/>
      </div>
    </div>

    <div className="storyEqipo">
      <Skeleton count={10} height={15} style={{ marginBottom: '8px' }}/>
    </div>

    <div className="galleryContainerEqipo">
      <Skeleton height={200}/>
    </div>

    <div className="tagEqipo">
      <Skeleton height={20} width={150}/>
      <div style={{ marginTop: '8px' }}>
        <Skeleton count={3} height={15} width={100} style={{ marginRight: '8px' }}/>
      </div>
    </div>

    <div className="specContainerEqipo">
      <Skeleton height={20} width={150}/>
      <div className="specEqipo" style={{ marginTop: '8px' }}>
        {[...Array(4)].map((_, index) => (
          <div key={index} style={{ display: 'grid', gridTemplateColumns: '113px 1fr', alignItems: 'center', marginTop: '8px' }}>
            <Skeleton height={15} width={100}/>
            <Skeleton height={15} width={150}/>
          </div>
        ))}
      </div>
    </div>

    <div className="priceEqipo">
      <Skeleton height={47} width={200}/>
      <Skeleton height={20} width={100} style={{ marginTop: '8px' }}/>
    </div>

  </div>
)
