import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  > div {
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.uiWhite};
    > h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.qipBlue};
    }
    > p {
      padding: 16px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: ${({ theme }) => theme.colors.qipBlue};
      > strong {
        font-weight: bold;
      }
      > span {
        color: ${({ theme }) => theme.colors.qipCoralLight};
        font-weight: bold;
      }
    }
  }
`

export const ButtonContainer = styled.section`
  padding: 0;
`
