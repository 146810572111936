import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Navbar from '../../components/Navbar/Navbar'
import ProfileMenu from './ProfileMenu/ProfileMenu'
import { GlobalContext } from '../../context/GlobalContext'
import userService from '../../services/userService'
import { type UserProfile } from '../../models/User'

import './Profile.scss'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { type Image } from '../../components/UploadImage/UploadImage'
import ProfileContainer from './ProfileContainer/ProfileContainer'
import EqiposList from '../../components/EqiposList/EqiposList'
import { useAsync } from 'react-async-hook'
import { type Listing } from '../../models/Listings'
import listingsService from '../../services/listingsService'
import { useHistory } from 'react-router-dom'

export interface ProfileProps {
  match: {
    params: {
      username: string
    }
  }
}

export const updateUserAvatar = async (photoURL: string) => {
  try {
    await userService.updatePhotoUserProfile(photoURL)
  } catch (error) {
    const { isWarn, message } = error as any
    isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage/>)
  }
}

const Profile: React.FC<ProfileProps> = ({ match }) => {
  const history = useHistory()
  const params = match.params
  const [userProfile, setProfile] = useState<UserProfile>()
  const { userProfile: contextUserProfile, setUserProfile } = useContext(GlobalContext)

  useEffect(() => {
    if (params.username !== contextUserProfile?.username) {
      const fetchData = async () => {
        try {
          const userProfile = await userService.getUserByUsername(params.username)
          setProfile(userProfile)
        } catch (error) {
          history.push('/not-found')
        }
      }
      void fetchData()
    } else {
      setProfile(contextUserProfile)
    }
  }, [params.username])

  const { result: listings, loading } = useAsync<Listing[]>(
    async () => {
      if (!userProfile) {
        await Promise.resolve()
        return
      }
      return await listingsService.getEnabledListings(userProfile.id!)
    },
    [userProfile]
  )

  const handleChangeAvatar = async (image: Image) => {
    if (image.src) {
      await updateUserAvatar(image.src)
      const newUserProfile = { ...userProfile, photoURL: image.src }
      setProfile(newUserProfile)
      setUserProfile(newUserProfile)
    }
  }

  const isOwner = params.username === contextUserProfile?.username

  return (
    <div id="profile">
      <Navbar/>
      <ProfileContainer
        handleChangeAvatar={handleChangeAvatar}
        profile={userProfile || {}}
        loading={!userProfile}
        isOwner={isOwner}
      />
      {isOwner
        ? <ProfileMenu/>
        : listings?.length
          ? <>
            <h6>EQUIPOS À VENDA</h6>
            <EqiposList listings={listings} isLoading={loading} disableHeader/>
          </>
          : !loading
              ? (
              <div className="qip-adventure">
                <img src="/images/qip-adventure.png" alt='qip-adventure'/>
              </div>
                )
              : null
      }
    </div>
  )
}

export default Profile
