import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import './ConfirmSendReview.scss'

import ListingsProvider from '../../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../../components/Navbar/Navbar'

const linkExternalAboutTrusteeship =
  process.env.REACT_APP_LINK_EXTERNAL_ABOUT_TRUSTEESHIP

const ConfirmSendReview: React.FC = () => {
  const history = useHistory()

  const onClose = () => {
    history.push('/meus-eqipos')
  }

  return (
    <div id="review">
      <div className="menu-review">
        <ListingsProvider >
          <Navbar />
        </ListingsProvider>
      </div>
      <div className="content-review">
        <div className="content-description">
          <strong className="description-1">
            Equipo enviado <br />
            para curadoria
          </strong>
          <p className="description-2">
            Queremos que seu equipo <br />
            esteja lindo no anúncio.
          </p>
          <strong className="description-3">
            Vamos verificar tudo <br /> e se precisarmos, falamos com
            <br /> você por e-mail.
          </strong>
          <p className="description-4">
            Ah, não deixe nossas mensagens <br /> no lixo eletrônico.
          </p>
          <Link className="button-details" to={ { pathname: linkExternalAboutTrusteeship } } target="_blank">
            Ver mais sobre curadoria
          </Link>
          <button className="button-close" onClick={ onClose }>
            Fechar
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmSendReview
