import React from 'react'
import { type UserProfile } from '../../../models/User'
import moment from 'moment/moment'
import { ProfileDetails } from './styles'

interface ProfileInformationProps {
  profile: UserProfile
}

export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

const ProfileInformation: React.FC<ProfileInformationProps> = ({ profile }) => {
  const userCity = `${profile?.address?.currentCity}`
  const userState = `${profile?.address?.currentState}`

  const createTimeUser = profile?.metadata?.creationTime
  const formattedCreateTimeUser = moment(createTimeUser)
  const monthCreateTimeUser = formattedCreateTimeUser.month()
  const yearCreateTimeUser = formattedCreateTimeUser.year()
  const formattedMonthCreateTimeUser = months[monthCreateTimeUser]

  return (
    <ProfileDetails>
      <h3>{ profile.displayName }</h3>
      <small>{ `Sou Qiper desde ${formattedMonthCreateTimeUser?.slice(0, 3)}/${yearCreateTimeUser}` }</small>
      <span>Estou em { userCity }/{ userState }</span>
    </ProfileDetails>
  )
}

export default ProfileInformation
