import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  margin: 1rem;
  flex-direction: column;
`

export const Profile = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-gap: 20px;
  height: 110px;

  img {
    height: 110px !important;
    width: 110px !important;
    border-radius: 100% !important;
  }
`

export const ProfileDetails = styled.div<any>`
  color: ${({ theme }) => theme.colors.qipBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: ${({ shared = true }) => shared && 'center'};
  line-height: 140%;

  > h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  > small {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
  }

  > span {
    font-style: italic;
    font-weight: bold;
    font-size: 13px;
  }
`

export const ButtonsProfile = styled.div`
  height: 40px;
  display: grid;
  grid-template-columns: 115px 40px;
  grid-gap: 14px;
`

export const Description = styled.div`
  margin-top: 16px;

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.qipBlue};
  }
`

export const SportList = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
`

export const Sport = styled.div<any>`
  padding-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.qipCoralLight};
  white-space: nowrap;
`

export const SocialContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;

  > div {
    margin-top: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0;
    
    img {
      height: 25px;
    }
  }
`
