import { cpf } from 'cpf-cnpj-validator'

export const atLeastTwoWords = (value: string) => {
  if (!value) return false
  const words = value.trim().split(/\s+/)
  return words.length >= 2
}

export const cpfValidation = (value: string) => {
  return cpf.isValid(value)
}
