import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './EqipoGallery.scss'
import CustomArrow from '../../assets/images/custom-arrow.svg'
import ClosedButton from '../../assets/images/close-2.svg'

interface IEqipoGallery {
  photos: string[]
  title: string
}

const EqipoGallery = ({ photos, title }: IEqipoGallery) => {
  const [imageSelected, setImageSelected] = useState(0)
  const [fullscreen, setFullscreen] = useState({ open: false, image: '' })

  const onPhotoClick = (index: number) => {
    setFullscreen({ open: true, image: photos[index] })
    setImageSelected(index) // Update the imageSelected state
  }

  return (
        <>
            {fullscreen.open && (
                <div className="fullscreenContainer eqipoGallery">
                    <img
                        className="closedButton"
                        src={ClosedButton}
                        alt="Close Button"
                        onClick={() => { setFullscreen({ open: false, image: '' }) }}
                    />
                    <Swiper
                        className="fullscreenSwiper"
                        modules={[Navigation, Pagination]}
                        navigation={{
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev'
                        }}
                        loop
                        pagination={{ clickable: true }}
                        initialSlide={imageSelected}
                        onSlideChange={(swiper) => { setImageSelected(swiper.activeIndex) }}
                    >
                        {photos.map((photo, index) => (
                            <SwiperSlide key={index}>
                                <img src={photo} alt={title}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-button-prev">
                        <img src={CustomArrow} alt="Previous"/>
                    </div>
                    <div className="swiper-button-next">
                        <img src={CustomArrow} alt="Previous"/>
                    </div>
                </div>
            )}
            <div className="eqipoGallery">
                <Swiper
                    modules={[Navigation, Pagination]}
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev'
                    }}
                    pagination={{ clickable: true }}
                    loop
                    initialSlide={imageSelected}
                    onSlideChange={(swiper) => { setImageSelected(swiper.activeIndex) }}
                >
                    {photos.map((photo, index) => (
                        <SwiperSlide key={index} onClick={() => { onPhotoClick(index) }}>
                            <div className="imageContainer">
                                <div
                                    className="imageBlur"
                                    style={{ backgroundImage: `url(${photo})` }}
                                />
                                <img className="imageFullscreen" src={photo} alt={title}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="swiper-button-prev">
                    <img src={CustomArrow} alt="Previous"/>
                </div>
                <div className="swiper-button-next">
                    <img src={CustomArrow} alt="Previous"/>
                </div>
            </div>
        </>
  )
}

export default EqipoGallery
