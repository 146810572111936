import remote from './remote'

import { type Sport } from '../../models/Sports'
import { type Club } from '../../models/Clubs'
import { type UserProfile } from '../../models/User'

interface UserParams {
  email?: string
  password?: string
  passwordConfirmation?: string
  firstName?: string
  lastName?: string
  displayName?: string
  username?: string
  phoneNumber?: string
  photoURL?: string
  taxNumber?: string
  idNumber?: string
  idIssuer?: string
  idIssuerDate?: string
  birthDate?: string
  address?: string
  address2?: string
  addressNumber?: string
  district?: string
  zipcode?: string
  city?: string
  state?: string
  country?: string
  clubs?: Club[]
  sports?: Sport[]
  fcmToken?: string
}

async function updateUserData ({ sports, clubs, state, city }: UserParams) {
  return await remote.patchUserData({ sports, clubs, state, city })
}

async function getUser () {
  return await remote.get()
}

const getUserByUsername = async (username: string) => {
  return await remote.getUserByUsername(username)
}

const updatePhotoUserProfile = async (photoURL: string) => {
  return await remote.updatePhotoUserProfile(photoURL)
}

const updateUserProfile = async (user: UserProfile | null) => {
  return await remote.updateUserProfile(user)
}
const validEmail = async (email: string) => {
  return await remote.validEmail(email)
}

export default {
  getUser,
  getUserByUsername,
  updateUserData,
  updatePhotoUserProfile,
  updateUserProfile,
  validEmail
}
