import { $http } from '../../httpService'

export interface CorreiosParams {
  cepTo: string
  listingItem: string
}

export interface CEPParams {
  cepTo: string
}

const getCorreios = async (params: CorreiosParams) => {
  const response = await $http.get(
    '/v1/shipment/correios',
    { params }
  )

  return response.data
}

const getCEP = async (params: CEPParams) => {
  const response = await $http.get(
    '/v1/shipment/cep',
    { params }
  )

  return response.data
}

export default { getCorreios, getCEP }
