import React from 'react'

import './NewEqipoImages.scss'
import { type WallpaperImage } from './NewEqipo'
import { useConfirmationService } from '../../../components/ConfirmDialog/ConfirmServiceProvider'
import { ButtonV2 as Button } from '../../../components/Button/ButtonV2'
import UploadImage, { type Image } from '../../../components/UploadImage/UploadImage'
import Cta from '../../../components/Cta/Cta'

interface NewEqipoImagesProps {
  onSaveImages: (images: string[], wallpaper: string) => void
  images: Image[]
  setImages: (payload: any) => void
  wallpaper: WallpaperImage
  setWallpaper: (payload: any) => void
}

const NewEqipoImages = (props: NewEqipoImagesProps) => {
  const { images, setImages, wallpaper, setWallpaper } = props
  const modalConfirm = useConfirmationService()
  const handleChangeGalleryImage = async (
    updatedImage: Image,
    currentImages: Image[]
  ) => {
    const updatedImages = currentImages.map(image =>
      image.id === updatedImage.id ? updatedImage : image
    )
    setImages(updatedImages)
  }

  const handleDeleteGalleryImage = async (
    id: number,
    currentImages: Image[]
  ) => {
    const confirmDialogTitle = 'Tem certeza que deseja apagar a imagem?'
    const shouldDelete = await modalConfirm({ title: confirmDialogTitle })
    if (shouldDelete) {
      const updatedImages = currentImages.map(image =>
        image.id === id ? { id, hasUploaded: false } : image
      )
      setImages(updatedImages)
    }
  }

  const handleChangeWallpaper = async (image: Image) => {
    const src = image.src
    setWallpaper({ ...image, src })
  }

  const handleDeleteWallpaper = async () => {
    const confirmDialogTitle = 'Tem certeza que deseja apagar a imagem?'
    const shouldDelete = await modalConfirm({ title: confirmDialogTitle })
    if (shouldDelete) {
      setWallpaper({ hasUploaded: false })
    }
  }

  const handleSave = (images: Image[], wallpaper: WallpaperImage) => {
    const imageUrls: string[] = images
      .filter(image => !!image.src)
      .map(image => image.src!)

    props.onSaveImages(imageUrls, wallpaper.src!)
  }
  const totalImagesUploaded = images.filter(
    ({ hasUploaded }) => hasUploaded
  ).length
  const canSave =
    wallpaper.hasUploaded &&
    images.filter(image => image.hasUploaded).length >= 3

  return (
    <div className="new-eqipo-images">
      <Cta
        notSpace
        title="INCLUA FOTOS DO EQUIPO"
        content={
          <>
            Escolha as melhores fotos do seu equipo.<br/>
            <div className="secondary">
              <em>Imagens na horizontal com fundo neutro são mais fáceis de visualizar.</em>
            </div>
          </>
        }
      />
      <div className="new-eqipo-images__heading">1. FOTO DESTACADA</div>
      <div className="coverNewEqipoImages">
        <UploadImage
          id={ 0 }
          single
          hasUploaded={ wallpaper.hasUploaded }
          src={ wallpaper.src }
          className="new-eqipo-images__wallpaper"
          preset="wallpaper"
          onFinish={ handleChangeWallpaper }
          onDelete={ handleDeleteWallpaper }
        />
      </div>
      <div className="new-eqipo-images__sub-heading">
        Escolha uma foto bem legal do seu equipo.
        Use o formato paisagem (horizontal), e tente usar um fundo neutro.
      </div>
      <div className="new-eqipo-images__heading">2. GALERIA</div>
      <div className="new-eqipo-images__img-gallery">
        { images
          .filter((_, index) =>
            index < 3
              ? true
              : index === 3 && totalImagesUploaded === 3
                ? true
                : index <= totalImagesUploaded
          )
          .map(({ id, hasUploaded, src }, index) => (
            <UploadImage
              id={ id }
              key={ id }
              hasUploaded={ hasUploaded }
              src={ src }
              isPlus={
                index < 2
                  ? false
                  : index === 2
                    ? true
                    : index <= totalImagesUploaded
              }
              className="new-eqipo-images__uploaded-image"
              onFinish={ async image => { await handleChangeGalleryImage(image, images) } }
              preset="gallery"
              onDelete={ async id => { await handleDeleteGalleryImage(id, images) } }
            />
          )) }
      </div>
      <div className="new-eqipo-images__sub-heading">
        Escolha os melhores ângulos (até 6 fotos. Máximo 6Mb)
      </div>
      <div className="new-eqipo-images__button">
        <Button
          variant="primary"
          onClick={ () => { handleSave(images, wallpaper) } }
          disabled={ !canSave }
        >
          AVANÇAR
        </Button>
      </div>
    </div>
  )
}

export default NewEqipoImages
