import * as Yup from 'yup'

export const newEquipoFormValidationSchema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório').min(10, 'Mínimo de 10 caracteres.'),
  story: Yup.string().required('Campo obrigatório').min(140, 'Mínimo de 140 caracteres.'),
  sports: Yup.array().min(1, 'Campo obrigatório').required('Campo obrigatório'),
  itemCondition: Yup.object().required('Campo obrigatório'),
  size: Yup.object().required('Campo obrigatório'),
  brand: Yup.object().required('Campo obrigatório'),
  price: Yup.number().required('Campo obrigatório'),
  termOfAgreement: Yup.string().required('Campo obrigatório')
})
