import React from 'react'
import UploadImage from '../../../components/UploadImage/UploadImage'
import { type UserProfile } from '../../../models/User'
import emptyProfileAvatar from '../../../assets/images/empty-profile.svg'

interface ProfileAvatarProps {
  shared: boolean
  isOwner: boolean
  profile: UserProfile
  handleChangeAvatar: any
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  shared,
  isOwner,
  profile,
  handleChangeAvatar
}) => {
  return (
    <UploadImage
      isProfile
      shared={ !isOwner }
      disabled={ !isOwner }
      id={ 0 }
      hasUploaded={ profile.photoURL !== '' || profile.photoURL === undefined }
      src={ profile.photoURL || emptyProfileAvatar }
      className="avatar"
      preset="avatar"
      onFinish={ handleChangeAvatar }
    />
  )
}

export default ProfileAvatar
