import { type UserProfile } from './User'
import { type Order } from './Orders'
import { type Room } from './Rooms'

interface NegotiatedPrices {
  buyerId: string
  price: number
}

export interface Listing {
  cover?: string
  title: string
  itemStatus?: string
  price: number
  negotiatedPrices?: [NegotiatedPrices]
  id: string
  slug?: string
  seller: UserProfile
  user?: UserProfile
  photos: string[]
  order?: Order
  room?: Room
}

export interface FullListing extends Listing {
  sports: []
  story: string
  user: UserProfile
  description: string
  itemCondition: ItemCondition
  itemStatus: string
  userId: string
  size?: ListingSize
  brand?: ListingBrand
  yearOfManufacture?: string
  model?: string
  material?: string
  capacity?: string
  hasInvoice?: boolean
  hasWarranty?: boolean
}

export enum ItemCondition {
  AS_NEW = 'as-new',
  LIGHTLY_USED = 'lightly-used',
  USED = 'used',
}

export enum ListingStatus {
  DRAFT = 'draft',
  WAITING_REVIEW = 'waitingReview',
  PROFILE_INCOMPLETE = 'profileIncomplete',
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  SOLD = 'sold',
  RECEIVED = 'received',
}

export interface ListingSize {
  id: string
  label: string
}

export interface ListingBrand {
  id: string
  name: string
}
