import styled from 'styled-components'

export const OtherAuthContainer = styled.div`
  margin-top: 56px;
  margin-bottom: 40px;
`

export const GoogleButton = styled.a`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.uiBlack};
`

export const ContainerSignUp = styled.div`
  > section {
    padding: 56px 0 14px;
  }
`
