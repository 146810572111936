import React, { useContext } from 'react'
import slugify from 'slugify'

import './EqipoCard.scss'
import { type Listing } from '../../models/Listings'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'

interface IEqipoCard extends Listing {
  typeEqipo?: 'grid' | 'list' | null
  isAdmin?: boolean
  status?: string
  onClick?: () => any
}

const statusLabel: Record<string, string> = {
  waitingReview: 'Em Aprovação',
  profileIncomplete: 'Perfil vendedor pendente',
  draft: 'Rascunho',
  disabled: 'Desabilitado',
  bought: 'Comprei',
  sold: 'Vendido',
  new: 'Pagamento Pendente',
  authorization: 'Aguardando entrega',
  shipped: 'Aguardando Recepcão'
}

const EqipoCard: React.FC<IEqipoCard> = ({ typeEqipo = 'grid', isAdmin = false, ...props }: IEqipoCard) => {
  const isWaitingReview = String(props.status) === 'waitingReview'
  const isEnabled = String(props.status) === 'enabled'
  const { userProfile } = useContext(GlobalContext)
  const hasUserProfile = !!userProfile

  return (
    <Link to={
      isAdmin && isWaitingReview
        ? `/eqipo/${props.id}`
        : isEnabled
          ? `/eqipo/${props.id}/${slugify(props.title)}`
          : '#'
    } onClick={(e) => {
      if (!(isAdmin && isWaitingReview) && !isEnabled) {
        e.preventDefault()
      }
      props.onClick?.()
    }}>
      <div className={`${typeEqipo === 'grid' ? 'eqipo-card__grid' : 'eqipo-card__list'} eqipo-card`}>
        <div className="eqipo-card__header">
          <img src={props.cover} alt={props.title}/>
        </div>
        <div className="eqipo-card__content">
          {props.price
            ? <span className="eqipo-card__price">
              {!hasUserProfile
                ? <span className="eqipo-card__blur">
              R$ 00.00
            </span>
                : <span>
              {props.price.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
              }
          </span>
            : null
          }
          {props.status && statusLabel[props.status] &&
            <div className="eqipo-card__status">{props.status && statusLabel[props.status]}</div>
          }
          <h4 className="eqipo-card__title">
            {props.title}
          </h4>
          {props.seller &&
            <p className="eqipo-card__seller">
              {props.seller.displayName}
            </p>}
        </div>
      </div>
    </Link>
  )
}

export default EqipoCard
