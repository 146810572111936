import React, { createContext, type ReactNode, useContext, useState } from 'react'

interface SignUp {
  displayName: string
  email: string
  phoneNumber: string
  password: string
  passwordConfirmation: string
  stravaId?: string
  photoURL?: string
}

interface SignUpContextType {
  signUp: SignUp
  updateSignUp: (newData: Partial<SignUp>) => void
}

const SignUpContext = createContext<SignUpContextType | undefined>(undefined)

export const useSignUp = () => {
  const context = useContext(SignUpContext)
  if (context === undefined) {
    throw new Error('useSignUp must be used within a SignUpProvider')
  }
  return context
}

interface SignUpProviderProps {
  children: ReactNode
}

export const SignUpProvider: React.FC<SignUpProviderProps> = ({ children }) => {
  const [signUp, setSignUp] = useState<SignUp>({
    displayName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: ''
  })

  const updateSignUp = (newData: Partial<SignUp>) => {
    setSignUp((prevData) => ({ ...prevData, ...newData }))
  }

  return (
    <SignUpContext.Provider value={{ signUp, updateSignUp }}>
      {children}
    </SignUpContext.Provider>
  )
}
