import styled, { css, keyframes } from 'styled-components'
import theme from '../../global/theme'
import React from 'react'

const spinAround = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const LoadingSpinner = styled.div<{ variant: Variant }>`
  animation: ${spinAround} 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;

  ${({ variant }) =>
    variant === 'secondaryNoBorder' &&
    css`
      border-color: ${theme.colors.qipCoralLight} ${theme.colors.qipCoralLight} transparent transparent
    `}
`

const variants = {
  primary: {
    background: theme.colors.qipCoralLight,
    color: theme.colors.uiWhite,
    border: theme.colors.qipCoralLight
  },
  secondary: {
    background: theme.colors.uiWhite,
    color: theme.colors.qipCoralLight,
    border: theme.colors.qipCoralLight
  },
  secondaryNoBorder: {
    background: theme.colors.uiWhite,
    color: theme.colors.qipCoralLight,
    border: theme.colors.uiWhite
  },
  blue: {
    background: theme.colors.qipBlueLight,
    color: theme.colors.uiBackgroundLight,
    border: theme.colors.qipBlueLight
  },
  blueSecondary: {
    background: theme.colors.uiWhite,
    color: theme.colors.qipBlueLight,
    border: theme.colors.qipBlueLight
  },
  blueNoBorder: {
    background: theme.colors.uiWhite,
    color: theme.colors.qipBlueLight,
    border: theme.colors.uiWhite
  },
  softBlue: {
    background: theme.colors.uiWhite,
    color: theme.colors.qipSoftBlue,
    border: theme.colors.uiWhite
  }
}

export type Variant = 'primary' | 'secondary' | 'blue' | 'blueSecondary' | 'blueNoBorder' | 'secondaryNoBorder' | 'softBlue'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant
  background?: string
  color?: string
  borderColor?: string
  borderRadius?: string
  borderWidth?: string
  width?: string
  height?: string
  margin?: string
  padding?: string
  isLoading?: boolean
}

const ButtonBase = styled.button<IButton>`
  
  color: ${({ color, variant = 'primary' as Variant }) => color || variants[variant].color};
  background: ${({ background, variant = 'primary' as Variant }) => background || variants[variant].background};
  border: ${({ borderWidth = '2px' }) => borderWidth} solid ${({
                                                               borderColor,
                                                               variant = 'primary' as Variant
                                                             }) => borderColor || variants[variant].border};
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '40px' }) => height};
  border-radius: ${({ borderRadius = '0px' }) => borderRadius};
  font-weight:  ${({ variant = 'primary' }) => variant === 'blueSecondary' ? 700 : 900};
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: ${({ margin = '10px 0' }) => margin};
  padding: ${({ padding = '0 10px' }) => padding};

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  ${({ disabled }) => disabled && css`
    opacity: 0.2;
  `}

  ${({ variant }) =>
    variant === 'secondaryNoBorder' && css`
      &:active,
      &.is-active,
      &:focus,
      &.is-focused,
      &:hover,
      &.is-hovered {
        background: ${theme.colors.uiWhite};
        border-color: ${theme.colors.uiWhite};
        color: ${theme.colors.qipCoralLight};
      }`
  }

  ${({ variant }) =>
    variant === 'blue' && css`
    &:active,
    &.is-active,
    &:focus,
    &.is-focused,
    &:hover,
    &.is-hovered {
      color: ${theme.colors.uiWhite};
    }
  `}
`

export const ButtonV2 = ({ isLoading, children, variant = 'primary', ...rest }: IButton & { children: React.ReactNode }) => (
  <ButtonBase
    className={
      `button 
   ${isLoading ? 'is-loading' : ''}
   ${variant === 'primary' ? 'is-primary' : variant}`
    }
    variant={variant}
    disabled={isLoading}
    {...rest}
  >
    {isLoading ? <LoadingSpinner variant={variant}/> : children}
  </ButtonBase>
)
