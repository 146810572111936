import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

import firebaseService, { getFirebaseErrorHandler } from '../firebaseService'
import remote from './remote'
import { type Club } from '../../models/Clubs'
import { type Sport } from '../../models/Sports'

// Configure Firebase.
const firebaseApp = firebaseService.firebaseApp()

function providersInit () {
  firebase.auth().languageCode = 'pt'

  // eslint-disable-next-line new-cap
  const googleProvider = new firebase.auth.GoogleAuthProvider()
  googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile')
  googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
  googleProvider.addScope('https://www.googleapis.com/auth/user.birthday.read')

  const facebookProvider = new firebase.auth.FacebookAuthProvider()
  facebookProvider.addScope('email')

  return {
    googleProvider,
    facebookProvider
  }
}

const { googleProvider, facebookProvider } = providersInit()

let currentUser = null
let token = null

async function signInSocialSocialProvider (
  provider: firebase.auth.AuthProvider
) {
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

    const result = await firebaseApp.auth().signInWithPopup(provider)

    // This gives you a Google Access Token. You can use it to access the Google API.
    token = result.credential!.providerId
    currentUser = result.user

    return { token, currentUser }
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

async function signInCustomProvider (provider: string) {
  try {
    const { redirectUrl } = await remote.registerCustom(provider)
    window.location.href = redirectUrl
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

async function signInWithCustomToken (token: string) {
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    return await firebaseApp.auth().signInWithCustomToken(token)
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

async function signInSocial (provider: firebase.auth.AuthProvider | string) {
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  switch (provider.toString()) {
    case 'google':
      return await signInSocialSocialProvider(googleProvider)
    case 'facebook':
      return await signInSocialSocialProvider(facebookProvider)
    case 'strava': {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      await signInCustomProvider(provider.toString())
      return
    }
    default:
      return await Promise.reject({
        isWarn: false,
        message: 'Ops! Aconteceu algo fora do esperado.'
      })
  }
}

async function signInEmailPassword ({
  email,
  password
}: {
  email: string
  password: string
}) {
  try {
    const result = await firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)

    currentUser = result.user
    const userJSON: any = currentUser?.toJSON()
    const token = userJSON.stsTokenManager.accessToken

    return { token, currentUser }
  } catch (error: any) {
    const { message, isWarn, code } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message,
      code
    })
  }
}

async function linkEmailPassword ({
  email,
  password
}: {
  email: string
  password: string
}) {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    )
    await firebaseApp.auth().currentUser!.linkWithCredential(credential)
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

interface RegisterEmailParams {
  displayName: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  sports: Sport[]
  clubs: Club[]
  state: string
  city: string
}

async function registerUser ({
  displayName,
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  sports,
  clubs,
  state,
  city
}: RegisterEmailParams) {
  return await remote.register({
    displayName,
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    sports,
    clubs,
    state,
    city
  })
}

interface EditParams {
  displayName: string
  email: string
  uid: string
  phoneNumber: string
}

async function editUser ({
  displayName,
  email,
  uid,
  phoneNumber
}: EditParams) {
  return await remote.edit({
    displayName,
    email,
    uid,
    phoneNumber
  })
}

async function getCurrentUser (): Promise<{
  user: firebase.User
  token: string
  claims: any
}> {
  const user = firebaseApp.auth().currentUser

  if (!user) {
    return await new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Firebase authentication timed out'))
      }, 3000)

      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(getCurrentUser())
        }
        clearTimeout(timeout)
        reject(new Error('No authenticated user found'))
      })
    })
  }
  const userJSON: any = user.toJSON()
  const { claims } = await user.getIdTokenResult()
  const token = userJSON.stsTokenManager.accessToken

  return { user, token, claims }
}

async function signOut () {
  try {
    await firebaseApp.auth().signOut()
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

async function verifyEmail (email: string) {
  try {
    return await remote.verifyEmail(email)
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

const sendPasswordResetEmail = async (email: string) => {
  return await remote.forgetPassword(email)
}

const confirmPasswordReset = async (code: string, newPassword: string) => {
  try {
    await firebaseApp.auth().verifyPasswordResetCode(code)
    await firebaseApp.auth().confirmPasswordReset(code, newPassword)
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

const updatePhotoUserProfile = async (photoURL: string) => {
  try {
    await firebaseApp.auth().currentUser?.updateProfile({
      photoURL
    })
  } catch (error: any) {
    const { message, isWarn } = getFirebaseErrorHandler(error.code)

    return await Promise.reject({
      isWarn,
      message
    })
  }
}

export default {
  verifyEmail,
  signInSocial,
  getCurrentUser,
  registerUser,
  signInEmailPassword,
  signInWithCustomToken,
  linkEmailPassword,
  editUser,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePhotoUserProfile,
  validateCustomCode: remote.validateCustomCode,
  getInfoStrava: remote.getInfoStrava,
  registerUserStrava: remote.registerUserStrava
}
