import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {}
  .css-yk16xz-control  {
    border-radius: 0px !important;
    border-color: #E0E0E0 !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-tlfecz-indicatorContainer {
    color: #616161 !important;
  }
  .css-1gtu0rj-indicatorContainer {
    color: #616161 !important;
  }
`

export default GlobalStyle
