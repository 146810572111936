import React from 'react'
import { Link } from 'react-router-dom'

interface CheckboxFieldProps {
  name?: string
  label?: string | any
  labelLink?: string | any
  link?: string
  checked: boolean
  notUpperCase?: boolean
  required?: boolean
  value?: boolean
  onChange?: (name: string, value: string) => void
}

const CheckboxField = (props: CheckboxFieldProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e.target.name, e.target.value)
    }
  }

  return (
    <div className="control">
      <label className="checkbox">
        <input
          type="checkbox"
          name={props.name}
          required={props.required || false}
          onChange={handleChange}
          checked={props.checked}
          value={`${props.value}`}
        />
        <span></span>
        <p className="check-box-content">
          <strong style={{ textTransform: props.notUpperCase || false ? 'unset' : 'uppercase', fontWeight: props.notUpperCase || false ? 'normal' : 'bold' }} >{props.label}</strong>
          <br/>
          <Link className="toast-link" to={{ pathname: props.link }} target="_blank" >
            {props.labelLink}
          </Link>
        </p>
      </label>
    </div>
  )
}

export default CheckboxField
