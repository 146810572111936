import React, { useContext, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import authService from '../../services/authService/index'
import userService from '../../services/userService/index'

import { GlobalContext } from '../../context/GlobalContext'
import Navbar from '../../components/Navbar/Navbar'
import FormField from '../../components/FormComponents/FormField'
import TextField from '../../components/FormComponents/TextField'
import * as yup from 'yup'

import './Auth.scss'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { OtherAuthContainer } from './styles'
import { ButtonStrava } from '../../components/ButtonStrava/ButtonStrava'

import stravaIcon from '../../assets/images/strava.svg'
import * as amplitude from '@amplitude/analytics-browser'
import { Identify } from '@amplitude/analytics-browser'

const schema = yup.object().shape({ email: yup.string().email().required() })

const cleanValues = { email: '', password: '' }

const SignIn = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { setUserProfile } = useContext(GlobalContext)
  const [signin, setSignin] = useState(cleanValues)
  const [isStravaLoading, setIsStravaLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(cleanValues)
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)

  const resetForm = () => {
    setSignin(cleanValues)
    setErrors(cleanValues)
  }

  const successRedirect = async () => {
    // const query = new URLSearchParams(search);
    // const eqipoId = query.get('eqipo');
    // const slug = query.get('slug');
    const userProfile = await userService.getUser()

    const identify = new Identify()
    identify.set('email', userProfile.email)
    amplitude.identify(identify)

    // if (eqipoId) {
    //   if (slug) {
    //     history.push(`/eqipo/${ eqipoId }/${ slug }`);
    //   } else {
    //     const { id } = await messageService.createRoom(
    //       {
    //         listingId: eqipoId,
    //         message: '',
    //       });
    //     history.push(`/mensagem/${ id }/`);
    //   }
    // } else {
    //   setUserProfile(userProfile);
    //   history.push(`/`);
    // }

    setUserProfile(userProfile)
    history.push('/')
    resetForm()
  }

  const signStrava = async () => {
    try {
      setIsStravaLoading(true)
      const user = await authService.signInSocial('strava')
      if (user) {
        await successRedirect()
      }
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      setIsStravaLoading(false)
    }
  }

  const handleChange = (name: string, value: string) => {
    setSignin({ ...signin, [name]: value })
  }

  const emailComplete = async (event: React.FormEvent) => {
    try {
      event.preventDefault()
      setIsLoading(true)
      const valid = await schema.isValid({ email: signin.email })

      setPasswordIsVisible(false)

      if (!valid) {
        setErrors({ ...errors, email: signin.email ? 'Email inválido' : 'Campo obrigatório' })
        setIsLoading(false)
      } else {
        const { alreadyExists } = await authService.verifyEmail(signin.email.toLowerCase())
        if (alreadyExists) {
          setPasswordIsVisible(true)
          setIsLoading(false)
        } else {
          const encodedEmail = encodeURIComponent(signin.email.toLowerCase().replace(/\./g, '-dot-'))
          const query = new URLSearchParams(search)
          const eqipoId = query.get('eqipo')
          const slug = query.get('slug')

          if (eqipoId !== null) {
            if (slug) {
              history.push(`/cadastrar?email=${encodedEmail}&eqipo=${eqipoId}&slug=${slug}`)
            } else {
              history.push(`/cadastrar?email=${encodedEmail}&eqipo=${eqipoId}`)
            }
          } else {
            history.push(`/cadastrar?email=${encodedEmail}`)
          }
        }
      }
    } catch (error: any) {
      setIsLoading(false)
      return (error.isWarn ? toast.warn(<ToastMessage label={error.message}/>) : toast.error(<ToastMessage />))
    }
  }

  const signInForm = async (event: React.FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    if (signin.password) {
      try {
        const { email, password } = signin
        await authService.signInEmailPassword(
          {
            email,
            password
          })

        await successRedirect()
      } catch (error: any) {
        setIsLoading(false)
        return (error.isWarn ? toast.warn(<ToastMessage label={error.message}/>) : toast.error(<ToastMessage />))
      }
    } else {
      setErrors({ ...errors, password: 'Campo obrigatório' })
      setIsLoading(false)
    }
  }

  return (
        <div>
            <Navbar noButtons/>
            <div className="signin">
                <div className="titleContainer">
                    <h1>QUE BOM QUE VOCÊ VEIO!</h1>
                </div>
                <OtherAuthContainer>
                    <ButtonStrava onClick={signStrava} isLoading={isStravaLoading}>
                        Entrar com <img src={stravaIcon} alt="Strava"/>
                    </ButtonStrava>
                </OtherAuthContainer>
                <form
                    onSubmit={async e => passwordIsVisible ? await signInForm(e) : await emailComplete(e)}
                    noValidate
                >
                    <FormField
                        name="email"
                        label="ENTRAR / CADASTRAR COM EMAIL"
                        validation={errors.email}
                        required
                    >
                        <TextField
                            name="email"
                            type="text"
                            placeholder="Digite seu e-mail"
                            value={signin.email}
                            onChange={handleChange}
                            autoComplete="username"
                            disabled={isLoading}
                        />
                    </FormField>
                    {passwordIsVisible && (
                        <FormField
                            name="password"
                            label="SENHA"
                            validation={errors.password}
                            required
                        >
                            <TextField
                                name="password"
                                placeholder="Digite sua senha"
                                type="password"
                                value={signin.password}
                                onChange={handleChange}
                                autoComplete="current-password"
                                disabled={isLoading}
                            />
                        </FormField>
                    )}
                    <Button
                        variant="primary"
                        type="button"
                        margin="16px 0px 0px "
                        onClick={async e => passwordIsVisible ? await signInForm(e) : await emailComplete(e)}
                        isLoading={isLoading}
                    >
                        CONTINUAR
                    </Button>
                    {passwordIsVisible && (
                        <div className="alignCenter" style={{ marginTop: '16px' }}>
                            <Link
                              to={{
                                pathname: '/resetar-senha',
                                state: { email: signin.email }
                              }}
                              className="resetPassword">
                                Esqueci minha senha
                            </Link>
                        </div>
                    )}
                </form>
            </div>
        </div>
  )
}

export default SignIn
