import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import './PurchaseComplete.scss'

import ListingsProvider, { initialFilter } from '../../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../../components/Navbar/Navbar'
import Cta from '../../../components/Cta/Cta'
import messageService from '../../../services/messageService'
import { toast } from 'react-toastify'
import ReturnHeader from '../../../components/ReturnHeader/ReturnHeader'
import { ButtonV2 } from '../../../components/Button/ButtonV2'

interface ParamsProps {
  paymentMethod: string
}

interface LocationState {
  listingId: string
  sellerName: string
  barecode: string
  barecodePDF: string
  pixQrCode: string
  pixUrl: string
  orderStatus: string
}

const PurchaseComplete: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pixQrCode, pixUrl, barecode, barecodePDF, sellerName, listingId, orderStatus } = location.state as LocationState || {}

  const { paymentMethod } = useParams<ParamsProps>()

  const redirectToChat = async ({ listingId }: any) => {
    try {
      const { id } = await messageService.createRoom({
        listingId,
        message: ''
      })
      history.push(`/mensagem/${id}`)
    } catch (error) {
      toast.error('Ops! Aconteceu algo fora do esperado.')
    }
  }

  const redirectToCheckout = async ({ listingId }: any) => {
    try {
      history.push('/checkout', { listingId })
    } catch (error) {
      toast.error('Ops! Aconteceu algo fora do esperado.')
    }
  }

  return (
    <>
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      {orderStatus === 'paymentIssue' &&
        <div style={{ marginTop: '30px' }}>
          <ReturnHeader title="Erro no pagamento" border noIcon/>
          <Cta
            titleColor="#FF6E5C"
            title={
              <>⚠️&nbsp;&nbsp;pagamento recusado&nbsp;&nbsp;⚠️</>
            }
            isButton
            buttonProps={{
              title: 'Tentar novamente',
              variant: 'blue',
              click: async () => {
                await redirectToCheckout({ listingId })
              }
            }}
            content={
              <>
                Por alguma razão não conseguimos processar seu pagamento e ele foi cancelado.<br/><br/>
                Revise as informações e tente novamente!
              </>
            }
          />
        </div>
      }

      {orderStatus !== 'paymentIssue' && paymentMethod === 'boleto' &&
        <div className="boletoContainer">
          <h4>PEDIDO REALIZADO</h4>
          <div className="boletoDetail">
            <div className="barecode">
              {barecode}
            </div>
            <ButtonV2 variant="secondary"
                      className="copyBarcode"
                      onClick={async () => {
                        await navigator.clipboard.writeText(barecode)
                        // TODO info
                        toast.warn('Codigo do boleto copiado')
                      }}>
              Copiar Código de barras
            </ButtonV2>
            <a target="_blank" className="linkToBoletoPDF" href={barecodePDF} rel="noreferrer">ver boleto</a>
            <p>Este boleto expira em 3 dias</p>
            <div className="boletoInfo">
              <p>
                Assim que recebermos a confirmação do pagamento, te avisamos por e-mail.<br/>
                Enquanto isso, você pode combinar a entrega do seu equipo com <strong>{sellerName}.</strong>
                <br/><br/>
                <strong>USE APENAS O NOSSO CHAT.</strong>
                <br/><br/>
                Somente dessa forma podemos garantir a segurança da transação, combinado?
              </p>
              <ButtonV2 variant="blue"
                        onClick={async () => {
                          await redirectToChat({ listingId })
                        }}>
                Combinar entrega
              </ButtonV2>
            </div>
          </div>
        </div>
      }
      {orderStatus !== 'paymentIssue' && paymentMethod === 'pix' &&
        <div className="pixContainer">
          <h4>PEDIDO REALIZADO</h4>
          <div className="pixDetail">
            <div className="pixPng">
              <img src={pixUrl} alt="pix-png"/>
            </div>
            <div className="pixQrCode">
              {pixQrCode}
            </div>
            <ButtonV2 variant="secondary"
                      className="copyPixQrCode"
                      onClick={async () => {
                        await navigator.clipboard.writeText(pixQrCode)
                        // TODO info
                        toast.warn('Codigo do Pix copiado')
                      }}>
              Copiar Código do PIX
            </ButtonV2>
            <p>Este Pix expira em 20 minutos</p>
            <div className="pixInfo">
              <p>
                Assim que recebermos a confirmação do pagamento, te avisamos por e-mail.<br/>
                Enquanto isso, você pode combinar a entrega do seu equipo com <strong>{sellerName}.</strong>
                <br/><br/>
                <strong>USE APENAS O NOSSO CHAT.</strong>
                <br/><br/>
                Somente dessa forma podemos garantir a segurança da transação, combinado?
              </p>
              <ButtonV2 variant="blue"
                        className="toMessageRoom"
                        onClick={async () => {
                          await redirectToChat({ listingId })
                        }}>
                Combinar entrega
              </ButtonV2>
            </div>
          </div>
        </div>
      }
      {orderStatus !== 'paymentIssue' && paymentMethod === 'credit_card' &&
        <div style={{ marginTop: '30px' }}>
          <ReturnHeader title="COMPRA CONCLUÍDA " border noIcon/>
          <Cta
            titleColor="#FF6E5C"
            title="Seu pagamento foi aprovado 🎉"
            isButton
            buttonProps={{
              title: 'Combinar Entrega',
              variant: 'blue',
              click: async () => {
                await redirectToChat({ listingId })
              }
            }}
            content={
              <>
                Agora você precisa combinar a entrega do seu
                <br/>
                equipo com{' '} <b>{sellerName}.</b>
                <br/><br/>
                <b>USE APENAS O NOSSO CHAT.</b>
                <br/><br/>
                Somente dessa forma podemos garantir
                <br/>
                a segurança da transação, combinado?
                <br/>
              </>
            }
          />
        </div>
      }
    </>
  )
}

export default PurchaseComplete
