import { $http } from '../../httpService'

const createPaymentAccount = async () => {
  const response = await $http.post(
    '/v1/payments/account'
  )
  return response.data
}

const getPaymentAccount = async () => {
  const response = await $http.get(
    '/v1/payments/account'
  )
  return response.data
}

export default { createPaymentAccount, getPaymentAccount }
