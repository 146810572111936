import React, { useContext, useEffect } from 'react'
import Container from '../../components/Container/Container'
import { type match, type RouteProps, useHistory } from 'react-router-dom'

import authService from '../../services/authService'
import Navbar from '../../components/Navbar/Navbar'
import { GlobalContext } from '../../context/GlobalContext'
import userService from '../../services/userService'
import { useSignUp } from '../../context/SignUpContext'

interface AuthCodeMatch extends match {
  params: {
    provider: ''
  }
}

interface AuthCodeProps extends RouteProps {
  match: AuthCodeMatch
}

const AuthCode = (props: AuthCodeProps) => {
  const history = useHistory()
  const { setUserProfile } = useContext(GlobalContext)
  const { updateSignUp } = useSignUp()

  useEffect(
    () => {
      async function validateCode () {
        try {
          const code = new URLSearchParams(props.location!.search).get('code')
          const userStravaInfo = await authService.getInfoStrava(code)
          // const user = {
          //   displayName: `${userStravaInfo.firstname} ${userStravaInfo.lastname}`,
          //   firstName: userStravaInfo.firstname,
          //   lastName: userStravaInfo.lastname,
          //   photoURL: userStravaInfo.profile,
          //   username: userStravaInfo.username,
          //   stravaId: userStravaInfo.id,
          //   address: {
          //     currentState: '',
          //     currentCity: '',
          //   },
          // };

          if (userStravaInfo.token) {
            await authService.signInWithCustomToken(userStravaInfo.token)
            const userProfile = await userService.getUser()
            setUserProfile(userProfile)
            history.push('/')
          } else {
            updateSignUp({
              stravaId: userStravaInfo.id,
              displayName: `${userStravaInfo.firstname} ${userStravaInfo.lastname}`,
              photoURL: userStravaInfo.profile
            })
            history.push('/cadastrar/strava')
          }
        } catch (error) {
        //   const { isWarn, message } = error as any
        //   (isWarn ? toast.warn(<ToastMessage label={message} />) : toast.error(<ToastMessage />));
        }
      }

      void validateCode()
    },
    [props, history]
  )

  return (
    <div className="Page AuthCode">
      <Navbar />
      <Container className="text-hero">
        <progress className="progress is-info is-primary" max="100">15%</progress>
      </Container>
    </div>
  )
}

export default AuthCode
