import React from 'react'
import { type UserProfile } from '../../../models/User'
import { Sport, SportList } from './styles'

interface ProfileSportsProps {
  profile: UserProfile
}

const ProfileSports: React.FC<ProfileSportsProps> = ({ profile }) => {
  return (
    <>
      <h5>Esportes que pratico</h5>
      <SportList className="my-sports-list">
        { profile?.sports?.map((s) => (
          <Sport key={ s.id }>{ s.title.replace(' - ', ' ') }</Sport>
        )) }
      </SportList>
    </>
  )
}

export default ProfileSports
