import React, { useContext } from 'react'
import { HeaderLogged } from '../styles'
import { Header } from '../../../components/Filter/styles'
import { GlobalContext } from '../../../context/GlobalContext'

const ListingHeader = ({ isFilterApplied }: any) => {
  const { userProfile } = useContext(GlobalContext)
  const firstName = userProfile?.displayName?.toLowerCase().split(' ')[0]

  if (userProfile && !isFilterApplied) {
    return <HeaderLogged>
      <strong>Que bom te ver aqui,&nbsp;</strong>{ firstName }
    </HeaderLogged>
  } else {
    return <Header>Veja esses equipos incríveis</Header>
  }
}

export default ListingHeader
