import { DetailsContainer, PriceContainer } from './styles'
import CleaveField from '../../../components/FormComponents/CleaveField'
import { ButtonV2 as Button } from '../../../components/Button/ButtonV2'
import slugify from 'slugify'
import editIcon from '../../../assets/images/edit-orange.svg'
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import listingsService from '../../../services/listingsService'
import { toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'
import { ListingStatus } from '../../../models/Listings'
import { Link } from 'react-router-dom'

interface MessagesRoomHeaderProps {
  room: any
  recipient: any
  price: number
  originalPrice: number
  setPrice: any
  priceWithMask: string
  setPriceWithMask: any
  history: any
}

export const MessagesRoomHeader = (
  {
    room,
    recipient,
    price,
    originalPrice,
    setPrice,
    priceWithMask,
    setPriceWithMask,
    history
  }: MessagesRoomHeaderProps) => {
  const { userProfile } = useContext(GlobalContext)
  const [editingPrice, setEditingPrice] = useState(false)
  const isBuyer = userProfile?.id === room?.order?.buyer?.id
  const isSeller = userProfile?.id === room?.listing.userId || room?.order?.seller?.id === userProfile?.id

  const editListing = async () => {
    try {
      await listingsService.editListingPrice(room.listing.id, { buyerId: recipient!.id!, price })
      setPrice(price)
      setPriceWithMask(priceWithMask)
      setEditingPrice(false)
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    }
  }

  const SoldBanner: React.FC = () => {
    if (room?.order?.orderStatus === 'received' && isSeller) {
      return (
        <div className="alreadySoldMessage">
          <div>
            <span>VOCÊ VENDEU ESSE EQIPO</span>
            <Link to="/eqipo/novo">Qual será seu próximo equipo para venda?</Link>
          </div>
        </div>
      )
    }
    if (room?.order?.orderStatus === 'received' && room?.order?.buyer?.id === userProfile?.id) {
      return (
        <div className="alreadySoldMessage">
          <div>
          <span>
                COMPREI EM{' '}
            {room?.order?.createdAt &&
              Intl.DateTimeFormat('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }).format(new Date(room?.order?.createdAt))}
              </span>
          </div>
        </div>
      )
    }
    if (!room?.order && room?.listing.itemStatus === ListingStatus.SOLD) {
      return (
        <div className="alreadySoldMessage">
          <div>
            <span>ESSE EQUIPO NÃO ESTÁ MAIS À VENDA</span>
            <Link to="/">Encontre um Equipo Incrível pra você</Link>
          </div>
        </div>
      )
    }
    return null
  }

  const OrderStatusBanner: React.FC = () => {
    if (room?.order?.orderStatus === 'authorization' && isBuyer) {
      return <div className="order-status-banner">
        <p>Aguardando entrega</p>
      </div>
    }
    if (room?.order?.orderStatus === 'shipped' && isSeller) {
      return <div className="order-status-banner">
        <p>Aguardando recebimento</p>
      </div>
    }
    if (room?.order?.orderStatus === 'new') {
      return <div className="order-status-banner">
        <p>Aguardando pagamento</p>
      </div>
    }
    return null
  }

  const ActionButton: React.FC = () => {
    const hasOrder = room?.order
    const isSeller = userProfile?.id === room?.listing?.user?.id
    const isListingSold = room?.listing?.itemStatus === ListingStatus.SOLD

    if (!hasOrder && !isSeller && !isListingSold) {
      return <Button
        variant="secondary"
        type="button"
        width="auto"
        padding='0 8px'
        onClick={() => {
          if (room.listing?.id && room.listing.title) {
            localStorage.setItem('checkoutListing', room.listing.id)
            localStorage.setItem(
              'checkoutListingSlug',
              slugify(room.listing.title)
            )
            history.push('/checkout', {
              listingId: room.listing.id
            })
          }
        }}
      >
        COMPRAR
      </Button>
    }
    if (!hasOrder && isSeller && !isListingSold) {
      return <div className="edit-price-button">
        <Button
          variant="secondary"
          type="button"
          width="auto"
          onClick={() => {
            setEditingPrice(true)
          }}
        >
          <img src={editIcon} alt="editar Preço"/>
        </Button>
      </div>
    }
    if (room?.order?.orderStatus === 'authorization' && isSeller) {
      return <Button
        variant="secondary"
        type="button"
        width="auto"
        padding='0 8px'
        onClick={() => {
          history.push(`/confirmar-envio/${room?.order?.id}/`)
        }}
      >
        Entreguei
      </Button>
    }
    if (room?.order?.orderStatus === 'shipped' && isBuyer) {
      return <Button
        variant="secondary"
        type="button"
        width="auto"
        padding='0 8px'
        onClick={() => {
          history.push(`/confirmar-recebimento/${room?.order?.id}/`)
        }}
      >
        Recebi
      </Button>
    }
    return null
  }

  const editPriceForm = () => {
    return <div className="edit-price">
      <CleaveField
        name="price"
        value={priceWithMask}
        onChange={(name, value, rawValue) => {
          setPriceWithMask(value)
          setPrice(parseFloat(rawValue))
        }}
        type="numeric"
        options={{
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
          numeralPositiveOnly: true,
          rawValueTrimPrefix: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          prefix: 'R$'
        }}
      />
      <Button
        variant="primary"
        onClick={async () => {
          await editListing()
        }}>
        OK
      </Button>
      <Button
        variant="secondaryNoBorder"
        onClick={() => {
          setPrice(originalPrice)
          setPriceWithMask(originalPrice.toString())
          setEditingPrice(false)
        }}>Cancelar</Button>
    </div>
  }

  const formatedPrice = price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  const installments = room?.order?.payment[0].installmentCount

  return (
    <>
      <SoldBanner/>
      <OrderStatusBanner/>
      <div className="main">
        <div className="product-image">
          <Link to={`/eqipo/${room?.listing?.id}/${slugify(room?.listing?.title)}`}>
          <img
            src={room?.listing?.cover}
            alt={room?.listing?.title || ''}
          />
          </Link>
        </div>
        <DetailsContainer>
          <p>{room?.listing?.title}</p>
          {editingPrice
            ? editPriceForm()
            : <div>
              <PriceContainer>
                <h5>{formatedPrice}</h5>
                {!room?.order
                  ? <small>no Pix ou em até 12x</small>
                  : installments > 1 && <small>Em {installments}x</small>
                }
              </PriceContainer>
              <ActionButton/>
            </div>}
        </DetailsContainer>
      </div>
    </>
  )
}
