import React from 'react'

interface RadioFieldProps {
  name?: string
  label?: string
  checked: boolean
  value?: string | number
  required?: boolean
  isSquare?: boolean
  subtitle?: string
  onChange?: (name: string, value: string) => void
}

const RadioField = (props: RadioFieldProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e.target.name, e.target.value)
    }
  }
  return (
    <div className="control">
      <label className="radio">
        {props.label}
        <input
          type="radio"
          required={props.required || false}
          name={props.name}
          onChange={handleChange}
          checked={props.checked}
          value={props.value}
        />
        <span style={{ borderRadius: props.isSquare ? '0px' : '100%' }} className={props.isSquare ? 'notAfter' : 'after'}></span>
        {props.subtitle && <p className="is-pulled-right">{props.subtitle}</p>}
      </label>
    </div>
  )
}

export default RadioField
