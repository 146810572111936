import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Footer.scss'
import { FooterMenu } from './FooterMenu'
import logo from '../../assets/images/logo.svg'
import moment from 'moment'

const Footer = () => {
  const location = useLocation()

  if (location.pathname.startsWith('/mensagem/')) {
    return null
  }

  return (
    <div className="footer">
      <div className="footerTopLine" />
        <div className="footer_menu">
          {FooterMenu?.map((menu, index) => (
            <div className="menu_item" key={index}>
              <p>{menu?.title}</p>
              <ul>
                {menu?.items?.map((item: any, index) => (
                  <li key={index}>
                    <Link style={item.style} to={{ pathname: item.url }} target={item.target}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      <div className="footer-bottom">
        <strong>SIGA A Qip</strong>
        <hr />
        <div className="menu__social">
            <Link to={{ pathname: 'https://www.instagram.com/qip.com.br/' }} target="_blank">
              Instagram
            </Link>
            <div>|</div>
            <Link to={{ pathname: 'https://www.strava.com/clubs/qip_' }} target="_blank">
              Strava
            </Link>
        </div>
      </div>
      <div className="copyRight">
        <small>© Qip, {moment().year()}. Todos os Direitos Reservados.</small>
        <img src={logo} alt="Logo Qip" />
      </div>
    </div>
  )
}

export default Footer
