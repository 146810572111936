import React, { Component } from 'react'
import { toast } from 'react-toastify'

import Container from '../../components/Container/Container'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import favoritesService from '../../services/favoritesService'
import EqipoCard from '../../components/EqipoCard/EqipoCard'
import ToastMessage from '../../components/ToastMessage/ToastMessage'

export interface MyFavoritesState {
  listings: any[]
  count: number
  next?: string
}

class MyFavorites extends Component<any, MyFavoritesState> {
  constructor (props: any) {
    super(props)

    this.state = {
      listings: [],
      count: 0
    }

    void this.loadData()
  }

  async loadData () {
    try {
      const { listings, count, next } = await favoritesService.get()

      this.setState({ listings, count, next })
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
    }
  }

  render () {
    return (
            <div>
                <ListingsProvider filter={initialFilter}>
                    <Navbar/>
                </ListingsProvider>

                <div className="Search">
                    <Container>
                        <div className="tile is-ancestor search-result">
                            {this.state.listings.map((listing, i) => (<div key={i} className="tile is-parent is-6">
                                <EqipoCard
                                    cover={listing.cover}
                                    title={listing.title}
                                    status={listing.itemStatus}
                                    id={listing.id}
                                    seller={listing.seller}
                                    photos={listing.photos}
                                    price={listing.negotiatedPrices?.[0]?.price || listing.price}
                                />
                            </div>))}
                        </div>
                    </Container>
                </div>
            </div>
    )
  }
}

export default MyFavorites
