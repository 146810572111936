export const availablePaymentMethods = {
  creditCard: 'CREDIT_CARD',
  boleto: 'BOLETO',
  pix: 'PIX'
}

export const orderStatuses = {
  authorized: 'AUTHORIZED',
  notCreated: 'NOT_CREATED',
  waiting: 'WAITING'
}
