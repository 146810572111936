export const FooterMenu = [
  {
    title: 'VENDER',
    items: [
      {
        name: 'Venda seus Equipos',
        url: '/eqipo/novo',
        target: '_self',
        style: { fontWeight: 'bold' }
      },
      {
        name: 'Sobre a curadoria',
        url: 'https://qiper.zendesk.com/hc/pt-br/articles/25659711841691-Com-Funciona-a-curadoria-Na-Qip',
        target: '_blank'
      }
    ]
  },
  {
    title: 'AJUDA',
    items: [
      {
        name: 'Chat com suporte',
        url: '/ajuda/',
        style: { fontWeight: 'bold' }
      },
      {
        name: 'Central de Ajuda',
        url: 'https://qiper.zendesk.com/hc/pt-br/categories/24660410370459-D%C3%BAvidas-que-a-maiora-tem',
        target: '_blank'
      },
      {
        name: 'Enviar uma solicitação',
        url: 'https://qiper.zendesk.com/hc/pt-br/requests/new',
        target: '_blank'
      }
    ]
  },
  {
    title: 'SOBRE A Qip',
    items: [
      {
        name: 'Termos de Uso',
        url: 'https://qiper.zendesk.com/hc/pt-br/articles/25570200137627-Termos-e-Condi%C3%A7%C3%B5es-Gerais-de-Uso-da-Qip',
        target: '_blank'
      },
      {
        name: 'Quem Somos',
        url: '/quem-somos/',
        target: '_self'
      }
    ]
  }
]
