import React, { useContext, useEffect, useState } from 'react'
import Cta from '../Cta/Cta'
import FormField from '../FormComponents/FormField'
import SelectField, { type Option } from '../FormComponents/SelectField'

import { Container } from './styles'
import { ButtonV2 as Button } from '../Button/ButtonV2'
import FilterNav from './FilterNav'
import '../EqiposList/EqiposList.scss'
import { SelectSports } from '../SelectSports/SelectSports'
import { SelectStateCity } from '../SelectStateCity/SelectStateCity'

import './Filter.scss'
import { type ListingFilter, ListingsContext, stateToQuery } from '../ListingsProvider/ListingsProvider'
import { useHistory } from 'react-router-dom'

interface IFilter {
  isAbsolute?: boolean
  closePanel?: () => any
  toggleMenu?: () => any
  isOpened?: boolean
}

export interface IRefreshFilter {
  id: string
}

const initialState = { sports: [], conditions: [], states: [] }

const Filter = ({ isAbsolute = false, closePanel, toggleMenu, isOpened = false }: IFilter) => {
  const { filter } = useContext(ListingsContext)
  const [filter_, setFilter_] = useState<ListingFilter>(initialState)
  const [conditionOptions] = useState<Option[]>([
    { value: 'AS_NEW', label: 'Novinho' },
    { value: 'LIGHTLY_USED', label: 'Quase novo' },
    { value: 'USED', label: 'Usado' }
  ])
  const history = useHistory()

  useEffect(() => {
    setFilter_(filter)
  }, [])

  async function handleSubmit (event?: any) {
    if (event) {
      event.preventDefault()
    }
    const query = stateToQuery(filter_)
    history.push(`/eqipos?${query}`)
    toggleMenu?.()
  }

  return (
    <Container className="container" isAbsolute={isAbsolute}>
      {isAbsolute && (
        <FilterNav closePanel={() => closePanel?.()}/>
      )}
      <>
        <Cta
          notSpace
          title="Encontre seu próximo Equipo"
          content="Escolha a categoria e a condição de uso do seu próximo equipo e a gente busca ele pra você!"
        />
        <form onSubmit={handleSubmit} action="">
          <FormField
            name="sport"
            label="Filtrar por esporte"
          >
            <SelectSports
              value={filter_.sports}
              onChange={(selectedOptions) => {
                setFilter_(prevState => ({
                  ...prevState,
                  sports: [...selectedOptions]
                }))
              }}
              placeholder="Selecionar"
            />
          </FormField>

          <FormField name="condition" label="Condição de uso">
            <SelectField
              value={filter_.conditions}
              isMulti
              onChange={conditionOptions => {
                setFilter_(prevState => ({
                  ...prevState,
                  conditions: [...conditionOptions]
                }))
              }}
              placeholder="Selecionar"
              options={conditionOptions}
            />
          </FormField>

          <SelectStateCity
            currentStateOption={filter_.states}
            handleStateChange={stateOptions => {
              setFilter_(prevState => ({
                ...prevState,
                states: [...stateOptions]
              }))
            }}
          />

          <Button
            variant="primary"
            type="submit"
            margin="30px 0px 10px 0px">
            Filtrar
          </Button>
          <Button
            variant="blueNoBorder"
            margin="0px"
            type="button"
            onClick={() => {
              setFilter_(initialState)
              history.push('/eqipos')
              toggleMenu?.()
            }}
          >
            Limpar Filtros
          </Button>
        </form>
      </>
    </Container>
  )
}

export default Filter
