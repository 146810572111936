import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { ButtonContainer, Container } from './styles'
import orderService from '../../services/orderService'
import Navbar from '../../components/Navbar/Navbar'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'

const ConfirmReceive: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false) // Initialize isLoading as false
  const history = useHistory()
  const location = useLocation()

  const onConfirm = async () => {
    try {
      setIsLoading(true)
      const id = location.pathname.split('/')[2]
      await orderService.updateOrder({
        id,
        summary: 'Recebimento',
        trackingNumber: 'null',
        status: 'received'
      })

      // const escrowId = await orderService.getEscrowId(id);
      // await orderService.releaseEscrow(escrowId);
      history.replace('/minhas-compras/')
    } catch (message) {
      toast.error(<ToastMessage />)
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    history.goBack()
  }

  return (
    <>
      <Navbar />
      <Container>
        <div>
          <h4 className="description-1">CONFIRMAR RECEBIMENTO</h4>
          <p>
            Ao confirmar o recebimento do equipo a liberação do pagamento para a pessoa vendedora será automática.
            <br/><br/>
            <span>ATENÇÃO:</span><br/>
            <strong>Esta ação não pode ser cancelada.</strong>
          </p>
          <ButtonContainer>
            <Button variant="primary" onClick={ onConfirm } isLoading={ isLoading }>
              CONFIRMAR RECEBIMENTO
            </Button>
            <Button variant="softBlue" onClick={ onClose } isLoading={isLoading}>
              CANCELAR
            </Button>
          </ButtonContainer>
        </div>
      </Container>
    </>
  )
}

export default ConfirmReceive
