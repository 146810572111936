import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Container } from './style'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import EqiposList from '../../components/EqiposList/EqiposList'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import eqipoService from '../../services/eqipoService'
import { useAsync } from 'react-async-hook'
import { type Listing, ListingStatus } from '../../models/Listings'
import Cta from '../../components/Cta/Cta'

const MyEqipoPage = () => {
  const { result: listings, loading } = useAsync<Listing[]>(
    async () => await eqipoService.getMyEqipos({ itemStatus: [ListingStatus.DRAFT, ListingStatus.WAITING_REVIEW, ListingStatus.ENABLED, ListingStatus.DISABLED] }),
    []
  )

  return <ListingsProvider filter={initialFilter}>
    <Navbar/>
    <Container>
      <ReturnHeader title="MEUS EQUIPOS" border/>
      {listings?.length === 0
        ? <div style={{ padding: '0 20px' }}>
          <Cta
            title="E aquele seu equipo?"
            content="Aquele seu equipo em boas condições de uso pode ser o início ou o próximo passo da jornada de alguém no esporte!"
            isButton
            buttonProps={{ title: 'Vender Equipo', route: '/eqipo/novo' }}
          />
        </div>
        : <EqiposList listings={listings} isLoading={loading} disableFilterModal/>
      }
    </Container>
  </ListingsProvider>
}
export default MyEqipoPage
