import styled from 'styled-components'

export const Container = styled.section`
  h2 {
    font-style: italic;
    font-weight: 900;
    font-size: clamp(1rem, 5.2vw, 2.3rem);
    max-width: 800px;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.qipBlueLight};
    margin: 30px 0 30px;
    z-index: 2;
    text-shadow:
      1px 1px 0 #ffffff,
      -1px 1px 0 #ffffff,
      1px -1px 0 #ffffff,
      -1px -1px 0 #ffffff,
      1px 0 0 #ffffff,
      -1px 0 0 #ffffff,
      0 1px 0 #ffffff,
      0 -1px 0 #ffffff;
  }

  h3 {
    font-style: italic;
    font-weight: 900;
    font-size: 31px;
    line-height: 100%;
    letter-spacing: -1px;
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.qipBlueLight};
    margin: 30px 0 10px 0;
    padding: 0 20px;
  }
`

export const ChamadaAspas = styled.img<any>`
  position: absolute;
  width: 90vw;
  max-width: 540px;
  opacity: 0.5;
`

export const Hero = styled.img<any>`
  object-fit: cover;
  width: 100vw;
`

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px;
  gap: 16px;
  align-items: center;
  
  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.qipBlueLight};
    
    strong {
      color: ${({ theme }) => theme.colors.qipBlueLight};
    }
  }

  > a {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.qipBlueLight};
  }
`

export const Header = styled.h4`
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  width: 100%;
  height: 52px;
  border-bottom: 1px solid #A69D9D;
`

export const HeaderLogged = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0px;
  width: 100%;
  height: 52px;
  box-shadow: 0 1px 0 #E0E0E0;

  > strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.qipBlueLight};
  }
`

export const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.uiBackground};
}

> h2 {
  font-style: italic;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  letter-spacing: -1px;
  text-align: center;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  margin-top: 16px;
}

> h3 {
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.qipCoral};
  margin-top: 24px;
}

#p1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  margin-top: 16px;

  > strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.qipBlueLight};
  }
}

#p2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.qipBlueLight};
  margin-top: 32px;
  margin-bottom: 24px;

  > strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.qipBlueLight};
  }
}
`

export const AboutUsContainerTop = styled.div`
  height: 65px;
  width: 100%;
  margin-bottom: -1px;
  flex-container: flex;
  display: flex;
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
  background-color: ${({ theme }) => theme.colors.uiBackground}
`

export const CallLoginContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 21px;
  margin-top: -50px;
  margin-bottom: 20px;

  > p {
    font-style: italic;
    font-weight: normal;
    font-size: 32px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.uiWhite};

    > strong {
      color: ${({ theme }) => theme.colors.uiWhite};
    }
  }
`
