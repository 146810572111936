import React from 'react'

import logo from '../../assets/images/logo-white.svg'
import qrcode from '../../assets/images/qrcode.svg'
import background from '../../assets/images/background.svg'

import './Desktop.scss'

export const Desktop = () => {
  return <div className="desktop" style={{ backgroundImage: `url(${background})` }}>
    <header>
      <img src={logo} alt="Logo"/>
    </header>
    <div className="container">
      <h1>AQUI VOCÊ COMPRA E VENDE PRODUTOS ESPORTIVOS DE ORIGEM, PRÉ-AMADOS E COM HISTÓRIA !</h1>
      <h2>POR ENQUANTO ESTAMOS APENAS NO SEU CELULAR!</h2>
      <div className="qrcode">
        <img src={qrcode} alt="qrcode"/>
      </div>
      <p>ACESSE PELO <strong>QR CODE</strong><br/> OU EM <span>QIP.COM.BR</span></p>
    </div>
  </div>
}
