import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../assets/images/logo.svg'
import closeIcon from '../../assets/images/close-2.svg'

interface INav {
  closePanel: (_: boolean) => any
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.uiWhite};
  width: calc(100% + 40px);
  height: 65px;
  margin-left: -20px;
  margin-top: -15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -15px;
  > button {
    position: absolute;
    right: 31px;
    top: 15px;
  }
  > a {
    > img {
      width: 71px;
      height: 34.59px;
    }
  }
`

const FilterNav = ({ closePanel }: INav) => {
  return (
    <Container>
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
      <button
        style={{ background: 'transparent' }}
        aria-label="menu"
        aria-expanded="false"
        data-target="sidebar"
        onClick={() => {
          closePanel(true)
        }}
      >
        <img src={closeIcon} alt="close" height={35} width={35} />
      </button>
    </Container>
  )
}

export default FilterNav
