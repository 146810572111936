import React from 'react'
import ConfirmDialog, { type ConfirmationOptions } from './ConfirmDialog'

type OpenConfirmation = (options: ConfirmationOptions) => Promise<boolean>

export const ConfirmServiceProvider = ({
  children
}: {
  children: React.ReactElement
}) => {
  const [
    confirmationState,
    setConfirmationState
  ] = React.useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = React.useRef<{
    resolve: (isConfirmed: boolean) => void
    reject: () => void
  }>()

  const openConfirmation: OpenConfirmation = async (options) => {
    setConfirmationState(options)
    return await new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    const catchOnCancel = confirmationState && confirmationState.catchOnCancel
    if (catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false)
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true)
    }

    setConfirmationState(null)
  }

  return (
        <>
            <ConfirmationServiceContext.Provider
                value={openConfirmation}
            >{children}
            </ConfirmationServiceContext.Provider>
            <ConfirmDialog
                open={Boolean(confirmationState)}
                title={confirmationState?.title || 'Confirmar?'}
                description={confirmationState?.description}
                buttonCancel={confirmationState ? confirmationState.buttonCancel : 'Não'}
                buttonConfirm={confirmationState ? confirmationState.buttonConfirm : 'Sim'}
                onClose={handleClose}
                onSubmit={handleSubmit}
            />
        </>
  )
}
export const ConfirmationServiceContext = React.createContext<
(options: ConfirmationOptions) => Promise<boolean>
>(Promise.reject)

export const useConfirmationService = () => React.useContext(ConfirmationServiceContext)
