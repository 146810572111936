import React, { type MutableRefObject, useRef } from 'react'

type OnChange = (name: string, value: string) => void

interface TextFieldProps<TextArea> {
  name: string
  textArea?: TextArea
  type?: TextArea extends true ? undefined : string
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange: OnChange
  description?: string
  autoComplete?: string
  prefix?: string
}

const TextField = function TextField<TextArea extends boolean | undefined> ({
  autoComplete = 'off',
  ...props
}: TextFieldProps<TextArea>) {
  const prefixRef: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.onChange(e.target.name, e.target.value)
  }

  const calculatePadding = () => {
    const prefixWidth = prefixRef.current ? prefixRef.current.offsetWidth : 0
    return prefixWidth + 11
  }

  return (
    <div className="control">
      {props.textArea
        ? (
          <>
          <textarea
            id={props.name}
            name={props.name}
            className="textarea"
            value={props.value || ''}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onChange={handleChange}
          />
            <span className="textarea__caption">{props.value ? props.value.length : 0}/800 (Mínimo 140 caracteres)</span>
          </>
          )
        : (
          <div className="input-prefix-container">
            {props.prefix && <div className="input-prefix" ref={prefixRef}>{props.prefix}</div>}
            <input
              id={props.name}
              name={props.name}
              className={props.prefix ? 'input input-prefixed' : 'input'}
              type={props.type}
              value={props.value || ''}
              placeholder={props.placeholder}
              disabled={props.disabled}
              onChange={handleChange}
              autoComplete={autoComplete}
              style={{ paddingLeft: `${calculatePadding()}px` }}
            />
            <span className="textarea__caption">{props.description && props.description}</span>
          </div>
          )}
    </div>
  )
}

export default TextField
