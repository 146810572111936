import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import { ButtonContainer, Container } from './styles'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import orderService from '../../services/orderService'
import Navbar from '../../components/Navbar/Navbar'

const ConfirmDeliver: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false) // Initialize isLoading as false

  const onConfirm = async () => {
    try {
      setIsLoading(true)
      const id = location.pathname.split('/')[2]
      await orderService.updateOrder({
        id,
        summary: 'Entregue',
        trackingNumber: 'null',
        status: 'shipped'
      })

      history.replace('/minhas-vendas/')
    } catch (error) {
      const { isWarn, message } = error as any
      isWarn ? toast.warn(<ToastMessage label={ message } />) : toast.error(<ToastMessage />)
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    history.goBack()
  }

  return (
    <>
      <Navbar />
      <Container>
        <div>
          <h4 className="description-1">CONFIRMAR ENTREGA</h4>
          <p>
            Tem certeza que deseja confirmar a entrega do equipo?
            <br/><br/>
            <span>ATENÇÃO:</span><br/>
            <strong>Esta ação não pode ser cancelada.</strong>
          </p>
          <ButtonContainer>
            <Button variant="primary" onClick={ onConfirm } isLoading={isLoading}>
              CONFIRMAR ENTREGA
            </Button>
            <Button variant="softBlue" onClick={ onClose } isLoading={isLoading}>
              CANCELAR
            </Button>
          </ButtonContainer>
        </div>
      </Container>
    </>
  )
}

export default ConfirmDeliver
