import { $http } from '../../httpService'
import { type ClubPayload } from '..'

interface ClubParams {
  search: string
}

async function search ({ search }: ClubParams) {
  let response
  if (search.length === 0) {
    response = await $http.get('/v1/clubs')
  } else {
    response = await $http.get(`/v1/clubs?search=${search}`)
  }
  return response.data
}

async function create ({ clubName, state, city }: ClubPayload) {
  const body = {
    clubName,
    state,
    city
  }
  const response = await $http.post('/v1/clubs', body)
  return response.data
}

export default { search, create }
