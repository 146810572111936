import Skeleton from 'react-loading-skeleton'
import { DetailsContainer } from './styles'
import React from 'react'

export const MessagesRoomLoading = () => (
  <div className="main">
    <Skeleton height={80} width={80}/>
    <DetailsContainer>
      <Skeleton height={25} width={100} style={{ marginBottom: '10px' }}/>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <Skeleton height={45} width={70}/>
        <Skeleton style={{ marginLeft: '10px' }} height={45} width={100}/>
      </div>
    </DetailsContainer>
  </div>
)
