// src/components/AboutUs.tsx
import React, { useState } from 'react'
import ListingsProvider, { initialFilter } from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'

import './AboutUs.scss'
import ReactPlayer from 'react-player'

const AboutUs: React.FC = () => {
  const [playing, setPlaying] = useState(false)

  const handleVideoClick = () => {
    setPlaying((prev) => !prev)
  }

  return (
    <div>
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <div className="about-us-container">
        <h1>A QIP NASCEU DE UMA <br/> HISTÓRIA REAL <br/> NO ESPORTE</h1>
        <div className="video-wrapper">
          <div className="video-aspect-ratio">
            <ReactPlayer
              className="react-player"
              url='/videos/qip-quem-somos.mp4'
              playing={playing}
              onClick={handleVideoClick}
              controls={false}
              width="100%"
              height="100%"
              light='/videos/qip-quem-somos-cover.png'
            />
          </div>
        </div>
        <p>
          A primeira vez que <em>Bruna Bittencourt</em> pensou na Qip, foi quando se preparava para uma ultramaratona na Patagônia em 2018.
          <br/><br/>
          Esse pensamento surgiu de um incômodo ao se ver comprando novos <em>equipos</em> em sites com experiências frias e distantes da
          comunidade esportiva. Além disso, Bruna queria que os equipos que precisava viessem de alguma pessoa com quem pudesse trocar sobre
          o esporte
          que ama, mas não encontrava como acessá-las.
          <br/><br/>
          A frustração aumentou quando comprou um bastão de caminhada <em>(trekking pole)</em> em um site fora do Brasil e o produto
          importado não serviu para seu tamanho. Ela teve que encontrar uma forma de vender o produto para outra pessoa.
          <br/><br/>
          Esses motivos e problemas moveram a empreendedora a criar a Qip:
        </p>
        <h2>PARA CONECTAR QUEM AMA ESPORTES AOS MELHORES EQUIPAMENTOS PRÉ-AMADOS</h2>
        <p>
          Trilhamos um caminho do bem para sociedade e nossos <em>stakeholders</em>, e como qualquer empresa privada, precisamos manter o
          lucro acima das despesas, mas não estamos interessados somente em retorno financeiro, temos o objetivo de ser uma ponte de
          ampliação para a
          comunidade esportiva.
          <br/><br/>
          <b>Somos pelas pessoas sempre e primeiro!</b>
          <br/><br/>
        </p>
        <p>
          <span className="imageWrapper">
            <img src="/images/qip-adventure.png" alt='qip-adventure'/>
          </span>
          Queremos impactar positivamente nossos clientes, parceiros e todos que se relacionam conosco, e estamos na missão do <b>consumo
          consciente</b>, retornando bons equipamentos esportivos para novos ciclos de uso
        </p>
      </div>
    </div>
  )
}

export default AboutUs
