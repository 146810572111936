import stravaIcon from '../../assets/images/strava-coral.svg'
import instagramIcon from '../../assets/images/instagram-orange.svg'
import twitterIcon from '../../assets/images/twitter-orange.svg'
import garminIcon from '../../assets/images/garmin-orange.svg'
import facebookIcon from '../../assets/images/facebook-orange.svg'

export const social: any = {
  strava: { alt: 'Strava link', src: stravaIcon, prefix: 'https://www.strava.com/athletes/' },
  twitter: { alt: 'Twitter link', src: twitterIcon, prefix: 'https://twitter.com/' },
  garmin: { alt: 'Garmin link', src: garminIcon, prefix: 'https://connect.garmin.com/modern/profile/' },
  facebook: { alt: 'Facebook link', src: facebookIcon, prefix: 'https://facebook.com/' },
  instagram: { alt: 'Instagram link', src: instagramIcon, prefix: 'https://instagram.com/' }
}
