import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './global/index.scss'
import * as amplitude from '@amplitude/analytics-browser'

if (window.location.hostname !== 'localhost') {
  amplitude.init('f22fb589b18f1194bcb9a6b50eaa8ea4', {
    autocapture: {
      elementInteractions: true,
      formInteractions: true,
      pageViews: true,
      sessions: true
    }
  })
}

const rootElement = document.getElementById('root')
ReactDOM.createRoot(rootElement!).render(<App />)
