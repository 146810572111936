import React, { useContext } from 'react'

import { ButtonV2 as Button } from '../../../components/Button/ButtonV2'
import { type FilledEqipoInfo, itemsCondition } from './NewEqipoForm'
import { type ListingPhotos } from './NewEqipo'
import { GlobalContext } from '../../../context/GlobalContext'
import EqipoGallery from '../../../components/EqipoGallery/EqipoGallery'
import './NewEqipoReview.scss'
import Cta from '../../../components/Cta/Cta'
import moment from 'moment'
import { months } from '../../Profile/ProfileContainer/ProfileInformation'
import emptyProfileAvatar from '../../../assets/images/empty-profile.svg'

interface INewEqipoReview {
  onSubmitForm: () => void
  backStage: () => void
  formData?: FilledEqipoInfo
  formPhotos?: ListingPhotos
  isLoading: boolean
}

const NewEqipoReview: React.FC<INewEqipoReview> = ({
  onSubmitForm,
  backStage,
  formData,
  formPhotos,
  isLoading
}) => {
  const { sports, userProfile: currentUser } = useContext(GlobalContext)
  const createTimeUser = currentUser?.metadata?.creationTime
  const formattedCreateTimeUser = moment(createTimeUser)
  const monthCreateTimeUser = formattedCreateTimeUser.month()
  const formattedMonthCreateTimeUser = months[monthCreateTimeUser]
  const yearCreateTimeUser = formattedCreateTimeUser.year()
  const itemStatus = formData?.itemStatus

  return (
    <div>
      <Cta
        notSpace
        title="Revise seu anúncio"
        content={
          <>
            Esta é a prévia do seu anúncio.
            <br/><br/>
            Verifique se não esqueceu nada, e se estiver tudo OK envie para nossa curadoria sem medo.
          </>
        }
      />
      <div className="coverEqipo">
        <img
          src={formPhotos?.cover}
          alt={`Imagem capa do equipo ${formData?.title}`}
        />
      </div>
      <h3 className="titleEqipo">{formData?.title}</h3>
      <div className="storyEqipo">
        <div className="profileEqipo">
          <img
            src={currentUser?.photoURL || emptyProfileAvatar}
            alt={`perfil do ${currentUser?.displayName}`}
          />
          <div>
            <h5>{currentUser?.displayName}</h5>
            <strong>Estou em {currentUser?.address?.currentCity}/{currentUser?.address?.currentState}</strong>
            <small>
              Sou Qiper desde {formattedMonthCreateTimeUser?.slice(0, 3)}/
              {yearCreateTimeUser} -{' '}
            </small>
          </div>
        </div>
        <p>{formData?.story}</p>
      </div>
      <div className="galleryContainerEqipo">
        <EqipoGallery
          photos={formPhotos?.photos || []}
          title="Imagens do Eqipo"
        />
      </div>
      <div className="tagEqipo">
        <strong className="titleNewEqipoReview">Esse equipo serve para</strong>
        <div>
          {formData?.sports.map(sport =>
            sports
              ?.filter(({ id }) => id === sport)
              .map(({ title, id }) => <span key={id}>{title}</span>)
          )}
        </div>
      </div>
      <div className="specContainerEqipo">
        <strong className="titleEqipo">Especificações</strong>
        <div className="specEqipo" style={{ marginTop: '8px' }}>
          <strong>Tamanho: </strong>
          <small>{formData?.size}</small>
        </div>
        <div className="specEqipo">
          <strong>Fabricante: </strong>
          <small>{formData?.brand}</small>
        </div>
        <div className="specEqipo">
          <strong>Nota Fiscal: </strong>
          <small>{formData?.hasInvoice ? 'Sim' : 'Não'}</small>
        </div>
        <div className="specEqipo">
          <strong>Conservação: </strong>
          <small>
            {
              itemsCondition.find(
                ({ value }) => formData?.itemCondition === value
              )?.label
            }
          </small>
        </div>
      </div>
      <div className="priceEqipo">
        <strong>
          {(formData?.price || 0).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })}
        </strong>
        <small>no Pix ou em até 12x</small>
        <div/>
      </div>
      <div className="buttonsContainerNewEqipoReview">
        {itemStatus !== ('draft' || 'waitingReview')
          ? (
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                onSubmitForm()
              }}
              isLoading={isLoading}
            >
              CONFIRMAR ALTERAÇÕES
            </Button>
            )
          : (
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                onSubmitForm()
              }}
              isLoading={isLoading}
            >
              ENVIAR PARA CURADORIA
            </Button>
            )
        }
        <Button
          type="button"
          variant="secondaryNoBorder"
          disabled={isLoading}
          onClick={() => {
            backStage()
          }}
        >
          Editar anúncio
        </Button>
      </div>
    </div>
  )
}

export default NewEqipoReview
